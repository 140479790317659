import { FC } from 'react';
import { notificationCodes, notificationCodesTableHeaders } from '#globals';
import { useSafeState } from '#hooks';

export const NotificationCodesToCopy: FC = () => {

    const [isExpandedBubble, setIsExpandedBubble] = useSafeState<boolean>(false)
    const [hoveredRowId, setHoveredRowId] = useSafeState<number | null>(null);

    const handleCopy = (code: string) => {
        navigator.clipboard.writeText(code);
    };

    const src = isExpandedBubble ? '/images/expand.png' : 'images/contract.png';


    return <div className='main-dashboard-container pd-m mt-s'>
            <div className='three-columns-expand-two' onClick={() => setIsExpandedBubble(!isExpandedBubble)}>
                <h3>Codes to copy to personalise the notifications</h3>
                <div />
                <img src={src} alt="arrow" className='expand-contract-esim vertical-align ml-l' />
            </div>
            {
                isExpandedBubble && <table className='center-wrapper table pd-m mt-l'>
                    <thead className="table-header">
                        <tr>
                            {
                                notificationCodesTableHeaders.map(
                                    header => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notificationCodes.map(
                                (code, index) => (
                                    <tr 
                                        key={index} 
                                        className={`table-row ${hoveredRowId === index ? 'hovered' : ''}`}  
                                        onClick={() => handleCopy(code.code)}
                                        onMouseEnter={() => {setHoveredRowId(index)}}
                                        onMouseLeave={() => {setHoveredRowId(null)}}
                                    >
                                        <td className='table-cell bold'>{code.label}</td>
                                        <td className="table-cell">{code.code}</td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
              </table>
        }
    </div>
}
