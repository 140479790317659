import { FC } from 'react';
import { PageHeader } from '#dashboard/components/pageHeader.component';
import { SupportOptionsList } from '#livechat/components/supportOptionsList.component';
import { LiveChatDashboard } from '#livechat/components/liveChatDashboard.component';
import { useAppContext } from '#hooks';
import { LiveChatArchiveTable } from '#livechat/components/liveChatArchiveTable.component';
import { DownloadConversationsButton } from '#livechat/components/downloadConversationsButton.component';

export const LiveChat: FC = () => {

    const { loggedInUser } = useAppContext();

    return <>
        <PageHeader header={'Live Chat'}/>
        <LiveChatDashboard />
        <LiveChatArchiveTable />
        { loggedInUser?.roles?.SUPERUSER && <SupportOptionsList /> }
        { loggedInUser?.roles?.SUPERUSER && <DownloadConversationsButton /> }
    </>
}
