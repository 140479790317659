import { FC } from 'react';
import { useAppContext, useSafeState } from '#hooks';
import { dashboardApi } from '#api';
import { HttpStatusCode } from 'axios';

export const CustomStatsButton: FC = () => {

    const { setApiStatus } = useAppContext()
    const [ isLoading, setIsLoading ] = useSafeState<boolean>(false)
    const [ response, setResponse ] = useSafeState<string | null>(null)

    const fetchAramcoStats = async () => {
        setIsLoading(true)
        try {
            const response = await dashboardApi.getCustomStats()
            setResponse(response.data)
        } catch {
            setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Could not fetch the stats' })
        } finally {
            setIsLoading(false)
        }
    }

    if(isLoading) {
        return <div className="lds-hourglass" />
    }

    if(!!response) {
        return <div className='main-dashboard-container pd-m mt-s three-columns-expand-three'>
            <span className='vertical-align selectable-text'>{response}</span>
            <div />
        </div>
    }

    return <div className='main-dashboard-container pd-m mt-s three-columns-expand-three'>
        <span className='vertical-align'>Click this button to fetch current Aramco statistics</span>
        <button onClick={fetchAramcoStats} className='download-button ml-m pd-s bold'>FETCH STATS</button>
        <div />
    </div>
}
