import { FC } from 'react';
import { FaqList } from '#dashboard/components/faqList.component';
import { PageHeader } from '../components/pageHeader.component';

export const CommonAnswers: FC = () => {

  return <>
        <PageHeader header={'Commonly Asked Questions'}/>
        <FaqList />
    </>
}
