import { FC } from "react";
import '../App.css'
import { Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSafeState } from "#hooks";

interface Props {
    isVisible: boolean;
    onClose: any;
    onConfirm: any;
}

export const ConfirmationModal: FC<Props> = ({ isVisible, onClose, onConfirm }) => {

    const [isLoading, setIsLoading] = useSafeState<boolean>(false);

    const handleOnConfirm = async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
    }

    if (!isVisible) {
        return null;
    };

    return <div className="modal-overlay">
        <div className="modal-content">
            <h3 className="modal-title">Are you sure you want to save changes?</h3>
            <div className="row" style={{ justifyContent: 'space-evenly' }}>
                <LoadingButton onClick={onConfirm} loading={isLoading} variant="contained">
                    Confirm
                </LoadingButton>
                <Button onClick={onClose} variant="outlined">Cancel</Button>
            </div>
        </div>
    </div>
}
