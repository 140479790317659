import { FC } from 'react';
import { Link } from 'react-router-dom';
import { getMenuList } from '#globals';
import { useAppContext } from '#hooks';

export const HeaderNav: FC = () => {

    const { loggedInUser, currentDb } = useAppContext();

    return <div className='vertical-align two-columns-expand-two'>
        <nav>
            <ul className='repeat-columns list-reset ml-l header-nav-ul'>
                {
                    getMenuList(loggedInUser?.roles?.SUPERUSER, currentDb).map(
                        (listItem: string) => <li key={`${listItem}`} className='mr-m'>
                            <Link to={`${listItem}`}>
                                <img src={`/images/${listItem}.png`} alt={`${listItem}`} className='header-icon  mb-s' />
                                <span className='header-text vertical-align wide-screen mb-s'>{listItem.toUpperCase()}</span>
                            </Link>
                        </li>
                    )
                }
            </ul>
        </nav>
        <div />
    </div>
}
