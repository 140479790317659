import { constants } from "#globals";
import { Database, User } from "#types";

export const formatDate = (strDate: string): string => {
    const date = new Date(strDate);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = date.getFullYear();
    const monthIndex = date.getMonth();

    const day = date.getDate();
    const monthName = monthNames[monthIndex];
    return `${day}th, ${monthName}, ${year}`;
}

export const formatDateAsDDMMYYYY = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

export function formatBytes(bytes: number): string {
    const unites = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) {
        return '0 Bytes';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (unites[i] === 'MB') {
        return parseInt((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + unites[i];
    }
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + unites[i];
}

export function formatDateWithHours(date: Date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
}

export const getFullUserName = (user: User) => {
    if(user.fullName){
        return user.fullName
    }
    return `${user.firstName} ${user.lastName}`
}

export const getFirstAndLastName = (user: User) => {
    if(user.fullName){
        const [firstName, lastName] = user.fullName.trim().split(/\s+/);
        return [firstName, lastName];
    }
    return [user.firstName, user.lastName]
}

export const isZim = localStorage.getItem(constants.targetDb) === Database.ZIM

export function getWebsocketUrl() {
    if(process.env.REACT_APP_DB_URL?.includes('localhost')) {
        return `http://localhost:${process.env.REACT_APP_SOCKET_IO_PORT}`
    }
    let websocketUrl: string;
    const targetDb = localStorage.getItem(constants.targetDb) as Database
    switch (targetDb) {            
        case Database.SBB:
            websocketUrl = `${process.env.REACT_APP_SBB_DB_URL}`;
            break;

    //TODO: change this to prod when releasing GD
        case Database.GD:
            websocketUrl = `${process.env.REACT_APP_GD_DB_URL_DEV}`;
            break;
        
        default:
            websocketUrl = `${process.env.REACT_APP_DB_URL_LIVE}`;
            break;
    }
    return websocketUrl
}