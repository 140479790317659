import { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { PlansFilter, PlanSearchBarOptions, searchLabels } from '#types';
import { useSafeState } from '#hooks';

interface PlansSearchBarProps{
    setFilter: Dispatch<SetStateAction<PlansFilter>>;
    setCurrentPage: (currentPage: number) => void;
}

export const PlansSearchBar: FC<PlansSearchBarProps> = ({ setFilter, setCurrentPage }) => {
    const [searchQuery, setSearchQuery] = useSafeState<string>('');
    const [searchField, setSearchField] = useSafeState<string>(PlanSearchBarOptions.DESTINATION_NAME);
    const planSearchBarOptionLabels: searchLabels[] = [
        { value: PlanSearchBarOptions.PRODUCT_CODE, label: "Product Code" },
        { value: PlanSearchBarOptions.DESTINATION_NAME, label: "Country" },
        { value: PlanSearchBarOptions.FOOTPRINT_CODE, label: "Footprint Code" },
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const filter = { [searchField]: searchQuery };
        setFilter(filter);
        setCurrentPage(1)
    };

    const handleClearFilter = () => {
        setFilter({})
        setCurrentPage(1)
    }

    return <div className='search-bar'>
        <form onSubmit={handleSubmit} id='user-search-bar-container'>
            <div className='two-columns-expand-two'>
                <label htmlFor="search-query" className='vertical-align mr-m'>Search:</label>
                <input
                    type="text"
                    id="search-query"
                    className='pl-m'
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                />
            </div>
            <div className='four-columns-auto'>
                <label htmlFor="search-field" className='vertical-align ml-m mr-m nowrap'>Search by:</label>
                <select id="search-field" value={searchField} onChange={(event) => setSearchField(event.target.value)}>
                    {
                        planSearchBarOptionLabels.map(
                            (searchOption) => <option value={searchOption.value} key={searchOption.value}>
                                {searchOption.label}
                            </option>
                        )
                    }
                </select>
                <button type="submit" className='searchBy-btn bold white-text'>SEARCH</button>
                <button type="button" onClick={handleClearFilter} className='searchBy-btn white-text bold nowrap'>CLEAR</button>
            </div>
        </form>
    </div>
};
