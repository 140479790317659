import { FC } from 'react';
import { HeaderNav } from '#dashboard/pages/headerNav'
import { useNavigate } from 'react-router-dom';
import { constants } from '#globals';
import { Database, User } from '#types';
import { useAppContext } from '#hooks';
import { DatabaseToggleSwitch } from '#dashboard/components/databaseToggleSwitch.component';

export const Header: FC = () => {

    const { isExpanded, loggedInUser, setLoggedInUser, setIsAuthenticated, pageFound, currentDb } = useAppContext();

    const navigate = useNavigate();

    function handleSignout(): void {
        localStorage.removeItem(constants.accessToken)
        localStorage.removeItem(constants.refreshToken)
        localStorage.removeItem(constants.targetEnv)
        setLoggedInUser({} as User)
        setIsAuthenticated(false)
        navigate('/auth/login')
    }

    function handleHomeClick(): void {
        if(loggedInUser?.roles?.SUPERUSER && pageFound) {
            navigate("/")
        }
    }

    return <div className='four-columns-expand-one header-height'>
        <div className='vertical-align ml-m'>
            {pageFound && isExpanded && <HeaderNav />}
        </div>
        { loggedInUser?.roles?.SUPERUSER && <DatabaseToggleSwitch /> }
        <button id='signout-button' className='clear-button vertical-align' onClick={handleSignout}>
            SIGN OUT
        </button>
        {
            currentDb === Database.ZIM && <button className='clear-button vertical-align' onClick={handleHomeClick}>
                    <img src="/images/logo-dark.svg" alt="zim-logo-dark" id='zim-logo-header' />
                </button>
        }
    </div>
}
