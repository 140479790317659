import { FC } from 'react';
import { reportPerPageNumberOptions, genericPerPageNumberOptions } from '#globals';

interface SelectPerPageDropdownProps{
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    setCurrentPage: (currentPage: number) => void;
    name: string;
}

export const SelectPerPageDropdown: FC<SelectPerPageDropdownProps> = ({ pageSize, setPageSize, setCurrentPage, name }) => {

    const options = name === "Reports" ? reportPerPageNumberOptions : genericPerPageNumberOptions

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1)
    };
  
    return <div className='two-columns-expand-one'>
        <label htmlFor="page-size-select" className='vertical-align ml-m mr-m nowrap'>{name} per page:</label>
        <div className='three-rows-expand-one-three'>
            <div/>
            <select id="page-size-select" value={pageSize} onChange={handlePageSizeChange}>
                {
                    options.map(
                        option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        )
                    )
                }
            </select>
            <div/>
        </div>
    </div>
}
