import { constants } from '#globals';
import { Database, EnvironmentType } from '#types';
import axios, { AxiosRequestConfig, AxiosError, InternalAxiosRequestConfig } from 'axios';

export const client = axios.create({ baseURL: process.env.REACT_APP_DB_URL as string });

client.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const jwtToken = localStorage.getItem(constants.accessToken);
        if (localStorage.getItem(constants.targetEnv) === EnvironmentType.DEVELOPMENT) {
            config.baseURL = process.env.REACT_APP_DB_URL_DEV as string;
        }
        if (localStorage.getItem(constants.targetDb) === Database.SBB) {
            config.baseURL = process.env.REACT_APP_SBB_DB_URL as string;
            if (localStorage.getItem(constants.targetEnv) === EnvironmentType.DEVELOPMENT) {
                config.baseURL = process.env.REACT_APP_SBB_DB_URL_DEV as string;
            }
        }
        if (localStorage.getItem(constants.targetDb) === Database.GD) {
            config.baseURL = process.env.REACT_APP_GD_DB_URL as string;
            if (localStorage.getItem(constants.targetEnv) === EnvironmentType.DEVELOPMENT) {
                config.baseURL = process.env.REACT_APP_GD_DB_URL_DEV as string;
            }
        }
        if (config.headers) {
            config.headers.Accept = 'application/json';
            config.headers['X-App-Source'] = `${process.env.REACT_APP_SOURCE}_WEB`;
            config.headers['X-App-Identifier'] = process.env.REACT_APP_IDENTIFIER;
            config.headers['X-App-Version'] = '3.0.1';
            if (jwtToken) {
                config.headers.Authorization = `Bearer ${jwtToken}`;
            }
        }
        return config as InternalAxiosRequestConfig<any>;
    },
    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        if (
            error.config &&
            error.response?.status === 401
        ) {
            try {
                const { accessToken }: any = await refreshJwtToken();
                error.config.headers.Authorization = `Bearer ${accessToken}`;
                return client(error.config);
            } catch (refreshError: any) {
                if (refreshError.response.status === 403) {
                    console.log('remove refresh...');
                    localStorage.removeItem(constants.accessToken);
                    localStorage.removeItem(constants.refreshToken);
                }
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

const refreshJwtToken = async () => {
    const targetEnv = localStorage.getItem(constants.refreshToken)
    if (!targetEnv) {
        return
    }
    const route = targetEnv === EnvironmentType.PRODUCTION ? `${process.env.REACT_APP_DB_URL}/auth/refresh` : `${process.env.REACT_APP_DB_URL_DEV}/auth/refresh`;
    const refreshToken = localStorage.getItem(constants.refreshToken);
    const config = {
        headers: {
            'Authorization': `Bearer ${refreshToken}`
        }
    };
    const response = await axios.post(route, { refreshToken }, config);
    localStorage.setItem(constants.accessToken, response.data.accessToken);
    localStorage.setItem(constants.refreshToken, response.data.refreshToken);
    return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken
    };

}