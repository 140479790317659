import { dashboardApi } from '#api';
import { FC, useEffect, useRef } from 'react';
import { Database, User, UsersFilter } from '#types';
import { UserSearchBar } from '#users/components/userSearchBar.component'
import { Pagination } from '#dashboard/components/pagination.component';
import { userTableHeaders } from '#globals';
import { useAppContext, useSafeState } from '#hooks';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';
import { getFirstAndLastName } from '../../services/helpers';

export const UserTable: FC = () => {

    const { setApiStatus, currentEnvironment, currentDb } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [users, setUsers] = useSafeState<User[]>([]);
    const [pageSize, setPageSize] = useSafeState<number>(10);
    const [currentPage, setCurrentPage] = useSafeState<number>(1);
    const [filter, setFilter] = useSafeState<UsersFilter>({});
    const totalPagesRef = useRef<number>(1);

    const fetchUsers = async () => {
        try {
            const response = await dashboardApi.getUsers(filter, currentPage, pageSize)
            totalPagesRef.current = Math.ceil(response.data.total / pageSize)
            setUsers([...response.data.users]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(
        () => {
            fetchUsers();
        },
        [filter, currentPage, pageSize, currentEnvironment]
    );


    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <>
        <div className='pd-m main-dashboard-container mt-s'>
            <div className='three-columns-expand-two'>
                <UserSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
                <div />
                <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Users' />
            </div>
            <table className='center-wrapper table'>
                <thead className="table-header">
                    <tr>
                        {
                            userTableHeaders.map(
                                (header: string) => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map(
                            (user: User, index: number) => (
                                <tr key={`${user._id}_${String(index)}`} className={'table-row'} onClick={() => window.open(`users/${user.email}`, '_blank')} >
                                    <td className="table-cell">{user.email} {user.isVerified && <span>&#x2705;</span>}</td>
                                    <td className="table-cell">{user.firstName || getFirstAndLastName(user)[0] || ''}</td>
                                    <td className="table-cell">{user.lastName || getFirstAndLastName(user)[1] || ''}</td>
                                    <td className="table-cell">{user.currentLocationFootprint?.toUpperCase()}</td>
                                    <td className="table-cell">{user.provider || 'email'}</td>
                                    <td className="table-cell">{user.device?.OS}</td>
                                    <td className="table-cell">{currentDb === Database.SBB ? user.subscriptions?.length : user.bundleIds?.length}</td>
                                </tr>
                            )
                        )
                    }
                </tbody>
            </table>
            <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    </>
}
