import { FC } from 'react';
import { Link } from 'react-router-dom';
import { getMenuList } from '#globals';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '#hooks';
import { Database } from '#types';


export const LeftMenu: FC = () => {

    const { loggedInUser, currentDb } = useAppContext();
    const navigate = useNavigate();
    const isSuperUser: boolean = loggedInUser?.roles?.SUPERUSER || false

    function handleHomeClick(): void {
        if (isSuperUser && currentDb === Database.ZIM) {
            navigate("/")
        }
    }

    return <div className='left-menu-container'>
        <div className='three-columns-expand-one-three'>
            <div />
            <button className='clear-button' onClick={handleHomeClick}>
                <img src={`/images/${currentDb}.png`} alt="profile-pic" id='profile-pic' style={{ width: currentDb === Database.ZIM ? '210px' : '200px' }} />
            </button>
            <div />
        </div>
        <h2>Welcome back,</h2>
        <h2 className='center-wrapper mb-m'>{currentDb.toUpperCase()}</h2>
        <nav>
            <ul className='left-menu-ul underline mb-m'>
                {
                    getMenuList(isSuperUser, currentDb).map(
                        (listItem: string) => <li key={`${listItem}`}>
                            <img src={`/images/${listItem}.png`} alt={`${listItem}`} className='li-icon' />
                            <Link to={`/${listItem}`}>
                                {listItem.toUpperCase()}
                            </Link>
                        </li>
                    )
                }
            </ul>
        </nav>
        <div className='underline pb-l center-wrapper mt-s'></div>
    </div>
}
