import { FC, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './table.css';
import { LoginPage } from '#auth';
import { Dashboard } from '#dashboard/pages/dashboard';
import { UserToDisplay } from '#users/pages/userToDisplay.pages';
import { UserReportChat } from '#reports/pages/userReportChat.pages';
import { useAppContext } from '#hooks'
import { ArchivedChat } from '#livechat/pages/archivedConversation.page';
import { dashboardApi } from '#api';
import { Database, User } from '#types';

const App: FC = () => {

    const { isAuthenticated, setLoggedInUser, setIsAuthenticated, currentEnvironment, loggedInUser, currentDb } = useAppContext();
    const hasCheckedForLoggedInUser = useRef<Boolean>(false)

    const getLoggedInUser = async () => {
        try {
            const response = await dashboardApi.getProfile()
            setLoggedInUser(response.data)
            return true
        } catch {
            setLoggedInUser({} as User)
            return false
        }
    }


    useEffect(
        () => {
            const checkLoggedInUserIsValid = async () => {
                hasCheckedForLoggedInUser.current = true
                setIsAuthenticated(await getLoggedInUser())
            };

            checkLoggedInUserIsValid();

            const handleScroll = () => {
                document.body.classList.add('hide-scrollbar');
            };

            document.addEventListener('scroll', handleScroll);

            return () => {
                document.removeEventListener('scroll', handleScroll);
            };
        },
        [currentEnvironment]
    );

    useEffect(
        () => {
            if(currentDb === Database.SBB) {
                document.documentElement.style.setProperty('--primary-colour-light', '#ffeded');
                document.documentElement.style.setProperty('--primary-colour-light-mid', '#B26666');
                document.documentElement.style.setProperty('--primary-colour-dark-mid', '#800000');
                document.documentElement.style.setProperty('--primary-colour-dark', '#730000');
                document.documentElement.style.setProperty('--primary-colour-accent', '#008080');
                document.documentElement.style.setProperty('--primary-colour-accent-hover', '#99CCCC');
            }
            if(currentDb === Database.GD) {
                document.documentElement.style.setProperty('--primary-colour-light', '#f0f1f1');
                document.documentElement.style.setProperty('--primary-colour-light-mid', '#868686');
                document.documentElement.style.setProperty('--primary-colour-dark-mid', '#131111');
                document.documentElement.style.setProperty('--primary-colour-dark', '#131111');
                document.documentElement.style.setProperty('--primary-colour-accent', '#75977a');
                document.documentElement.style.setProperty('--primary-colour-accent-hover', '#9eb6a1');
            }
            if(!isAuthenticated) {
                document.documentElement.style.setProperty('--primary-colour-light', '#f0ebf8');
                document.documentElement.style.setProperty('--primary-colour-light-mid', '#C8A4D4');
                document.documentElement.style.setProperty('--primary-colour-dark-mid', '#663f83');
                document.documentElement.style.setProperty('--primary-colour-dark', '#3F0A72');
                document.documentElement.style.setProperty('--primary-colour-accent', '#43c197');
                document.documentElement.style.setProperty('--primary-colour-accent-hover', '#5eeac5');
            }
        },
        [currentDb, isAuthenticated]
    );

    return <Router>
        <Routes>
            {
                isAuthenticated ? <>
                    <Route path='/auth/login' element={loggedInUser?.roles?.SUPERUSER || currentDb === Database.SBB || currentDb === Database.GD ? <Navigate to='/' replace /> : <Navigate to='/users' replace />} />
                    <Route path='/users/:email' element={<UserToDisplay />} />
                    <Route index path='/reports/:email' element={<UserReportChat />} />
                    <Route path='/conversations/:conversationId' element={<ArchivedChat />} />
                    <Route path='/*' element={<Dashboard />} />
                </> : <>
                    <Route path='/auth/login' element={<LoginPage />} />
                    <Route path='/*' element={hasCheckedForLoggedInUser.current && <Navigate to='/auth/login' replace />} />
                </>
            }
        </Routes>
    </Router>
}

export default App;
