import { FC, useEffect, useRef } from 'react';
import { Bundle, User } from '#types';
import { useSafeState } from '#hooks';
import { UserSubscriptionNotesScreen } from './userSubscriptionNotesScreen.component';
import { getFullUserName } from '../../services/helpers';
import { BundleCard } from './bundleCard.component';

interface UserInformationProps {
    bundles: Bundle[];
    user: User;
}

export type IccidMap = {
    [key: string]: string;
}

export const UserInformation: FC<UserInformationProps> = ({ user, bundles }) => {

    const [expandedEsimIccids, setExpandedEsimIccids] = useSafeState<IccidMap | null>(null);
    const [selectedSubscriptionForNotes, setSelectedSubscriptionForNotes] = useSafeState<string | null>(null)
    const subscriptionsWithNotesRef = useRef<string[]>([])

    useEffect(
        () => {
            if (bundles?.length > 0) {
                let initialExpandedEsim: IccidMap = {};
                bundles.forEach(
                    ({ esim }) => {
                        initialExpandedEsim[esim.iccid] = esim.iccid;
                    }
                )
                setExpandedEsimIccids(initialExpandedEsim);
            }
        },
        [bundles]
    );

    const handleExpandEsim = (iccid: string) => {
        if (!expandedEsimIccids) {
            return;
        }
        if (expandedEsimIccids[iccid]) {
            const esimIccidsCopy = { ...expandedEsimIccids };
            delete esimIccidsCopy[iccid]
            setExpandedEsimIccids(esimIccidsCopy);
        } else {
            setExpandedEsimIccids({ ...expandedEsimIccids, [iccid]: iccid });
        }
    }

    return <>
        <div className='main-dashboard-container pd-m mt-s'>
            {
                bundles?.length === 0 ?
                    <div>{getFullUserName(user)} Did not purchase any eSIM yet</div> :
                    bundles?.map(
                        (bundle, index: number) => <BundleCard
                            key={`${bundle._id}_${String(index)}`}
                            bundle={bundle}
                            handleExpandEsim={(iccid) => handleExpandEsim(iccid)}
                            expandedEsimIccids={expandedEsimIccids}
                            setSelectedSubscriptionForNotes={setSelectedSubscriptionForNotes}
                            subscriptionsWithNotesRef={subscriptionsWithNotesRef}
                        />
                    )
            }
        </div>
        {
            selectedSubscriptionForNotes ?
                <UserSubscriptionNotesScreen setSelectedSubscriptionForNotes={setSelectedSubscriptionForNotes} subscriptionId={selectedSubscriptionForNotes} user={user} /> :
                <UserSubscriptionNotesScreen setSelectedSubscriptionForNotes={setSelectedSubscriptionForNotes} user={user} subscriptionsWithNotesRef={subscriptionsWithNotesRef} />
        }
    </>
}
