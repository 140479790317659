import { FC, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.page.css'
import { dashboardApi } from '#api';
import { useSafeState } from '#hooks';
import { constants } from '#globals';
import { useAppContext } from '#hooks';
import { Database, EnvironmentType } from '#types';

export const LoginPage: FC = () => {

    const { setLoggedInUser, setIsAuthenticated, setCurrentEnvironment, setCurrentDb } = useAppContext();

    const navigate = useNavigate();
    const [email, setEmail] = useSafeState('');
    const [password, setPassword] = useSafeState('');
    const [targetDb, setTargetDb] = useSafeState<Database>(localStorage.getItem(constants.targetDb) as Database || Database.ZIM)
    const [errorMessage, setErrorMessage] = useSafeState<string>('');
    const zimEmailRegex = /^[\w.+-]+@zimconnections\.com$/;

    const changeTargetDatabase = (db: Database) => {
        localStorage.setItem(constants.targetDb, db)
        setTargetDb(db)
    }

    const isZimEmail = (email: string): boolean => {
        return zimEmailRegex.test(email)
    }

    const submitUserLogin = async (e: FormEvent) => {
        e.preventDefault();

        if (!email || !password) {
            setErrorMessage("Please make sure you have entered both your email and password")
            return
        }

        if (targetDb === Database.ZIM && !isZimEmail(email)) {
            setErrorMessage("The email you have entered is not a ZIM email, please use your ZIM email")
            return
        }

        try {
            const response = await dashboardApi.login(email, password)
            localStorage.setItem(constants.accessToken, response.data.tokens.accessToken)
            localStorage.setItem(constants.refreshToken, response.data.tokens.refreshToken)
            localStorage.setItem(constants.targetEnv, EnvironmentType.PRODUCTION)
            localStorage.setItem(constants.targetDb, targetDb)
            setCurrentEnvironment(EnvironmentType.PRODUCTION)
            setCurrentDb(targetDb)
            setLoggedInUser({ ...response.data.user })
            setIsAuthenticated(true)
            response.data.user.roles.SUPERUSER && targetDb === Database.ZIM ? navigate("/") : navigate("/users")
        } catch {
            setErrorMessage("Your email and/or password were incorrect")
        }
    }

    return <>
        <div className='container center-wrapper'>
            <img src={`/images/${targetDb}.png`} alt="zim-logo-white" className='zim-logo' />
            <form action='' method='get' onSubmit={submitUserLogin} className='mb-m'>
                <div className='login-input-area'>
                    <img src="/images/email.png" alt="email" className='email-icon' />
                    <input id='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' type='email' />
                </div>
                <div className='login-input-area'>
                    <img src="/images/password.png" alt="password" className='password-icon' />
                    <input id='password' name='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' type='password' />
                </div>
                <div className='eight-columns-expand-one-eight mt-l'>
                    <div/>
                    <input
                        type='radio'
                        id='zimRadio'
                        name='databaseSwitch'
                        className='mr-m'
                        checked={targetDb === Database.ZIM}
                        onChange={() => changeTargetDatabase(Database.ZIM)}
                    />
                    <label className='radio-button-login' htmlFor='zimRadio'>{Database.ZIM.toUpperCase()}</label>
                    <input
                        type='radio'
                        id='sbbRadio'
                        name='databaseSwitch'
                        className=' ml-xl mr-m'
                        checked={targetDb === Database.SBB}
                        onChange={() => changeTargetDatabase(Database.SBB)}
                    />
                    <label className='radio-button-login' htmlFor='sbbRadio'>{Database.SBB.toUpperCase()}</label>
                    <input
                        type='radio'
                        id='gdRadio'
                        name='databaseSwitch'
                        className=' ml-xl mr-m'
                        checked={targetDb === Database.GD}
                        onChange={() => changeTargetDatabase(Database.GD)}
                    />
                    <label className='radio-button-login' htmlFor='gdRadio'>{Database.GD.toUpperCase()}</label>
                    <div/>
                </div>
                <input type='submit' id='submit-button' value='LOGIN' />
            </form>
        </div>
        {errorMessage && <p className='error-message mt-m'>{errorMessage}</p>}
    </>
}
