import { dashboardApi } from "#api";
import { FC, useCallback, useEffect, useRef } from "react";
import { useAppContext, useSafeState } from "#hooks";
import { EsimTutorial, Locale, Platform, getRelevantLanguageDisplay } from "#types";
import { PageHeader } from "#dashboard/components/pageHeader.component";
import { HttpStatusCode } from "axios";
import { TutorialCard } from "#tutorials/components/tutorialCard.component";

export const EsimTutorialsPage: FC = () => {

    const { setApiStatus } = useAppContext();

    const esimTutorialsRef = useRef<EsimTutorial[]>([]);

    const [isLoading, setIsLoading] = useSafeState<boolean>(false);
    const [esimTutorials, setEsimTutorials] = useSafeState<EsimTutorial[]>([]);
    const [expandedTutorial, setExpandedTutorial] = useSafeState<EsimTutorial | null>(null);
    const [selectedPlatform, setSelectedPlatform] = useSafeState<Platform>(Platform.IOS);
    const [selectedLocale, setSelectedLocale] = useSafeState<Locale>(Locale.ENGLISH);

    useEffect(
        () => {
            const fetchTutorials = async (): Promise<void> => {
                setIsLoading(true);
                try {
                    const response = await dashboardApi.getEsimTutorials(selectedLocale, selectedPlatform);
                    setEsimTutorials(response.data);
                    esimTutorialsRef.current = response.data;
                } catch (error: any) {
                    console.log('error: ', error);
                    setApiStatus({ code: HttpStatusCode.NotAcceptable, message: error?.message });
                } finally {
                    setIsLoading(false);
                }
            };
            fetchTutorials();
        },
        [selectedLocale, selectedPlatform]
    );

    const handlePlatformChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const platform = e.target.value as Platform;
            setSelectedPlatform(platform);
        },
        []
    );

    const handleLocaleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const locale = e.target.value as Locale;
            setSelectedLocale(locale);
        },
        []
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <>
        <PageHeader header={'eSIM Tutorials'} />
        <div style={{ padding: 10 }} className="main-dashboard-container">
            <select
                style={{ marginBottom: 10 }}
                className="select-dropdown"
                onChange={(e) => handlePlatformChange(e)}
                value={selectedPlatform}

            >
                <option value={Platform.IOS}>{Platform.IOS}</option>
                <option value={Platform.ANDROID}>{Platform.ANDROID}</option>
            </select>
            <select
                style={{ marginLeft: 15 }}
                className="select-dropdown"
                onChange={(e) => handleLocaleChange(e)}
                value={selectedLocale}
            >
                <option value={Locale.ENGLISH}>{getRelevantLanguageDisplay(Locale.ENGLISH)}</option>
                <option value={Locale.FRENCH}>{getRelevantLanguageDisplay(Locale.FRENCH)}</option>
            </select>
            {
                esimTutorials.map(
                    (item: EsimTutorial, index: number) => <TutorialCard
                        setSelectedTutorial={(tutorial: EsimTutorial | null) => setExpandedTutorial(tutorial)}
                        selectedTutorial={expandedTutorial}
                        key={String(index)}
                        tutorial={item} />
                )
            }
        </div>
    </>
}