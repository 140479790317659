import { useAppContext, useSafeState } from '#hooks';
import { FC } from 'react';
import { EditReward } from './editReward.component';
import { Database, FaqSubSectionContent, Locale, Reward, getRelevantLanguages } from '#types';

export const CreateNewReward: FC = () => {

    const { currentDb } = useAppContext();
    const [isExpandedBubble, setIsExpandedBubble] = useSafeState<boolean>(false)
    const src = isExpandedBubble ? '/images/expand.png' : 'images/contract.png';
    const emptyFaqSubSectionArray: FaqSubSectionContent[] = [{type: 'ol', title: '', value: ['']}]
    const availableLanguages = getRelevantLanguages(currentDb, false)
    const emptyReward: Reward = {
        _id: 'create',
        title: '',
        companyName: '',
        subtitle: '',
        description: '',
        companyLogoUrl: '',
        rewardBackgroundUrl: '',
        instructions: emptyFaqSubSectionArray,
        lang: Locale.ENGLISH,
        includesPartnerVoucher: true,
        rewardCode: '',
        active: true,
        validFrom: new Date(),
        validTo: new Date(),
        targetFlagCodes: [],
        priority: 5,
    }
    const [_, setRewardToEdit] = useSafeState<Reward | null>(null)

    return <div className='main-dashboard-container pd-m mt-s'>
        <div className='three-columns-expand-two' onClick={() => setIsExpandedBubble(!isExpandedBubble)}>
            <h3>Create A Reward</h3>
            <div />
            <img src={src} alt="arrow" className='expand-contract-esim vertical-align ml-l' />
        </div>
        { isExpandedBubble && <EditReward availableLanguages={availableLanguages} rewardToEdit={emptyReward} setRewardToEdit={setRewardToEdit} setIsExpandedBubble={setIsExpandedBubble} /> }
    </div>
}