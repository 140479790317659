import { FC, Dispatch, SetStateAction, MutableRefObject } from 'react';
import { Notification, RecipientType, FilterFieldOptions, FilterOperatorOptions, Destination, Locale } from '#types';
import { useAppContext, useSafeState } from '#hooks';
import { dashboardApi } from '#api';

interface NotificationRecipientButtonsProps {
    notification: Notification;
    setNotification: Dispatch<SetStateAction<Notification>>;
    selectedDestinationName: MutableRefObject<string | null>;
}

export const NotificationRecipientButtons: FC<NotificationRecipientButtonsProps> = ({ notification, setNotification, selectedDestinationName }) => {

    const { setApiStatus } = useAppContext();
    const [matchingDestinations, setMatchingDestinations] = useSafeState<Destination[]>([])

    const handleLocationInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFilterChange(event);
        const { value } = event.target;
        if (value.length > 1) {
            try {
                const response = await dashboardApi.getDestinationsByName(value);
                setMatchingDestinations(response.data);
                if (response.data.length === 1) {
                    selectedDestinationName.current = response.data[0].displayName;
                }
            } catch (error: any) {
                setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
            }
        }
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        const newFilter = { ...notification.filter, [id]: value as string };
        setNotification(prevNotification => ({ ...prevNotification, filter: newFilter }))
    };

    return <form className='three-columns-expand-two-recipient mt-m'>
        <div className='two-columns-expand-one-radiobutton'>
            <input
                type="radio"
                id="all"
                className='mr-s notification-radio-button'
                name="sendTo"
                value={RecipientType.ALL}
                checked={notification.recipients === RecipientType.ALL}
                onChange={(event) => setNotification(prevNotification => ({ ...prevNotification, recipients: event.target.value as RecipientType }))}
            />
            <label htmlFor="all" className='vertical-align mr-s'>Send to All</label>
        </div>
        <div className='two-columns-expand-two-sendtoindividual mr-s'>
            <div className='two-columns-expand-one-radiobutton'>
                <input
                    type="radio"
                    id="individual"
                    className='mr-s notification-radio-button'
                    name="sendTo"
                    value={RecipientType.INDIVIDUAL}
                    checked={notification.recipients === RecipientType.INDIVIDUAL}
                    onChange={(event) => setNotification(prevNotification => ({ ...prevNotification, recipients: event.target.value as RecipientType }))}
                />
                <label htmlFor="individual" className='vertical-align mr-s'>Send to individual using email:</label>
            </div>
            <input
                type="text"
                id='target-individual-input'
                className='mg-reset pl-m mr-s'
                value={notification.email}
                onChange={(event) => setNotification(prevNotification => ({ ...prevNotification, email: event.target.value }))}
            />
        </div>
        <div className='three-columns-expand-three-sendtogroup'>
            <div className='two-columns-expand-one-radiobutton'>
                <input
                    type="radio"
                    id="individual"
                    className='mr-s notification-radio-button'
                    name="sendTo"
                    value={RecipientType.FILTERED}
                    checked={notification.recipients === RecipientType.FILTERED}
                    onChange={(event) => setNotification(prevNotification => ({ ...prevNotification, recipients: event.target.value as RecipientType }))}
                />
                <label htmlFor="group" className='vertical-align mr-s'>Or target a group with this filter:</label>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <select id='field' className='mg-reset pl-m mr-s' value={notification.filter?.field} onChange={handleFilterChange}>
                    <option value='' disabled>Select an field</option>
                    <option value={FilterFieldOptions.LOCATION}>Location</option>
                    <option value={FilterFieldOptions.SUBSCRIPTION}>No. of subscriptions</option>
                    <option value={FilterFieldOptions.STEPLER}>Stepler Users</option>
                </select>
                {
                    notification.filter?.field === FilterFieldOptions.SUBSCRIPTION && (
                        <select id='operator' className='mg-reset pl-m mr-s' value={notification.filter?.operator} onChange={handleFilterChange}>
                            <option value='' disabled>Select an operator</option>
                            <option value={FilterOperatorOptions.EQUAL_TO}>{FilterOperatorOptions.EQUAL_TO}</option>
                            <option value={FilterOperatorOptions.GREATER_THAN}>{FilterOperatorOptions.GREATER_THAN}</option>
                        </select>
                    )
                }
            </div>
            {
                notification.filter?.field !== FilterFieldOptions.LOCATION && notification.filter?.field !== FilterFieldOptions.STEPLER && (
                    <input type="text" id='value' className='mg-reset pl-m mr-s' value={notification.filter?.value} onChange={handleFilterChange} />
                )
            }
            {
                notification.filter?.field === FilterFieldOptions.LOCATION && (
                    <div className='mg-reset mr-s'>
                        <input
                            type='text'
                            id='value'
                            value={notification.filter.value}
                            onChange={handleLocationInputChange}
                            list='countries'
                            placeholder='Search for a country...'
                            className='pl-s'
                        />
                        <datalist id='countries'>
                            {
                                matchingDestinations.map(
                                    (destination, index) => (
                                        <option key={index} value={destination.displayName} />
                                    )
                                )
                            }
                        </datalist>
                    </div>
                )
            }
        </div>
    </form>
};
