import { dashboardApi } from '#api';
import { FC, useEffect, useRef } from 'react';
import { Promotion, PromotionsFilter } from '#types';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';
import { Pagination } from '#dashboard/components/pagination.component';
import { PromotionsTable } from '#promotions/components/promotionsTable.component';
import { useAppContext, useSafeState } from '#hooks';
import { PromotionSearchBar } from './promotionsSearchBar.component';
import { EditPromotion } from './editPromotion.component';

export const PromotionsInformation: FC = () => {

    const { setApiStatus, currentEnvironment } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [filter, setFilter] = useSafeState<PromotionsFilter>({});
    const [promotions, setPromotions] = useSafeState<Promotion[]>([])
    const [pageSize, setPageSize] = useSafeState<number>(10);
    const [currentPage, setCurrentPage] = useSafeState<number>(1);
    const [promotionToEdit, setPromotionToEdit] = useSafeState<Promotion | null>(null)
    const totalPagesRef = useRef<number>(1);

    //!endpoint currently fetches only valid promotions, for superuser we would like to see all promotions

    const fetchPromotions = async () => {
        try {
            const response = await dashboardApi.getPromotions(filter, currentPage, pageSize)
            totalPagesRef.current = Math.ceil(response.data.total / pageSize)
            setPromotions([...response.data.promotions]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(
        () => {
            fetchPromotions();
        }, 
        [filter, currentPage, pageSize, currentEnvironment, promotionToEdit]
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    if(promotionToEdit) {
        return <div className='main-dashboard-container pd-m mt-s'>
            <EditPromotion promotionToEdit={promotionToEdit} setPromotionToEdit={setPromotionToEdit} />
        </div>
    }

    return <div className='main-dashboard-container pd-m mt-s'>
        <div className='three-columns-expand-two'>
            <PromotionSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
            <div />
            <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Promotions' />
        </div>
        <PromotionsTable promotions={promotions} setPromotionToEdit={setPromotionToEdit} />
        <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
}