import { Guide, MessageType, Code, Database } from "#types";

export const getMenuList = (isSuperUser: boolean, db: Database) => {
    const menu = ["users", "reports", "livechat", "faq", "blacklist"]
    if (isSuperUser && db !== Database.GD) {
        menu.push("promos", "rewards")
    }
    if (isSuperUser) {
        menu.push("notifications", "locale", 'tutorials', "esims")
    }
    return menu
}

export const importantDatesUL: MessageType[] = [
    {
        date: new Date(Date.parse("01/01/2024")),
        type: "bank",
        message: "New Year's Day"
    },
    {
        date: new Date(Date.parse("01/29/2024")),
        type: "birthday",
        message: "Happy Birthday Ahmad!"
    },
    {
        date: new Date(Date.parse("02/09/2024")),
        type: "birthday",
        message: "Happy Birthday Nick!"
    },
    {
        date: new Date(Date.parse("02/14/2024")),
        type: "celebration",
        message: "Valentines Day"
    },
    {
        date: new Date(Date.parse("03/09/2024")),
        type: "religion",
        message: "First Day of Ramadan"
    },
    {
        date: new Date(Date.parse("04/08/2024")),
        type: "religion",
        message: "Last Day of Ramadan"
    },
    {
        date: new Date(Date.parse("04/01/2024")),
        type: "celebration",
        message: "April Fools Day"
    },
    {
        date: new Date(Date.parse("03/29/2023")),
        type: "bank",
        message: "Good Friday"
    },
    {
        date: new Date(Date.parse("04/01/2023")),
        type: "bank",
        message: "Easter Monday"
    },
    {
        date: new Date(Date.parse("04/25/2023")),
        type: "birthday",
        message: "Happy Birthday Pablo!"
    },
    {
        date: new Date(Date.parse("04/12/2024")),
        type: "birthday",
        message: "Happy Birthday Rashad!"
    },
    {
        date: new Date(Date.parse("05/27/2023")),
        type: "bank",
        message: "Spring Bank Holiday"
    },
    {
        date: new Date(Date.parse("05/06/2024")),
        type: "bank",
        message: "May Day Bank Holiday"
    },
    {
        date: new Date(Date.parse("06/18/2024")),
        type: "birthday",
        message: "Happy birthday Nour!"
    },
    {
        date: new Date(Date.parse("06/18/2023")),
        type: "celebration",
        message: "Father's day"
    },
    {
        date: new Date(Date.parse("07/12/2024")),
        type: "zim",
        message: "Nick Work Anniversary!"
    },
    {
        date: new Date(Date.parse("07/28/2024")),
        type: "birthday",
        message: "Happy birthday Guilia!"
    },
    {
        date: new Date(Date.parse("08/26/2023")),
        type: "bank",
        message: "Summer Bank Holiday"
    },
    {
        date: new Date(Date.parse("09/22/2024")),
        type: "zim",
        message: "Ahmad Work Anniversary!"
    },
    {
        date: new Date(Date.parse("10/01/2024")),
        type: "zim",
        message: "Alex Work Anniversary!"
    },
    {
        date: new Date(Date.parse("11/02/2024")),
        type: "zim",
        message: "Guilia & Irina Work Anniversary!"
    },
    {
        date: new Date(Date.parse("12/01/2024")),
        type: "zim",
        message: "Gess Work Anniversary!"
    },
    {
        date: new Date(Date.parse("12/06/2024")),
        type: "zim",
        message: "Rashad & Nour Work Anniversary!"
    },
    {
        date: new Date(Date.parse("11/02/2024")),
        type: "birthday",
        message: "Happy Birthday ZIM!"
    },
    {
        date: new Date(Date.parse("11/05/2024")),
        type: "celebration",
        message: "Guy Falkes Night"
    },
    {
        date: new Date(Date.parse("11/12/2023")),
        type: "religion",
        message: "Diwali"
    },
    {
        date: new Date(Date.parse("11/23/2024")),
        type: "celebration",
        message: "Thanksgiving"
    },
    {
        date: new Date(Date.parse("11/29/2023")),
        type: "celebration",
        message: "Black Friday"
    },
    {
        date: new Date(Date.parse("02/12/2023")),
        type: "celebration",
        message: "Cyber Monday"
    },
    {
        date: new Date(Date.parse("11/23/2024")),
        type: "birthday",
        message: "Happy Birthday Irina!"
    },
    {
        date: new Date(Date.parse("09/21/2024")),
        type: "birthday",
        message: "Happy Birthday Gabs!"
    },
    {
        date: new Date(Date.parse("12/17/2024")),
        type: "birthday",
        message: "Happy Birthday Alex!"
    },
    {
        date: new Date(Date.parse("12/25/2024")),
        type: "bank",
        message: "Christmas Day"
    },
    {
        date: new Date(Date.parse("12/26/2024")),
        type: "bank",
        message: "Boxing Day"
    },
    {
        date: new Date(Date.parse("12/28/2024")),
        type: "birthday",
        message: "Happy Birthday Gess!"
    },
]

export const userTableHeaders: string[] = [
    "Email",
    "First Name",
    "Last Name",
    "Location",
    "Joined Using",
    "Device OS",
    "Subscriptions"
]

export const esimTableHeaders: string[] = [
    "ICCID",
    "Provider",
    "Email",
    "MSIDN",
    "Status",
]

export const conversationTableHeaders: string[] = [
    "Email",
    "Title",
    "Problem",
    "Created At"
]

export const blacklistedUsersTableHeaders: string[] = [
    "Email",
    "Device ID",
]

export const reportTableHeaders: string[] = [
    "Email",
    "Date",
    "Type",
    "Description",
    "Message",
    "Images",
    "Is Open?"
]

export const plansTableHeaders: string[] = [
    "Product Code",
    "Name",
    "Provider",
    "Country",
    "Data",
    "Duration",
    "Price"
]

export const promotionTableHeaders: string[] = [
    "Code",
    "No. Users",
    "Is Active?",
    "Discount",
    "Type",
    "Scope",
    "One Off Used?",
    "Remaining Uses",
    "Valid From",
    "Valid To"
]

export const rewardsTableHeaders: string[] = [
    "Company Name",
    "Title",
    "Subtitle",
    "Description",
    "Reward Code",
    "Active",
    "Target Flag Codes",
    "Priority",
    // "Instructions",
    // "Additional Info",
    "Valid From",
    "Valid To",
]

export const notificationCodesTableHeaders: string[] = [
    "User Attribute",
    "Code"
]

export const notificationCodes: Code[] = [
    {
        label: 'Customer First Name',
        code: '{firstName}'
    },
    {
        label: 'Customer Last Name',
        code: '{lastName}'
    },
    {
        label: 'Customer Current Location',
        code: '{currentLocation}'
    },
    {
        label: 'Customer Email',
        code: '{email}'
    }
]

export const constants = {
    accessToken: '_accessToken',
    refreshToken: '_refreshToken',
    targetEnv: '_targetEnv',
    targetDb: '_targetDb'
}

export const reportPerPageNumberOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
];

export const genericPerPageNumberOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
];

export const guideToCreateAPromotion: Guide[] = [
    { title: "Title", description: "Heading for the promotion when the customer applies the promotion in the app" },
    { title: "Description", description: "Message displayed to the customer if they click on more information about the promotion" },
    { title: "Code", description: "Used by the customer in the app to apply the promotion, must be 8 digits or less" },
    { title: "Amount", description: "In percentage the discount (only type the number)" },
    { title: "Valid From/To", description: "Dates the promotion will be available for the customers to use, by default the valid from is the time now and valid to is the time tomorrow. If you change these values, the time date will be midnight of the start of the selected date (example: 17/06/2023 00:00:00)" },
    { title: "Background URL", description: "The image that displays behind the promotion in the app" },
    { title: "Target Emails", description: "(optional) Only customers with these emails can use the promotion, should be inputted by typing the emails separated by a commar and space" },
    {
        title: "Type", description: `Changes the behaviour of the promtion as it is used by the customer: \n
        NORMAL - can be used by any customer an unlimited amount of times \n
        PROMOTION - applied to the entire app during a special event (example: black Friday) \n
        LIMITED_USE - number of times the code can be used before expiring. A targeted email and a value for number of uses remaining is needed to create this type. For unlimited use, enter 999 for number of uses remaining. \n
        EVENT - does not have a use for now`
    },
    { title: "Number of uses remaining", description: "How many times the promotion can be used before expiring (only relevant with limited use type promotions)" },
    {
        title: "Scope", description: `Limits the type of customer that can use the promotion: \n
        GLOBAL - any customer can use the code \n
        GROUP - has no use for now 
        EXCLUSIVE - only new customers can use the code \n`
    },
    { title: "*", description: "any field with a * is required for the promotion creation" },
]

export const guideToCreateAReward: Guide[] = [
    { title: "Company Name", description: "The display name of the company offering the reward" },
    { title: "Title", description: "A breif summary of the offer" },
    { title: "Subtitle", description: "Further information for the offer" },
    { title: "Description", description: "Full description of the offer" },
    { title: "Reward Code", description: "Three letter code that identifies the reward, should be the same value for the reward in all languages" },
    { title: "Priority", description: "Number that instructs the order that the rewards are displayed in the app" },
    { title: "Language", description: "Only customers with this preferred language will see the reward" },
    { title: "Instructions", description: `Title: (optional) An introduction into the instruction.\n
        List type: determines whether the values will be listed with a bullet points or numbered list.\n
        Value: each value will display as a new bullet point or number in the list`
    },
    { title: "Additional Information", description: "Same as instructions, but with information relevant to the reward that is not directly how to use the reward." },
    { title: "Active", description: "True or false - is the reward currently active" },
    { title: "Target Flag Codes", description: "A list of 2 letter flag codes that will determine the customers in which countries will see the reward. Leave blank for all" },
    { title: "Reward Background URL", description: "The URL of logo associated with the company offering the reward" },
    { title: "Includes Partner Voucher", description: "True or false - does the reward require a voucher" },
    { title: "Valid From/To", description: "Dates that describe the start and end of the reward" },
]
