import { FC, useEffect } from 'react';
import { useAppContext, useSafeState } from '#hooks';
import { dashboardApi } from '#api';
import { BlacklistedUsers } from '#types';
import { blacklistedUsersTableHeaders } from '#globals';

export const BlacklistTable: FC = () => {

    const { setApiStatus, currentEnvironment } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [blacklistedUsers, setBlacklistedUsers] = useSafeState<BlacklistedUsers[]>([]);

    useEffect(
        () => {
            init();
        },
        [currentEnvironment]
    );

    const init = async () => {
        try {
            const response = await dashboardApi.getBlacklistedUsers()
            setBlacklistedUsers([...response.data]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <div className='pd-m main-dashboard-container mt-s'>
        <table className='center-wrapper table'>
            <thead className="table-header">
                <tr>
                    {
                        blacklistedUsersTableHeaders.map(
                            (header: string) => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    blacklistedUsers.map(
                        (user: BlacklistedUsers, index: number) => (
                            <tr key={`${user._id}_${String(index)}`} className={'table-row'} >
                                <td className="table-cell selectable-text">{user.email}</td>
                                <td className="table-cell selectable-text">{user.macAddress ?? 'N/A'}</td>
                            </tr>
                        )
                    )
                }
            </tbody>
        </table>
    </div>;
}
