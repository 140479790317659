import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UserInformation } from '#users/components/userInformation.component'
import { Bundle, Database, User } from '#types';
import { dashboardApi } from '#api';
import { useAppContext, useSafeState } from '#hooks';
import { formatDate, getFullUserName, isZim } from '../../services/helpers';
import { ApiStatus } from '#dashboard/components/apiStatus.component';
import { AssignEsimForm } from '#users/components/assignEsimForm.component';
import { UserWithBundlesInformation } from '#users/components/userInformationWithBundles.component';

export const UserToDisplay: FC = () => {

    const { apiStatus, loggedInUser, currentDb } = useAppContext();
    const { email } = useParams();
    const [errorMessage, setErrorMessage] = useSafeState<string>('')
    const [user, setUser] = useSafeState<User>({} as User)
    const [bundles, setBundles] = useSafeState<Bundle[]>([]);
    const [displayAssignEsimForm, setDisplayAssignEsimForm] = useSafeState<boolean>(false);
    const [isLoading, setIsLoading] = useSafeState<boolean>(true);
    const hasBundles = currentDb !== Database.SBB

    const fetchTargetUser = async () => {
        try {
            const response = await dashboardApi.getUserProfileAndBundles(email as string);
            if(hasBundles) {
                setUser(response.data.user);
                setBundles(response.data.bundles);
            } else {
                setUser(response.data)
            }
        } catch {
            setErrorMessage('User could not be found, please sign out, sign in and try again. If problem persists, please contact Alex or Ahmad')
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(
        () => {
            fetchTargetUser();
        },
        []
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    if (!user?._id) {
        return <div className='app user-to-display-page'>
            <div className='main-dashboard-container mb-s pd-m'>
                <p className='status-message-failure bold'>{errorMessage}</p>
            </div>
        </div>
    }

    return <div className='app user-to-display-page'>
        <div style={{ alignItems: 'center', padding: 10 }} className='main-dashboard-container pb-m mb-s four-columns-expand-three'>
            <h1 className='selectable-text'>{getFullUserName(user)} {user?.isVerified && <span> &#x2705;</span>}  - {user?.email}</h1>
            <div className='three-rows-expand-one-three'>
                <div />
                <img style={{ width: 60, height: 35, marginLeft: 15, }} src={`https://flagcdn.com/w80/${user?.currentLocationFootprint?.toLowerCase()}.png`} />
                <div />
            </div>
            <div />
            {
                loggedInUser?.roles?.SUPERUSER && <button onClick={() => setDisplayAssignEsimForm(!displayAssignEsimForm)} id='assign-esim-button' className='mr-m pd-s bold'>ASSIGN ESIM</button>
            }
        </div>
        {apiStatus && <ApiStatus />}
        {displayAssignEsimForm && <AssignEsimForm setDisplayAssignEsimForm={setDisplayAssignEsimForm} setUser={setUser} user={user} />}
        {hasBundles ? <UserInformation bundles={bundles} user={user} /> : <UserWithBundlesInformation user={user} />}
        <div className='main-dashboard-container mb-s pd-m'>
            <p>Device: {user?.device?.model}</p>
            <p>Joined ZIM on {formatDate(user?.createdAt)}</p>
            <p>Last visited the app on {formatDate(user?.updatedAt)}</p>
        </div>
    </div>
}
