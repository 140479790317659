import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '#hooks';

export const NotFoundPage: FC = () => {

    const { loggedInUser, setPageFound } = useAppContext();

    const navigate = useNavigate()
    
    useEffect(
        () => {
            setPageFound(false)
        },
        []
    );

    const goHomeClick = (): void => {
        setPageFound(true)
        loggedInUser.roles.SUPERUSER ? navigate('/') : navigate('/users')
    }

    return <div
        className='main-dashboard-container pb-m center-wrapper'
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
            width: '95vw',
            backgroundColor: '#f9fafb',
            fontFamily: 'sans-serif',
        }}
        >
        <h1
            style={{
                fontSize: '5rem',
                fontWeight: 'bold',
                color: '#1a202c',
                marginBottom: '2rem',
            }}
        >
            404
        </h1>
        <p
            style={{
                fontSize: '2rem',
                color: '#4a5568',
                textAlign: 'center',
                marginBottom: '2rem',
            }}
        >
            Yikes... looks like you got lost! Better head back home!
        </p>
        <button
            className='clear-button bold'
            onClick={goHomeClick}
            style={{
                fontSize: "1.5rem",
                color: "#fff",
                backgroundColor: "#3F0A72",
                padding: "1rem 2rem",
                borderRadius: "0.5rem",
                textDecoration: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                transition: "background-color 0.3s ease",
            }}
        >
            GO HOME
        </button>
    </div>
};
