import { FC } from 'react';
import { Database, User } from '#types';
import { dashboardApi } from '#api';
import { useAppContext, useSafeState } from '#hooks';

export const DownloadUsersButtons: FC = () => {

    const { setApiStatus, currentDb } = useAppContext();
    const [csvIsLoading, setCsvIsLoading] = useSafeState<boolean>(false)
    const [textIsLoading, setTextIsLoading] = useSafeState<boolean>(false)
    const isZim = currentDb === Database.ZIM
    
    const removeCommasFromCountryNames = (countryName: string): string => {
        return countryName?.replace(/,/g, "")
    }
    
    const fetchUsersFromDB = async () => {
        try {
            const response = await dashboardApi.getUsers();
            return response.data.users
        } catch(error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    function formatDate(date: string) {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        if (!date || !regex.test(date)) {
            return "Invalid";
        }
        const [datePart] = date.split('T');
        const [year, month, day] = datePart.split('-');
        return `${day}/${month}/${year}`;
    }

    async function createCsv(): Promise<void> {
        setCsvIsLoading(true)
        const users: User[] = await fetchUsersFromDB()
        let csvContent: string

        if (isZim) {
            csvContent = `firstName,lastName,email,currentLocation,subscriptions,createdAt\n`
            users.forEach(
                (customer: User) => {
                    const row = `${customer.firstName},${customer.lastName},${customer.email},${removeCommasFromCountryNames(customer.currentLocation)},${customer.subscriptions?.length},${formatDate(customer.createdAt)}\n`;
                    csvContent += row;
                }
            );
        } else {
            csvContent = `fullName,lastName,email,currentLocation,subscriptions,createdAt\n`
            users.forEach(
                (customer: User) => {
                    const row = `${customer.fullName},${customer.email},${removeCommasFromCountryNames(customer.currentLocation)},${customer.subscriptions?.length},${customer.createdAttoLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}\n`;
                    csvContent += row;
                }
            );
        }

        const dataToReturn = new Blob([csvContent], { type: 'text/csv' });
        const d = new Date()
        const dateString = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
            + "-" + ("0" + d.getHours()).slice(-2) + "h" + ("0" + d.getMinutes()).slice(-2) + "m"

        const fileName = isZim ? `zim-customers-${dateString}.csv` : `sbb-customers-${dateString}.csv`;
        saveFile(dataToReturn, fileName);
        setCsvIsLoading(false)
    }

    async function createTxt(): Promise<void> {
        setTextIsLoading(true)
        const users: User[] = await fetchUsersFromDB()

        let txtContent = ''

        users.forEach(
            (customer: User) => {
                const row = `${customer.email}\n`;
                txtContent += row;
            }
        );

        const dataToReturn = new Blob([txtContent], { type: 'text/plain' });
        const d = new Date()
        const dateString = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
            + "-" + ("0" + d.getHours()).slice(-2) + "h" + ("0" + d.getMinutes()).slice(-2) + "m"

        const fileName = isZim ? `zim-customers-email-${dateString}.csv` : `sbb-customers-email-${dateString}.csv`;
        saveFile(dataToReturn, fileName);
        setCsvIsLoading(false)
    }

    function saveFile(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
    }

    return <div>
        <div className='main-dashboard-container pd-m mt-s three-columns-expand-three'>
            <span className='vertical-align'>Click this button to download all the users</span>
            {
                csvIsLoading ? <div className="lds-hourglass" /> : <button onClick={createCsv} className='download-button ml-m pd-s bold'>DOWNLOAD USERS AS CSV</button>
            }
            <div />
        </div>
        <div className='main-dashboard-container pd-m mt-s three-columns-expand-three'>
            <span className='vertical-align'>Click this button to download a list of user emails</span>
            {
                textIsLoading ? <div className="lds-hourglass" /> : <button onClick={createTxt} className='download-button bold ml-m pd-s'>DOWNLOAD USERS AS TXT</button>
            }
            <div />
        </div>
    </div>
}
