import React, { FC, createContext, useContext, useEffect, Dispatch, SetStateAction } from "react";
import { User, Status, EnvironmentType, Database } from "#types";
import { useSafeState } from "#hooks";
import { constants } from "#globals";

//TODO: make pageFound a useRef
//TODO: when setApiStatus is triggered, there are a few rerenders

interface AppContextType {
    loggedInUser: User;
    setLoggedInUser: Dispatch<SetStateAction<User>>;
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
    isExpanded: boolean;
    setIsExpanded: Dispatch<SetStateAction<boolean>>;
    pageFound: boolean;
    setPageFound: Dispatch<SetStateAction<boolean>>;
    apiStatus: Status;
    setApiStatus: Dispatch<SetStateAction<Status>>;
    currentEnvironment: EnvironmentType;
    setCurrentEnvironment: Dispatch<SetStateAction<EnvironmentType>>;
    currentDb: Database;
    setCurrentDb: Dispatch<SetStateAction<Database>>;
}

export const AppContext = createContext<AppContextType>({
    loggedInUser: {} as User,
    setLoggedInUser: () => {},
    isAuthenticated: false,
    setIsAuthenticated: () => {},
    isExpanded: false,
    setIsExpanded: () => {},
    pageFound: true,
    setPageFound: () => {},
    apiStatus: {} as Status,
    setApiStatus: () => {},
    currentEnvironment: EnvironmentType.PRODUCTION,
    setCurrentEnvironment: () => {},
    currentDb: Database.ZIM,
    setCurrentDb: () => {},
});

export const useAppContext = () => useContext(AppContext);

interface AppProviderProps {
    children: React.ReactNode;
}

export const AppProvider: FC<AppProviderProps> = React.memo(({ children }) => {

    const [ loggedInUser, setLoggedInUser ] = useSafeState<User>({} as User);
    const [ isAuthenticated, setIsAuthenticated ] = useSafeState<boolean>(false);
    const [ isExpanded, setIsExpanded ] = useSafeState<boolean>(false);
    const [ pageFound, setPageFound ] = useSafeState<boolean>(true);
    const [ apiStatus, setApiStatus ] = useSafeState<Status>({} as Status);
    const [ currentEnvironment, setCurrentEnvironment ] = useSafeState<EnvironmentType>(localStorage.getItem(constants.targetEnv) as EnvironmentType || EnvironmentType.PRODUCTION);
    const [ currentDb, setCurrentDb ] = useSafeState<Database>(localStorage.getItem(constants.targetDb) as Database || Database.ZIM)

    useEffect(
        () => {
            let timer: ReturnType<typeof setTimeout>;
            if (apiStatus?.message?.length > 0) {
                timer = setTimeout(
                    () => {
                        setApiStatus({} as Status);
                    },
                    15000 //15 seconds
                );
            }
            return () => clearTimeout(timer);
        },
        [apiStatus]
    );

    const value = {
        loggedInUser,
        setLoggedInUser,
        isAuthenticated,
        setIsAuthenticated,
        isExpanded,
        setIsExpanded,
        pageFound,
        setPageFound,
        apiStatus,
        setApiStatus,
        currentEnvironment,
        setCurrentEnvironment,
        currentDb,
        setCurrentDb
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
});
