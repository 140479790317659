import { FC, useEffect } from 'react';
import { LeftMenu } from '#dashboard/pages/leftMenu';
import { MainDashboardContainer } from '#dashboard/pages/mainDashboardContainer'
import { Header } from '#dashboard/pages/header'
import '../../dashboard.css'
import { useAppContext } from '#hooks';

export const Dashboard: FC = () => {

    const { isExpanded, setIsExpanded, pageFound, loggedInUser } = useAppContext();

    useEffect(
        () => {
            
            function handleResize() {
                if (window.innerWidth < 800) {
                    setIsExpanded(true);
                }
            }

            if (window.innerWidth < 800) {
                setIsExpanded(true);
            }
        
            window.addEventListener('resize', handleResize);
        
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, 
        [loggedInUser]
    );
 
    return <div className='app'>
        <div className='dashboard-container two-columns-expand-two'>
            <div>{pageFound && !isExpanded && <LeftMenu />}</div>
            <div>
                <Header />
                <MainDashboardContainer />
            </div>
        </div>
    </div>
}
