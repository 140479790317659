import { FC } from 'react';
import { useSafeState } from '#hooks';
import { guideToCreateAReward } from '#globals'
import { Guide } from '#types';

export const GuideToCreateReward: FC = () => {

    const [isExpandedBubble, setIsExpandedBubble] = useSafeState<boolean>(false)
    const src = isExpandedBubble ? '/images/expand.png' : 'images/contract.png';

    return <div className='main-dashboard-container pd-m mt-s'>
        <div className='three-columns-expand-two' onClick={() => setIsExpandedBubble(!isExpandedBubble)}>
            <h3>Creating Or Editing A Reward Guide</h3>
            <div />
            <img src={src} alt="arrow" className='expand-contract-esim vertical-align ml-l' />
        </div>
        {
            isExpandedBubble &&  <div className='three-columns-expand-two'>
                <div>
                    {
                        guideToCreateAReward.map(
                            (paragraph: Guide, index: number) => (
                                <div key={index} className='pt-m'>
                                    <span className='bold'>{paragraph.title} - </span>
                                    {
                                        paragraph.description.split('\n').map(
                                            (line, i) => (
                                                <p key={i} className={i === 0 ? 'display-inline' : 'ml-l'}>
                                                    {line}
                                                </p>
                                            )
                                        )
                                    }
                                </div>
                            )
                        )
                    }
                </div>
                <div/>
                <img src='/images/rewardInstructions.png' alt="guide to create a reward" id='reward-guide' />
            </div>
        }
    </div>
}
