import { Dispatch, FC, SetStateAction, MouseEvent, MutableRefObject } from 'react';
import { dashboardApi } from '#api';
import { RecipientType, NotificationReqBody, FilterFieldOptions, Notification, Status, Locale } from '#types';
import { useAppContext, useSafeState } from '#hooks';
import { notificationCodes } from '#globals';
import { HttpStatusCode } from 'axios';

interface ConfirmationSendNotificationProps {
    setIsConfirmationOpen: Dispatch<SetStateAction<boolean>>;
    notification: Notification;
    selectedDestinationName: MutableRefObject<string | null>;
}

export const ConfirmationSendNotification: FC<ConfirmationSendNotificationProps> = ({ setIsConfirmationOpen, notification, selectedDestinationName }) => {

    const { setApiStatus } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(false)

    const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsConfirmationOpen(false);
    }

    //TODO: adjust imageUrl
    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let requestBody: NotificationReqBody;
        switch (notification.recipients) {
            case RecipientType.ALL:
                requestBody = { title: notification.title, body: notification.body, imageUri: "welcome.png", type: notification.type, ...(notification.lang !== Locale.ALL && { lang: notification.lang }) };
                break;
            case RecipientType.FILTERED:
                if (notification.filter.field === FilterFieldOptions.LOCATION) {
                    notification.filter.value = selectedDestinationName.current as string
                }
                requestBody = { title: notification.title, body: notification.body, type: notification.type, imageUri: "welcome.png", filter: notification.filter, ...(notification.lang !== Locale.ALL && { lang: notification.lang }) };
                break;
            case RecipientType.INDIVIDUAL:
                requestBody = { title: notification.title, body: notification.body, type: notification.type, imageUri: "welcome.png", email: notification.email };
                break;
            default:
                setApiStatus({
                    code: HttpStatusCode.BadRequest,
                    message: "There has been an error in the format of the notification, please correct and try again"
                });
                return
        }
        try {
            setIsLoading(true);
            const res = await dashboardApi.sendNotification(requestBody)
            //TODO add a status/sent boolean to the notification object
            setApiStatus({ code: res.status, message: res.data.message })
            setIsConfirmationOpen(false)
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    const confirmationRecipients = (): string => {
        switch (notification.recipients) {
            case RecipientType.ALL:
                return 'All users';
            case RecipientType.FILTERED:
                if (notification.filter.field === FilterFieldOptions.SUBSCRIPTION) {
                    return `All users that have ${notification.filter.operator} ${notification.filter.value} ${notification.filter.field}${notification.filter.value === '1' ? '' : 's'}`;
                } else if (notification.filter.field === FilterFieldOptions.STEPLER) {
                    return 'All users that come from Stepler';
                } else {
                    return `All users that their ${notification.filter.field} is ${notification.filter.value}`;
                }
            case RecipientType.INDIVIDUAL:
                return notification.email as string;
            default:
                return '';
        }
    };

    const highlightCodes = (text: string): JSX.Element => {
        const regexString = notificationCodes.map((code) => code.code.slice(1, -1)).join('|');
        const regex = new RegExp(`({(?:${regexString})})`, 'g');
        const parts = text.split(regex);
        return (
            <>
                {
                    parts.map(
                        (part, i) => {
                            const className = regex.test(part) ? 'highlight-accent' : '';
                            return <span className={className} key={i} >{part}</span>
                        }
                    )
                }
            </>
        );
    };

    return <div className="main-dashboard-container pd-m mt-s line-height">
        <h2>Please Check And Submit The Notification To Send</h2>
        <div className='min-width mt-m'>
            <span className='bold'>Title: </span>
            <p>{highlightCodes(notification.title)}</p>
        </div>
        <div className='min-width mt-s'>
            <span className='bold'>Body: </span>
            <span>{highlightCodes(notification.body)}</span>
        </div>
        <div className='min-width mt-s'>
            <span className='bold'>Type: </span>
            <span>{notification.type}</span>
        </div>
        <div className='min-width mt-s'>
            <span className='bold'>Recipient/s: </span>
            <span>{confirmationRecipients()}</span>
        </div>
        {
            notification.recipients !== RecipientType.INDIVIDUAL && <div className='min-width mt-s'>
                <span className='bold'>Language: </span>
                <span>{notification.lang}</span>
            </div>
        }
        {
            isLoading ?
                <div className="lds-hourglass" /> :
                <div className="mt-m four-columns-expand-one-four">
                    <div />
                    <button onClick={handleSubmit} id='notification-confirmation-button' className='pd-s mr-m bold'>SUBMIT</button>
                    <button onClick={handleCancel} id='notification-confirmation-button' className='pd-s bold'>CANCEL</button>
                    <div />
                </div>
        }
    </div>
}
