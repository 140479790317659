import { dashboardApi } from '#api';
import { FC, useEffect, useRef } from 'react';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';
import { Pagination } from '#dashboard/components/pagination.component';
import { useAppContext, useSafeState } from '#hooks';
import { Database, Locale, Reward, RewardsFilter, getRelevantLanguages } from '#types';
import { EditReward } from './editReward.component';
import { RewardsSearchBar } from './rewardsSearchBar.component';
import { RewardsTable } from './rewardsTable.component';

export const RewardsInformation: FC = () => {

    const { setApiStatus, currentEnvironment, currentDb } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [filter, setFilter] = useSafeState<RewardsFilter>({});
    const [rewards, setRewards] = useSafeState<Reward[]>([])
    const [pageSize, setPageSize] = useSafeState<number>(10);
    const [currentPage, setCurrentPage] = useSafeState<number>(1);
    const [rewardToEdit, setRewardToEdit] = useSafeState<Reward | null>(null)
    const [lang, setLang] = useSafeState<Locale>(Locale.ENGLISH)
    const totalPagesRef = useRef<number>(1);
    const availableLanguages = getRelevantLanguages(currentDb, false)

    const fetchRewards = async () => {
        try {
            const response = await dashboardApi.getRewards(currentPage, pageSize, lang, filter)
            totalPagesRef.current = Math.ceil(response.data.total / pageSize)
            setRewards([...response.data.rewards]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(
        () => {
            fetchRewards();
        }, 
        [filter, currentPage, pageSize, currentEnvironment, rewardToEdit, lang]
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    if(rewardToEdit) {
        return <div className='main-dashboard-container pd-m mt-s'>
            <EditReward rewardToEdit={rewardToEdit} setRewardToEdit={setRewardToEdit} availableLanguages={availableLanguages} />
        </div>
    }

    return <div className='main-dashboard-container pd-m mt-s'>
        <div className='three-columns-expand-two'>
            <RewardsSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
            <div />
            <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Rewards' />
        </div>
        <RewardsTable rewards={rewards} setRewardToEdit={setRewardToEdit} lang={lang} setLang={setLang} availableLanguages={availableLanguages} />
        <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
}