import { useAppContext, useSafeState } from '#hooks';
import { BackgroundUrlOptions, PromoCodeScope, PromoCodeType, Promotion } from '#types';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { dashboardApi } from '#api';
import { HttpStatusCode } from 'axios';

interface EditPromotionProps {
    promotionToEdit: Promotion
    setPromotionToEdit: Dispatch<SetStateAction<Promotion | null>>
}

export const EditPromotion: FC<EditPromotionProps> = ({ promotionToEdit, setPromotionToEdit }) => {

    const { setApiStatus } = useAppContext();
    const [updatedPromotion, setUpdatedPromotion] = useSafeState<Promotion>(promotionToEdit)

    const updatePromotion = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const updatedPromotionProperties: Partial<Promotion> = {}
            for (const property in updatedPromotion) {
                if (updatedPromotion.hasOwnProperty(property) &&
                    promotionToEdit[property] !== updatedPromotion[property]) {
                    updatedPromotionProperties[property] = updatedPromotion[property];
                }
            }
            if (Object.keys(updatedPromotionProperties).length === 0) {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: 'No changes made to the promotion' })
                return
            }
            if(updatedPromotionProperties.amount) {
                updatedPromotionProperties.amount = Number(updatedPromotionProperties.amount)
            }
            if(updatedPromotionProperties.numberOfUsesRemaining) {
                updatedPromotionProperties.numberOfUsesRemaining = Number(updatedPromotionProperties.numberOfUsesRemaining)
            }
            if(typeof updatedPromotionProperties.targetEmails === 'string') {
                if(updatedPromotionProperties.targetEmails === '') {
                    updatedPromotionProperties.targetEmails = []
                } else {
                    updatedPromotionProperties.targetEmails = updatedPromotionProperties.targetEmails.split(',').map(email => email.trim());
                }
            }
            const response = await dashboardApi.updatePromotion(promotionToEdit._id, updatedPromotionProperties)
            setApiStatus({ code: response.status, message: response.data.message })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setPromotionToEdit(null)
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdatedPromotion({ ...updatedPromotion, [name]: value });
    };

    const handleInputChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setUpdatedPromotion({ ...updatedPromotion, [event.target.name]: event.target.value});
    };

    return <div className="main-dashboard-container mb-s pd-m">
        <h3 className='mb-m'>Edit A Promo Code</h3>
        <form onSubmit={(event) => updatePromotion(event)}>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="title" className='bold label-width'>Title</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={updatedPromotion.title || ''}
                    onChange={handleChange}
                    className='bold pd-s input-width'
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="description" className='bold label-width'>Description</label>
                <input
                    type="text"
                    id="description"
                    name="description"
                    value={updatedPromotion.description || ''}
                    onChange={handleChange}
                    className='bold pd-s input-width'
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="code" className='bold label-width'>Code</label>
                <input
                    type="text"
                    id="code"
                    name="code"
                    value={updatedPromotion.code}
                    maxLength={8}
                    onChange={handleChange}
                    className='bold pd-s input-width'
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="amount" className='bold label-width'>Amount</label>
                <input
                    type="number"
                    id="amount"
                    name="amount"
                    value={updatedPromotion.amount}
                    onChange={handleChange}
                    className='bold pd-s input-width'
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="targetEmails" className='bold label-width'>Target Emails</label>
                <input
                    type="text"
                    id="targetEmails"
                    name="targetEmails"
                    value={updatedPromotion.targetEmails || ''}
                    onChange={handleChange}
                    className='bold pd-s input-width'
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="validFrom" className='bold label-width'>Valid From</label>
                <input
                    type="date"
                    id="validFrom"
                    name="validFrom"
                    value={updatedPromotion.validFrom ? new Date(updatedPromotion.validFrom).toISOString().split('T')[0] : ''}
                    onChange={handleChange}
                    className='bold pd-s input-width'
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="validTo" className='bold label-width'>Valid To</label>
                <input
                    type="date"
                    id="validTo"
                    name="validTo"
                    className='pl-m promo-input'
                    value={updatedPromotion.validTo ? new Date(updatedPromotion.validTo).toISOString().split('T')[0] : ''}
                    onChange={handleChange}
                />
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="scope" className='bold label-width'>Scope</label>
                <select
                    id='scope'
                    name='scope'
                    className='bold pd-s input-width'
                    value={updatedPromotion.scope}
                    onChange={handleInputChange}
                >
                    {
                        Object.values(PromoCodeScope).map(
                            (option) => (
                                <option key={option} value={option}>{option}</option>
                            )
                        )
                    }
                </select>
                <div/>
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="type" className='bold label-width'>Type</label>
                <select
                    id='type'
                    name='type'
                    className='bold pd-s input-width'
                    value={updatedPromotion.type}
                    onChange={handleInputChange}
                >
                    {
                        Object.values(PromoCodeType).map(
                            (option) => (
                                <option key={option} value={option}>{option}</option>
                            )
                        )
                    }
                </select>
                <div/>
            </div>
            {
                updatedPromotion.type === PromoCodeType.LIMITED_USE && <div className='three-columns-expand-three mb-m'>
                    <label htmlFor="type" className='bold label-width'>Number of Uses</label>
                    <input
                        type="number"
                        id="numberOfUsesRemaining"
                        name="numberOfUsesRemaining"
                        className='bold pd-s input-width'
                        value={updatedPromotion.numberOfUsesRemaining}
                        onChange={handleChange}
                    />
                    <div/>
                </div>
            }
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor='backgroundUrl' className='bold label-width'>Background URL</label>
                <select
                    id='backgroundUrl'
                    name='backgroundUrl'
                    className='bold pd-s input-width'
                    value={updatedPromotion.backgroundUrl}
                    onChange={handleInputChange}
                >
                    {
                        Object.values(BackgroundUrlOptions).map(
                            (option) => (
                                <option key={option} value={option}>
                                    {
                                        option
                                            .replace('https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/', '')
                                            .replace('.png', '')
                                            .replace(/\b\w/g, (match) => match.toUpperCase())
                                            .replace(/[-_]/g, ' ')
                                    }
                                </option>
                            )
                        )
                    }
                </select>
                <div/>
            </div>
            <div className='four-columns-expand-one-four'>
                <div/>
                <div className="three-columns-expand-one-three mt-m">
                    <div />
                    <button type='button' className="center-wrapper bold pagination-button white-text mb-s pd-s" onClick={() => setPromotionToEdit(null)}>
                        CANCEL
                    </button>
                    <div />
                </div>
                <div className="three-columns-expand-one-three mt-m ml-m">
                    <div />
                    <button type="submit" className="center-wrapper bold pagination-button white-text mb-s pd-s">SAVE</button>
                    <div />
                </div>
                <div/>
            </div>
        </form>
    </div>
}
