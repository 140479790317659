import { Dispatch, FC, SetStateAction } from 'react';
import moment from 'moment';
import { Subscription } from '#types';
import { formatBytes } from '../../services/helpers';

interface SubscriptionToDisplayProps {
    subscription: Subscription
    setSelectedSubscriptionForNotes: Dispatch<SetStateAction<string | null>>;
    hasNotes: boolean;
}

export const SubscriptionToDisplay: FC<SubscriptionToDisplayProps> = ({ subscription, setSelectedSubscriptionForNotes, hasNotes }) => {
    //TODO: implement paymentStatus fetch from services
    /*
    const [paymentStatus, setPaymentStatus] = useSafeState<string>('')

    const checkPaymentStatus = async (subscriptionId: string) => {
        try {
            const response = await dashboardApi.getPaymentInformationBySubscriptionId(subscriptionId);
            setPaymentStatus(response.data.status);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(
        () => {
            checkPaymentStatus(subscription._id);
        }
    );
    */

    const purchaseDate = moment(subscription.createdDate).format('DD/MM/YYYY') ?? 'Not Available';
    const activationDate = moment(subscription.activationDate).format('DD/MM/YYYY') ?? 'Not Available';
    const expirationDate = moment(subscription.expiryDate).format('DD/MM/YYYY') ?? 'Not Available';

    return <div
            style={{ padding: 15, width: 320, display: 'flex', flexDirection: 'column' }}
            key={subscription._id}
            className='a-tag-reset faq-li center-wrapper border-radius-s line-height'
        >
        <div className='three-columns-expand-two mb-m'>
            <h3 className='bold selectable-text'>{subscription.name}</h3>
            <div/>
            <img
                className='notes-icon vertical-align'
                src={hasNotes ? '/images/notes-accent.png' : '/images/notes.png'}
                alt="notes"
                onClick={() => {setSelectedSubscriptionForNotes(subscription._id as string)}}
            />
        </div>
        <ul>
            {/* <li>
                <div>Payment Status: <span className='bold'>{paymentStatus.toUpperCase()}</span></div>
            </li> */}
            <li>
                <div className='selectable-text'>Subscription Status: <span className='bold selectable-text'>{subscription.status.toUpperCase()}</span></div>
            </li>
            <li>
                <div className='selectable-text'>Purchase Date: <span className='bold selectable-text'>{purchaseDate}</span></div>
            </li>
            <li>
                <div className='selectable-text'>Activation Date: <span className='bold selectable-text'>{subscription.activationDate === " " ? "Not activated yet" : activationDate}</span></div>
            </li>
            <li>
                <div className='selectable-text'>ID: {subscription._id}</div>
            </li>
            {
                subscription.activationDate !== " " &&
                <li>
                    <div className='selectable-text'>Expires On: <span className='bold selectable-text'>{expirationDate}</span></div>
                </li>
            }
            {
                subscription.initialBalance > 0 && subscription.currentBalance > 0 && <li>
                    <div>
                        Data:
                        <progress
                            value={(subscription.currentBalance / subscription.initialBalance) * 100}
                            max={100}
                            className='ml-s progress-bar'
                        />
                        <span className='ml-s'>{formatBytes(subscription.currentBalance)}</span>
                    </div>
                </li>
            }
        </ul>
    </div>
}