import { dashboardApi } from '#api';
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { Pagination } from '#dashboard/components/pagination.component';
import { useAppContext, useSafeState } from '#hooks';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';
import { PlansSearchBar } from '#users/components/plansSearchBar.component';
import { Plan, PlansFilter } from '#types'
import { plansTableHeaders } from '#globals'

interface PlansSearchBarProps{
    setSelectedPlan: Dispatch<SetStateAction<Plan>>;
    setShowConfirmation: Dispatch<SetStateAction<boolean>>;
}

export const PlansTable: FC<PlansSearchBarProps> = ({ setSelectedPlan, setShowConfirmation }) => {

    const { setApiStatus, currentEnvironment } = useAppContext();
    const [ isLoading, setIsLoading ] = useSafeState<boolean>(true)
    const [ pageSize, setPageSize ] = useSafeState<number>(10);
    const [ currentPage, setCurrentPage ] = useSafeState<number>(1);
    const [ filter, setFilter ] = useSafeState<PlansFilter>({});
    const [ plans, setPlans ] = useSafeState<Plan[]>([])
    const totalPagesRef = useRef<number>(1);

    const fetchPlans = async () => {
        try {
            const response = await dashboardApi.getPlans(currentPage, pageSize, filter)
            if(response.data.lastPage === 0) {
                totalPagesRef.current = 1
            } else {
                totalPagesRef.current = response.data.lastPage
            }
            setPlans([...response.data.data]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    const handlePlanClick = (plan: Plan) => {
        setSelectedPlan(plan)
        setShowConfirmation(true)
    }

    useEffect(
        () => {
            fetchPlans();
        },
        [filter, currentPage, pageSize, currentEnvironment]
    );


    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <>
        <div className='pd-m main-dashboard-container mt-s'>
            <div className='three-columns-expand-two'>
                <PlansSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
                <div />
                <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Plans' />
            </div>
            <table className='center-wrapper table'>
                <thead className="table-header">
                    <tr>
                        {
                            plansTableHeaders.map(
                                (header: string) => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        plans.map(
                            (plan: Plan, index: number) => (
                                <tr key={`${plan._id}_${String(index)}`} className={'table-row'} onClick={() => handlePlanClick(plan)} >
                                    <td className="table-cell">{plan.productCode}</td>
                                    <td className="table-cell">{plan.name}</td>
                                    <td className="table-cell">{plan.provider}</td>
                                    <td className="table-cell">{plan.destinationName}</td>
                                    <td className="table-cell">{plan.data} {plan.dataUnit}</td>
                                    <td className="table-cell">{plan.duration} Day{plan.duration !== 1 && 's'}</td>
                                    <td className="table-cell">£{plan.priceGbp}</td>
                                </tr>
                            )
                        )
                    }
                </tbody>
            </table>
            <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    </>
}
