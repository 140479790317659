import { dashboardApi } from "#api";
import { FC, useEffect, useRef } from "react";
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { useAppContext, useSafeState } from "#hooks";
import { Translation } from "#types";
import { PageHeader } from "#dashboard/components/pageHeader.component";
import { HttpStatusCode } from "axios";

export const TranslationsPage: FC = () => {

    const { setApiStatus } = useAppContext();
    const editorRef = useRef<JSONEditor | null>(null);
    const componentLoaded = useRef<boolean>(false)
    const [containerNode, setContainerNode] = useSafeState<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [translations, setTranslations] = useSafeState<Translation[]>([]);
    const [selectedTranslation, setSelectedTranslation] = useSafeState<Translation>({} as Translation);

    const init = async (): Promise<void> => {
        try {
            const response = await dashboardApi.getTranslations();
            setTranslations(response.data);
            setSelectedTranslation(response.data[0]);
        } catch (error: any) {
            setApiStatus({ code: HttpStatusCode.NotAcceptable, message: error?.message });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(
        () => {
            init();
        },
        []
    );

    useEffect(
        () => {
            if (containerNode && !componentLoaded.current) {
                const options: any = { modes: ['form', 'code', 'text', 'tree', 'view'] };

                if (editorRef.current) {
                    editorRef.current.destroy();
                }

                const editor = new JSONEditor(containerNode, options);
                editorRef.current = editor;

                if (selectedTranslation) {
                    editor.set(selectedTranslation.translations);
                }

                componentLoaded.current = true
            }
        },
        [containerNode, selectedTranslation]
    );

    const isJsonValid = async (): Promise<boolean> => {
        if (editorRef.current) {
            const errors = await editorRef.current.validate();
            return errors.length === 0;
        }
        return false;
    };

    const handleSave = async (): Promise<void> => {
        if (!(await isJsonValid())) {
            alert("Please correct the errors before saving the file!");
            return;
        }
        setIsLoading(true);
        componentLoaded.current = false
        const updatedTranslations = editorRef?.current?.get();
        try {
            const response = await dashboardApi.updateTranslation(selectedTranslation._id.toString(), updatedTranslations);
            alert(response.data.message);
            init();
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
            alert('Failed to update translations');
        } finally {
            setIsLoading(false);
        }
    };

    const handleLocaleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedLocale = e.target.value;
        const selectedTrans = translations.find(t => t.locale === selectedLocale);
        componentLoaded.current = false
        if (selectedTrans) {
            setSelectedTranslation(selectedTrans);
        }
    };

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <>
        <PageHeader header={'ZIM Mobile App Translation'} />
        <div className="main-dashboard-container">
            <select className="select-dropdown mt-l ml-l" onChange={handleLocaleChange}>
                {
                    translations.map((translation: Translation, index: number) =>
                        <option key={String(index)} value={translation.locale}>{translation.title}</option>
                    )
                }
            </select>
            <div ref={setContainerNode} id="jsoneditor" className="code-editor mg-m" />
            <center>
                <button id="save-button" className="mb-m" onClick={handleSave}>SAVE</button>
            </center>
        </div>
    </>
}