import { FC } from 'react';
import { PageHeader } from '#dashboard/components/pageHeader.component';
import { EsimsTable } from '#esims/components/esimsTable.component';
import { useAppContext } from '#hooks';
import { CustomStatsButton } from '#esims/components/customStatsButton.component';
import { isZim } from '../../services/helpers';

export const EsimsPage: FC = () => {

    const { loggedInUser } = useAppContext()

    return <>
        <PageHeader header={'Esims'} />
        <EsimsTable />
        { loggedInUser?.roles?.SUPERUSER && isZim && <CustomStatsButton /> }
    </>
}
