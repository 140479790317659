import { FC } from "react"
import { Message } from "#types";
import { formatDateWithHours } from "../../services/helpers";

interface LiveChatMessageProps{
    message: Message;
    index: number;
}

export const LiveChatMessageBubble:FC<LiveChatMessageProps> = ({ message, index }) => {

    const supportEmails: string[] = [process.env.REACT_APP_SUPPORT_EMAIL as string, process.env.REACT_APP_SUPPORT_SBB_EMAIL as string, process.env.REACT_APP_SUPPORT_GD_EMAIL as string]

    return <li  key={index}  className={supportEmails.includes(message.from) ? 'two-columns-expand-two' : 'two-columns-expand-one'}>
        {!supportEmails.includes(message.from) && <div style={{ width: "10%" }}/>}
        <div>
            <div className={supportEmails.includes(message.from) ?  "chat-bubble" : "reply-bubble ml-m"}>
                <p className="date-text selectable-text" style={{ whiteSpace: 'nowrap' }}>{message.createdAt && formatDateWithHours(message.createdAt)} - {message.from}</p>
                <p className='selectable-text'>{message.content}</p>
            </div>
            {
                message?.images && message.images.length > 0 && message?.images?.map(
                    (image, index) => (
                        <img key={index} className="report-screenshot ml-xl" src={`${image}`} alt={`Image ${index}`} />
                    )
                )
            }
        </div>
        {supportEmails.includes(message.from) && <div style={{ width: "10%" }}/>}
    </li>
}
