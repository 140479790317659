import { dashboardApi } from "#api";
import { useSafeState, useAppContext } from "#hooks";
import { Issue } from "#types";
import { ChangeEvent, FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PageHeader } from "#dashboard/components/pageHeader.component";

export const UserReportChat: FC = () => {

    const { setApiStatus } = useAppContext();
    const { email } = useParams();
    const [reports, setReports] = useSafeState<Issue[]>([])
    const [isLoading, setIsLoading] = useSafeState<boolean>(true);
    const [replyText, setReplyText] = useSafeState<string>('');
    const reportsScrollHeightRef = useRef<HTMLDivElement>(null);

    const handleSendReply = async () => {
        try {
            const response = await dashboardApi.replyToReport(email as string, replyText)
            setApiStatus({ code: response.status, message: response.data.message })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setReplyText('');
            fetchUserReports();
        }
    };
    
    const handleReplyTextChange = (event: ChangeEvent<HTMLTextAreaElement>)  => {
        setReplyText(event.target.value);
    };

    const fetchUserReports = async () => {
        try {
            const response = await dashboardApi.getReports({ email: email }, true)
            if (response.data.reports.length === reports.length) return
            setReports([ ...response.data.reports.reverse() ]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(
        () => {
            fetchUserReports();
        }, 
        []
    );

    useEffect(
        () => {
            const imagesHeight = reports.reduce(
                (total, report) => {
                    return total + (report.images.length > 0 ? report.images.length * 500 : 0);
                }, 
                0
            );
        
            reportsScrollHeightRef.current?.scrollTo(0, reportsScrollHeightRef.current.scrollHeight + imagesHeight);
        }, 
        [reports]
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <div className='app report-chat-page three-rows-expand-two'>
        <PageHeader header={`Report chat with ${email}`}/>
        <div className='main-dashboard-container chat-box pd-m mb-s' ref={reportsScrollHeightRef}>
            {
                reports.length > 0 && reports.map(
                    (report, i) => (
                        <li key={i}>
                            <div className={report.email === "support@zimconnections.com" ? "two-columns-expand-two" : "two-columns-expand-one"}>
                                {report.email === "support@zimconnections.com" && <div style={{ width: "10%" }}/>}
                                <div className={report.email === "support@zimconnections.com" ? "reply-bubble" : "chat-bubble"}>
                                    <p className="date-text">{new Date(report.createdAt).toLocaleDateString()} {new Date(report.createdAt).toLocaleTimeString()} {report.description && ` - ${report.description}`}</p>
                                    <p className="selectable-text">{report.message}</p>
                                </div>
                                {report.email !== "support@zimconnections.com" && <div style={{ width: "10%" }}/>}
                            </div>
                            {
                                report.images.map(
                                    (image, index) => (
                                        <img key={index} className="report-screenshot ml-xl" src={`${image}`} alt={`Image ${index}`} />
                                    )
                                )
                            }
                        </li>
                    )
                )
            }
        </div>
        <div className='main-dashboard-container pd-m mb-s two-columns-expand-one'>
            <textarea rows={6} className="textarea-chat-page" placeholder='Type your message here' value={replyText} onChange={handleReplyTextChange}></textarea>
            <div className="three-rows-expand-two ml-m">
                <button className="clear-button center-wrapper" onClick={fetchUserReports}>
                    <img className="refresh-arrow mt-s cursor-pointer" src="/images/refresh-arrow.png" alt="refresh-arrow" />
                </button>
                <div/>
                <button id="send-reply-button" className="bold" onClick={handleSendReply}>SEND</button>
            </div>
        </div>
    </div>
}
