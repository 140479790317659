import { Dispatch, FC, SetStateAction, useRef } from 'react';
import { NotificationRecipientButtons } from '#notifications/components/notificationRecipientButtons.component';
import { ConfirmationSendNotification } from '#notifications/components/confirmationSendNotification.component';
import { NotificationType, Notification, RecipientType, Locale, getRelevantLanguages, Database, getRelevantLanguageDisplay } from '#types';
import { useAppContext, useSafeState } from '#hooks';

interface ComposeNotificationProps {
    isConfirmationOpen: boolean;
    setIsConfirmationOpen: Dispatch<SetStateAction<boolean>>;
}

export const ComposeNotification: FC<ComposeNotificationProps> = ({ isConfirmationOpen, setIsConfirmationOpen }) => {

    const { currentDb } = useAppContext();
    const [notification, setNotification] = useSafeState<Notification>({
        title: '',
        body: '',
        type: NotificationType.Welcome,
        email: '',
        filter: {
            field: '',
            operator: '',
            value: ''
        },
        recipients: RecipientType.ALL
    } as Notification)
    const selectedDestinationName = useRef<string | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsConfirmationOpen(true);
    }

    return <>
        {
            !isConfirmationOpen && <div className='main-dashboard-container pd-m'>
                <NotificationRecipientButtons
                    notification={notification}
                    setNotification={setNotification}
                    selectedDestinationName={selectedDestinationName}
                />
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor='title'>Title: </label>
                        <input required
                            type='text' id='notification-title-input'
                            name='title'
                            className='pl-m'
                            value={notification.title}
                            onChange={e => setNotification(prevNotification => ({ ...prevNotification, title: e.target.value }))}
                        />
                    </div>
                    <div>
                        <label htmlFor='body'>Description: </label>
                        <textarea
                            required id="notification-text-area"
                            name="body" rows={6} cols={60}
                            value={notification.body}
                            className='pl-m'
                            onChange={e => setNotification(prevNotification => ({ ...prevNotification, body: e.target.value }))}
                        />
                    </div>
                    <div className='three-columns-expand-three'>
                        <label htmlFor='type'>Notification Type: </label>
                        <div className='three-rows-expand-one-three ml-m'>
                            <div />
                            <select
                                id='selectDropDown'
                                value={notification.type}
                                onChange={(event) => setNotification(prevNotification => ({ ...prevNotification, type: event.target.value as NotificationType }))}
                            >
                                {
                                    Object.values(NotificationType).map(
                                        (notificationType: string) => <option value={notificationType} key={notificationType}>
                                            {notificationType}
                                        </option>
                                    )
                                }
                            </select>
                            <div />
                        </div>
                        <div />
                    </div>
                    {
                        notification.recipients !== RecipientType.INDIVIDUAL &&
                            <div className='three-columns-expand-three'>
                                <label htmlFor='type'>Language: </label>
                                <div className='three-rows-expand-one-three ml-m'>
                                    <div />
                                    <select
                                        id='selectDropDownLang'
                                        value={notification.lang}
                                        onChange={(event) => setNotification(prevNotification => ({ ...prevNotification, lang: event.target.value as Locale }))}
                                    >
                                        {
                                           getRelevantLanguages(currentDb, true).map(
                                                (lang: Locale) => <option value={lang} key={lang}>
                                                    {getRelevantLanguageDisplay(lang)}
                                                </option>
                                            )
                                        }
                                    </select>
                                    <div />
                                </div>
                                <div />
                            </div>
                    }
                    <input id="send-notification-button" type="submit" value="SEND" />
                </form>
            </div>
        }
        {
            isConfirmationOpen && <ConfirmationSendNotification
                setIsConfirmationOpen={setIsConfirmationOpen}
                notification={notification}
                selectedDestinationName={selectedDestinationName}
            />
        }
    </>
}
