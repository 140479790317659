import { useAppContext, useSafeState } from '#hooks';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { PlansTable } from '#users/components/plansTable.component';
import { Destination, DestinationDetails, EsimProvider, Plan, User } from '#types';
import { dashboardApi } from '#api';

interface AssignEsimFormProps {
    user: User;
    setUser: Dispatch<SetStateAction<User>>
    setDisplayAssignEsimForm: Dispatch<SetStateAction<boolean>>
}

export const AssignEsimForm: FC<AssignEsimFormProps> = ({ user, setUser, setDisplayAssignEsimForm }) => {

    const { setApiStatus } = useAppContext();
    const [selectedPlan, setSelectedPlan] = useSafeState<Plan>({} as Plan)
    const [showConfirmation, setShowConfirmation] = useSafeState<boolean>(false)
    const [truphoneActivationDate, setTruphoneActivationDate] = useSafeState<Date | null>(null)
    const [isLoading, setIsLoading] = useSafeState<boolean>(false)
    const [destinations, setDestinations] = useSafeState<DestinationDetails[]>([]);
    const [selectedDestinationName, setSelectedDestinationName] = useSafeState<string>('');

    const handleActivationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = new Date(event.target.value);
        setTruphoneActivationDate(selectedDate);
    };

    useEffect(
        () => {
            const fetchDestinations = async () => {
                try {
                    const response = await dashboardApi.fetchDestinations();
                    setDestinations(response.data)
                    setSelectedDestinationName(response.data[0].name.en)
                } catch { }
            }
            fetchDestinations();
        },
        []
    );

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const response = await dashboardApi.assignEsim(user.email, selectedPlan.productCode, selectedDestinationName, truphoneActivationDate);
            setUser(response.data)
            setApiStatus({ code: response.status, message: `${selectedPlan.name} - ${selectedPlan.destinationName} has been successfully assigned to ${user.email}` })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
            setDisplayAssignEsimForm(false)
        }
    }

    const todaysDate = new Date();
    const maxDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth() + 1, todaysDate.getDate());
    const maxDateString = maxDate.toISOString().split('T')[0];

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <div className='main-dashboard-container pd-m mt-s'>
        <h3 className='mb-m'>Generate order for {user.email}</h3>
        {
            showConfirmation ? (
                <>
                    <p className='mb-m'>
                        Assign the plan <span className='highlight-accent bold'>{selectedPlan.name} - {selectedPlan.provider}</span> for the user with email: <span className='highlight-accent bold'>{user.email}</span>?
                        {
                            selectedPlan.provider === EsimProvider.TRUPHONE && <>
                                <label htmlFor="activateOn" className="mr-s vertical-align display-inline"> Activate on: </label>
                                <input
                                    type="date"
                                    id="activateOn"
                                    name="activateOn"
                                    className="pl-m"
                                    value={truphoneActivationDate ? truphoneActivationDate.toISOString().split('T')[0] : todaysDate.toISOString().split('T')[0]}
                                    onChange={handleActivationDateChange}
                                    min={todaysDate.toISOString().split('T')[0]}
                                    max={maxDateString}
                                />
                            </>
                        }
                    </p>
                    <div className='destinations-ddl-container'>
                        <p>Destination Name (Ex: Italy): </p>
                        <select
                            onChange={(event) => setSelectedDestinationName(event.target.value)}
                            className='destinations-ddl'
                            value={selectedDestinationName}
                        >
                            {
                                destinations?.map(
                                    (destination) => <option
                                        className='destinations-options'
                                        key={destination._id}
                                        value={destination.displayName.en}>
                                        {destination.displayName.en}
                                    </option>
                                )
                            }

                        </select>
                    </div>

                    <div className='two-columns-expand-one'>
                        <div />
                        <div>
                            <button onClick={() => setShowConfirmation(false)} id='esim-assign-confirmation-button' className='pd-s bold mr-s'>CANCEL</button>
                            <button onClick={handleSubmit} id='esim-assign-confirmation-button' className='pd-s bold mr-m'>SUBMIT</button>
                        </div>
                    </div>
                </>) : (<>
                    <PlansTable setSelectedPlan={setSelectedPlan} setShowConfirmation={setShowConfirmation} />
                    <div className='two-columns-expand-one'>
                        <div />
                        <button onClick={() => setDisplayAssignEsimForm(false)} id='esim-assign-confirmation-button' className='pd-s bold mr-m'>CANCEL</button>
                    </div>
                </>
            )
        }
    </div>
}
