import { FC } from 'react';
import { PromotionsInformation } from '#promotions/components/promotionsInformation.component';
import { CreatePromotion } from '#promotions/components/createPromotion.component';
import { PageHeader } from '#dashboard/components/pageHeader.component'
import { GuideToCreatePromotions } from '#promotions/components/guideToCreatePromotions.component';

export const Promotions: FC = () => {

    return <>
        <PageHeader header={'ZIM Promotions'}/>
        <PromotionsInformation />
        <CreatePromotion />
        <GuideToCreatePromotions />
    </>
}
