import { PromotionReqBody, NotificationReqBody, UsersFilter, ReportsFilter, PromotionsFilter, PlansFilter, FaqSubSectionContent, Plan, SupportOption, Locale, Platform, EsimsFilter, ConversationFilter, EsimSearchBarOptions, Promotion, RewardsFilter, RewardSearchBarOptions, Reward, RewardImageType } from "#types";
import { client, authClient } from "#api";

//TODO: send across params in an object

const login = (email: string, password: string) => {
    const route = '/auth/login/admin'
    return authClient.post(route, { email, password })
}

const getUserProfileAndBundles = (email: string) => {
    const route = `/staff/users/${email}`
    return client.get(route)
}

const getProfile = () => {
    const route = `/staff/user`
    return client.get(route)
}

const getUsers = (filter?: UsersFilter, pageNumber?: number, pageSize?: number) => {
    let route = `/staff/users/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filter) {
        const filterString = JSON.stringify(filter);
        const filterEncoded = encodeURIComponent(filterString);
        route += `&filter=${filterEncoded}`;
    }
    return client.get(route);
};

const getEsims = (pageNumber: number, pageSize: number, filter?: EsimsFilter) => {
    let route = `/services/esims/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filter) {
        if (filter.userEmail) {
            route += `&${EsimSearchBarOptions.USER_EMAIL}=${encodeURIComponent(filter.userEmail)}`;
        } else if (filter.iccid) {
            route += `&${EsimSearchBarOptions.ICCID}=${encodeURIComponent(filter.iccid)}`;
        } else if (filter.provider) {
            route += `&${EsimSearchBarOptions.PROVIDER}=${encodeURIComponent(filter.provider)}`;
        } else if (filter.status) {
            route += `&${EsimSearchBarOptions.STATUS}s=${encodeURIComponent(filter.status)}`;
        }
    }
    return client.get(route);
};

const getPromotions = (filter?: PromotionsFilter, pageNumber?: number, pageSize?: number) => {
    let route = `/staff/promotions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filter) {
        const filterString = JSON.stringify(filter);
        const filterEncoded = encodeURIComponent(filterString);
        route += `&filter=${filterEncoded}`;
    }
    return client.get(route);
}

const getPaymentInformationBySubscriptionId = (subscriptionId: string) => {
    const route = `/staff/payments/${subscriptionId}`
    return client.get(route)
}

const createPromotion = (promotion: PromotionReqBody) => {
    const route = '/staff/promotions/create'
    return client.post(route, promotion)
}

const sendNotification = (notification: NotificationReqBody) => {
    let route: string;
    if (notification.filter) {
        route = '/notifications/group/send';
    } else {
        route = notification.email ? '/notifications/individual/send' : '/notifications/broadcast/send';
    }
    return client.post(route, notification)
}

const getReports = (filter?: ReportsFilter, includeReplies?: boolean, pageNumber?: number, pageSize?: number) => {
    let route = `/staff/issues/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filter) {
        const filterString = JSON.stringify(filter);
        const filterEncoded = encodeURIComponent(filterString);
        route += `&filter=${filterEncoded}`;
    }
    if (includeReplies) {
        route += `&includeReplies=${includeReplies}`;
    }
    return client.get(route);
}

const replyToReport = (email: string, message: string) => {
    let route = '/staff/issues/reply';
    const body = { email, message }
    return client.post(route, body);;
}

const getDestinationsByName = (countryName: string) => {
    const route = `/services/products/destinations/find/${countryName}`;
    return client.get(route)
}

const getBlacklistedUsers = () => {
    const route = `/staff/users/blacklist/all`
    return client.get(route);
};

const getAllFaqs = (lang: string) => {
    const route = `/staff/faqs?lang=${lang}`
    return client.get(route);
};

const getAllSupportOptions = (language: string) => {
    const route = `/zim/support-options?lang=${language}`
    return client.get(route);
};

const getPlans = (pageNumber?: number, pageSize?: number, filter?: PlansFilter) => {
    let route = `/staff/plans?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filter) {
        const filterString = JSON.stringify(filter);
        const filterEncoded = encodeURIComponent(filterString);
        route += `&filter=${filterEncoded}`;
    }
    return client.get(route);
}

const updateFaq = (originalTitle: string, editedContent: FaqSubSectionContent[] | string, editedTitle: string,) => {
    const route = '/staff/faq/update';
    const body = originalTitle === editedTitle ? { originalTitle, editedContent } : { originalTitle, editedContent, editedTitle }
    return client.patch(route, body);
}

const updateSupportOption = (supportOption: SupportOption) => {
    const route = '/staff/supportOption/update';
    const body = supportOption;
    return client.post(route, body);
}

const assignEsim = (userEmail: string, productCode: string, planDestinationName: string, activationDate?: Date | null) => {
    const route = '/staff/assignEsim'
    const body = { userEmail, productCode, planDestinationName, ...(activationDate && { activationDate }) }
    return client.post(route, body);
}

const getTranslations = () => {
    const route = '/locale';
    return client.get(route);
}

const updateTranslation = (_id: string, translations: Record<string, string>) => {
    const route = `/staff/locale/update/${_id}`;
    return client.patch(route, { translations });
}

const refreshEsimgoEsim = (iccid: string) => {
    const route = `staff/esim/${iccid}/refresh`;
    return client.get(route);
}

const getEsimTutorials = (lang: Locale, os: Platform) => {
    const route = `/staff/tutorials/esim?lang=${lang}&os=${os}`;
    return client.get(route);
}

const saveEsimTutorial = (tutorialId: string, elementId: string, title: string, description: string) => {
    const route = '/staff/tutorials/esim';
    const body = { tutorialId, title, elementId, description };
    return client.patch(route, body);
}

const saveEsimTutorialTitle = (tutorialId: string, sectionTitle: string) => {
    const route = '/staff/tutorials/esim';
    const body = { tutorialId, sectionTitle };
    return client.patch(route, body);
}

const fetchUserNotes = (userId: string, subscriptionId?: string) => {
    const route = subscriptionId ? `/staff/notes/subscription/${subscriptionId}` : `/staff/notes/user/${userId}`;
    return client.get(route);
}

const createUserNote = (userId: string, content: string, author: string, subscriptionId?: string) => {
    const route = '/staff/notes';
    const body = subscriptionId ? { userId, content, author, subscriptionId } : { userId, content, author };
    return client.post(route, body);
}

const editUserNote = (userNoteId: string, content: string, author: string) => {
    const route = `/staff/notes/${userNoteId}`;
    const body = { userNoteId, content, author };
    return client.patch(route, body);
}

const deleteUserNote = (noteId: string) => {
    const route = `/staff/notes/${noteId}`;
    return client.delete(route);
}

const getConversationAndLiveChatMessages = (conversationId: string) => {
    const route = `/staff/conversations/${conversationId}`
    return client.get(route)
}

const getClosedConversations = (filter?: ConversationFilter, pageNumber?: number, pageSize?: number) => {
    let route = `/staff/conversations/closed/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filter) {
        const filterString = JSON.stringify(filter);
        const filterEncoded = encodeURIComponent(filterString);
        route += `&filter=${filterEncoded}`;
    }
    return client.get(route);
};

const getConversationsWithLiveMessages = (to: string, from: string) => {
    const route = `/staff/conversationReport/all?to=${to}&from=${from}`
    return client.get(route);
}

const updatePromotion = (promotionId: string, updatedPromotionProperties: Partial<Promotion>) => {
    const route = '/staff/promotions/update';
    const body = { promotionId, updatedPromotionProperties }
    return client.patch(route, body);
}

const getRewards = (pageNumber: number, pageSize: number, lang: Locale, filter?: RewardsFilter) => {
    let route = `/staff/rewards/all?pageNumber=${pageNumber}&pageSize=${pageSize}&lang=${lang}`;
    if (filter) {
        if (filter.title) {
            route += `&${RewardSearchBarOptions.TITLE}=${encodeURIComponent(filter.title)}`;
        } else if (filter.companyName) {
            route += `&${RewardSearchBarOptions.COMPANY_NAME}=${encodeURIComponent(filter.companyName)}`;
        } else if (filter.rewardCode) {
            route += `&${RewardSearchBarOptions.REWARD_CODE}=${encodeURIComponent(filter.rewardCode)}`;
        }
    }
    return client.get(route);
};

const updateReward = (rewardId: string, updatedRewardProperties: Partial<Reward>) => {
    const route = '/staff/rewards';
    const body = { rewardId, updatedRewardProperties }
    return client.patch(route, body);
}

const createReward = (reward: Reward) => {
    const route = '/staff/rewards'
    return client.post(route, reward)
}

const fetchDestinations = () => {
    const route = '/staff/destinations'
    return client.get(route);
}

const getBundleSubscriptions = (iccid: string, userEmail: string, includeUsed: boolean) => {
    const route = `/staff/subscriptions?iccid=${iccid}&userEmail=${userEmail}&includeUsed=${includeUsed}`
    return client.get(route);
}

const getCustomStats = () => {
    const route = `/staff/user-stats`
    return client.get(route);
}

export const dashboardApi = {
    login,
    getUserProfileAndBundles,
    getUsers,
    getPromotions,
    getPaymentInformationBySubscriptionId,
    createPromotion,
    sendNotification,
    getReports,
    getDestinationsByName,
    getBlacklistedUsers,
    replyToReport,
    getProfile,
    getAllFaqs,
    getAllSupportOptions,
    getPlans,
    updateFaq,
    updateSupportOption,
    assignEsim,
    getTranslations,
    updateTranslation,
    refreshEsimgoEsim,
    getEsimTutorials,
    saveEsimTutorial,
    saveEsimTutorialTitle,
    fetchUserNotes,
    createUserNote,
    editUserNote,
    deleteUserNote,
    getEsims,
    getConversationAndLiveChatMessages,
    getClosedConversations,
    getConversationsWithLiveMessages,
    updatePromotion,
    getRewards,
    updateReward,
    createReward,
    fetchDestinations,
    getBundleSubscriptions,
    getCustomStats,
}