import { useLiveChat, useSafeState } from '#hooks';
import { ChatRoom, LiveChatEvent } from '#types';
import { FC, useEffect, useRef } from 'react';
import { LiveChatMessageBubble } from './liveChatMessageBubble.component';

export const LiveChatDashboard: FC = () => {

    const { liveChatState, openChat, handleSendMessage, handleTypeMessage, handleLoadMoreMessages, handleAskForResolution, handleAttachMedia, removeMediaFromMessage, reconnectingToSocketIoStatus, closeCurrentChat } = useLiveChat();
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const shouldAdjustContainerHieght = useRef<boolean>(true);
    const [sortedChatRooms, setSortedChatRooms] = useSafeState<{ 'Need to reply': ChatRoom[], 'Waiting for reply': ChatRoom[] }>({'Need to reply': [], 'Waiting for reply': []});
    
    useEffect(
        () => {
            if(!shouldAdjustContainerHieght.current) {
                shouldAdjustContainerHieght.current = true
                return
            }
            const messagesContainer = messagesContainerRef.current;
            if (messagesContainer) {
                messagesContainer.scrollTop = messagesContainer.scrollHeight;
            }
        }, 
        [liveChatState.selectedChatRoomMessages]
    );

    useEffect(
        () => {
            setSortedChatRooms({
                'Waiting for reply': liveChatState?.connectedChatRooms?.filter(room => !room.lastMessageFromCustomer),
                'Need to reply': liveChatState?.connectedChatRooms?.filter(room => room.lastMessageFromCustomer),
            });
        }, 
        [liveChatState.connectedChatRooms]
    );

    return <div className='main-dashboard-container mb-s pd-m two-rows-expand-one livechat-container'>
        <h3 className='vertical-align mb-m'>
            Live Chat Dashboard 
            {reconnectingToSocketIoStatus === LiveChatEvent.DISCONNECT && ' - LOST CONNECTION TO BACKEND, PLEASE CHECK YOUR INTERNET CONNECTION & REFRESH THE PAGE 🚨'}
            {reconnectingToSocketIoStatus === LiveChatEvent.ATTEMPTING_RECONNECT && ' - RECONNECTING TO BACKEND ♻️'}    
        </h3>
            <div className='four-columns-expand-two' style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                <select
                    value={liveChatState.selectedConversationId || ''}
                    onChange={(e) => {
                        const selectedConversationId = e.target.value;
                        if(selectedConversationId === '') {
                            openChat(null)
                            return
                        }
                        const selectedChatRoom = liveChatState?.connectedChatRooms?.find((room) => room.conversationId === selectedConversationId);
                        openChat(selectedChatRoom as ChatRoom);
                    }}
                    className='mb-m'
                >
                    <option value=''>Select a customer</option>
                    {
                        Object.entries(sortedChatRooms).map(
                            ([label, chatRooms]) => (
                                <optgroup label={label} key={label}>
                                    {
                                        chatRooms.map(
                                            (chatRoom) => (
                                                <option key={chatRoom.conversationId} value={chatRoom.conversationId}>
                                                    {chatRoom.email || 'User connected - waiting email'}
                                                </option>
                                            )
                                        )
                                    }
                                </optgroup>
                            )
                        )
                    }
                </select>
                <div/>
                { liveChatState.selectedChatRoomEmail &&  <button className='searchBy-btn bold white-text mr-m' onClick={() => closeCurrentChat()}>CLOSE CHAT</button> }
                { liveChatState.selectedChatRoomEmail &&  <button className='searchBy-btn bold white-text' onClick={() => window.open(`http://www.dashboard.zimconnections.com/users/${liveChatState.selectedChatRoomEmail}`, '_blank')}>USER PROFILE</button> }
            </div>
            {/* TODO: fetch most recent plan for user */}
            <div className='ml-m' style={{ maxHeight: '60vh', overflowY: 'auto' }} ref={messagesContainerRef}>
            {
                liveChatState.selectedConversationId ? <>
                    {
                        !liveChatState.selectedChatRoomLoadedAllMessages && (
                            <div className='three-rows-expand-one-three'>
                                <div/>
                                <button className="load-more-button" onClick={() => {shouldAdjustContainerHieght.current = false; handleLoadMoreMessages()}}>LOAD MORE</button>
                                <div/>
                            </div>
                        )
                    }
                    <ul className='mb-m'>
                        {
                            liveChatState.selectedChatRoomMessages?.map(
                                (message, index) => (
                                    <LiveChatMessageBubble message={message} index={index} key={index.toString()}/>
                                )
                            )
                        }
                    </ul>
                    {liveChatState.supportAgentTyping ? <p className='is-typing-text'>Another support agent is typing...</p> : <p className='is-typing-text'> </p>}
                    {liveChatState.customerTyping ? <p className='is-typing-text'>Customer is typing...</p> : <p className='is-typing-text'> </p>}
                    {
                        liveChatState.images.length > 0 && liveChatState.images.map(
                            (image, index) => (
                                <div key={index} className='three-columns-expand-two mb-s'>
                                    <p className='is-typing-text'>{image.name}</p>
                                    <div/>
                                    <button onClick={() => removeMediaFromMessage(image)} className='clear-button pr-xl'>X</button>
                                </div>
                            )
                        )
                    }
                    <div className='message-input two-columns-expand-one'>
                        <textarea
                            placeholder="Type your message..."
                            value={liveChatState.message}
                            className='pd-s'
                            rows={6}
                            onChange={handleTypeMessage}
                        />                           
                        <div className="four-rows-expand-two ml-m">
                            <button id="send-media-button" className="clear-button mt-m" style={{ fontSize: 16 }} onClick={() => handleAttachMedia()}>📎 {liveChatState.images.length}</button>
                            <div/>
                            {
                                liveChatState.selectedConversationId && !liveChatState.connectedChatRooms.find(chatRoom => chatRoom.conversationId === liveChatState.selectedConversationId)?.agentAskedForResolution && 
                                    <button className='clear-button question-mark mb-s' onClick={(event) => handleAskForResolution(liveChatState?.selectedConversationId as string, event)}>❓</button>
                            }
                            <button id="send-reply-button" className="bold" disabled={reconnectingToSocketIoStatus !== LiveChatEvent.CONNECT} onClick={() => handleSendMessage()}>SEND</button>
                        </div>
                    </div>
                </> : 
                <p>
                    { 
                        liveChatState.connectedChatRooms.length === 0 ? 
                            'There are no customers online' : `Please select a customer - number online: ${liveChatState.connectedChatRooms.length}, 
                            waiting for a reply: ${liveChatState?.connectedChatRooms?.filter(room => room.lastMessageFromCustomer).length || 0}` 
                    } 
                </p>
            }
        </div>
    </div>
}