import { dashboardApi } from '#api';
import { FC, useEffect, useRef } from 'react';
import { Esim, EsimsFilter } from '#types';
import { EsimSearchBar } from '#esims/components/esimSearchBar.component'
import { Pagination } from '#dashboard/components/pagination.component';
import { esimTableHeaders } from '#globals';
import { useAppContext, useSafeState } from '#hooks';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';

export const EsimsTable: FC = () => {

    const { setApiStatus, currentEnvironment } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [esims, setEsims] = useSafeState<Esim[]>([]);
    const [pageSize, setPageSize] = useSafeState<number>(10);
    const [currentPage, setCurrentPage] = useSafeState<number>(1);
    const [filter, setFilter] = useSafeState<EsimsFilter>({});
    const totalPagesRef = useRef<number>(1);

    const fetchEsims = async () => {
        try {
            const response = await dashboardApi.getEsims(currentPage, pageSize, filter)
            totalPagesRef.current = Math.ceil(response.data.total / pageSize)
            setEsims([...response.data.esims]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(
        () => {
            fetchEsims();
        },
        [filter, currentPage, pageSize, currentEnvironment]
    );


    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <>
        <div className='pd-m main-dashboard-container mt-s'>
            <div className='three-columns-expand-two'>
                <EsimSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
                <div />
                <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Esims' />
            </div>
            <table className='center-wrapper table'>
                <thead className="table-header">
                    <tr>
                        {
                            esimTableHeaders.map(
                                (header: string) => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        esims.map(
                            (esim: Esim, index: number) => (
                                <tr key={`${esim.iccid}_${String(index)}`} className={'table-row'} >
                                    <td className="table-cell selectable-text">{esim.iccid}</td>
                                    <td className="table-cell selectable-text">{esim.provider}</td>
                                    <td className="table-cell selectable-text">{esim.userEmail}</td>
                                    <td className="table-cell selectable-text">{esim.msisdn || 'N/A'}</td>
                                    <td className="table-cell selectable-text">{esim.status}</td>
                                </tr>
                            )
                        )
                    }
                </tbody>
            </table>
            <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    </>
}
