import React, { FC } from 'react';
import { useAppContext } from '#hooks';
import { HttpStatusCode } from 'axios';

export const ApiStatus: FC = React.memo(() => {

    const { apiStatus } = useAppContext();

    const className = apiStatus.code === HttpStatusCode.Ok || apiStatus.code === HttpStatusCode.Created
        ? 'status-message-success'
        : 'status-message-failure';

    return apiStatus?.message ? 
        <p className={`${className} main-dashboard-container pd-m bold selectable-text`}>{HttpStatusCode[apiStatus?.code as number].toUpperCase()} - {apiStatus?.message}</p> 
        : null
})
