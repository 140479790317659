import { EsimGuideType, EsimTutorial, EsimTutorialContent, EsimType } from "#types";
import { FC, useCallback } from "react";
import '../styles/tutorial.style.css';
import { useAppContext, useSafeState } from "#hooks";
import { dashboardApi } from "#api";
import { ConfirmationModal } from "../../components/confirmation.modal";
import { Button } from "@mui/material";
import { HttpStatusCode } from "axios";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

interface Props {
    tutorial: EsimTutorial;
    selectedTutorial: EsimTutorial | null;
    setSelectedTutorial: (tutorial: EsimTutorial | null) => void;
}

const renderIcon = (type: EsimGuideType, esimType: EsimType) => {
    const getEsimTypeText = (esimType: string): string => {
        switch (esimType) {
            case EsimType.DATA:
                return 'Data only';

            case EsimType.VOICE_AND_DATE:
                return 'Voice & Data';

            case EsimType.VOICE:
                return 'Voice only';

            default:
                return 'Data only';
        }
    }

    switch (type) {
        case EsimGuideType.MANUAL_INSTALLATION:
            return <div className="row">
                <img className="icon" src="images/manual.png" />
                <p className="mr-s">{getEsimTypeText(esimType)} -</p>
                <p>Manual</p>
            </div>

        case EsimGuideType.QRCODE_INSTALLATION:
            return <div className="row">
                <img className="icon" src="images/qr-code.png" />
                <p className="mr-s">{getEsimTypeText(esimType)} -</p>
                <p>QR</p>
            </div>

        case EsimGuideType.DELETION:
            return <div className="row">
                <img className="icon" src="images/trash.png" />
                <p className="mr-s">{getEsimTypeText(esimType)} -</p>
                <p>Delete</p>
            </div>

    }

};

interface TutorialContentProps {
    content: EsimTutorialContent;
    onItemSave: (item: EsimTutorialContent) => void;
}

export const TutorialContent: FC<TutorialContentProps> = ({ content, onItemSave }) => {

    const [isEditing, setIsEditing] = useSafeState<boolean>(false);
    const [title, setTitle] = useSafeState<string>(content.title);
    const [description, setDescription] = useSafeState<string>(content.description);
    const [showModal, setShowModal] = useSafeState<boolean>(false);


    const handleButtonClick = () => {
        if (isEditing) {
            setShowModal(true);
        }
    };

    const handleConfirm = () => {
        onItemSave(
            {
                ...content,
                title,
                description
            }
        )
        setIsEditing(false);
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription((e.target.value));
    };

    return <div className="tutorial-item-container">
        <img className="tutorial-image" src={content.imageUri} />
        {
            isEditing ?
                <>
                    <input type="text" className="tutorial-title-input" defaultValue={title} onBlur={handleTitleChange} />
                    <textarea defaultValue={description} onBlur={handleDescriptionChange} />
                </> :
                <>
                    <h3 className="tutorial-title">{title}</h3>
                    <p className="tutorial-description" >{description}</p>
                </>
        }
        {
            isEditing ?
                <div className="row mt-l">
                    <Button sx={{ marginRight: 1 }} onClick={handleButtonClick} variant='contained'>Save</Button>
                    <Button onClick={() => setIsEditing(false)} variant='outlined'>Cancel</Button>
                </div> :
                <Button sx={{ marginTop: 1 }} onClick={() => setIsEditing(true)} variant='contained'>Edit</Button>
        }
        <ConfirmationModal
            isVisible={showModal}
            onClose={handleCancel}
            onConfirm={handleConfirm}
        />
    </div>

}

export const TutorialCard: FC<Props> = ({ tutorial, selectedTutorial, setSelectedTutorial }) => {

    const { setApiStatus } = useAppContext();

    const [sectionTitle, setSectionTitle] = useSafeState<string>(tutorial.sectionTitle);
    const [isSectionTitleEditing, setIsSectionTitleEditing] = useSafeState<boolean>(false);

    const isSelected = tutorial === selectedTutorial;
    const icon = isSelected ? '/images/contract.png' : '/images/expand.png';

    const handleSaveItem = useCallback(
        async (item: EsimTutorialContent) => {
            try {
                await dashboardApi.saveEsimTutorial(tutorial._id, item._id, item.title, item.description);
                alert('Content updated successfully');
            } catch (error: any) {
                setApiStatus({ code: HttpStatusCode.NotAcceptable, message: error?.message });
            }
        },
        []
    );

    const handleEditSectionTitle = useCallback(
        async () => {
            try {
                console.log('enter func');

                await dashboardApi.saveEsimTutorialTitle(tutorial._id, sectionTitle);
                alert('Title updated successfully');
                setIsSectionTitleEditing(false);
            } catch (error: any) {
                setApiStatus({ code: HttpStatusCode.NotAcceptable, message: error?.message });
            }
        },
        [sectionTitle]
    );

    return <div
        className='card-container'>
        <div className='card row'>
            <div className="row">
                <h4>{renderIcon(tutorial.type, tutorial.esimType)}</h4>
            </div>
            <img
                className='expand-contract-esim vertical-align ml-m'
                src={icon}
                alt="expand"
                onClick={
                    () => {
                        if (isSelected) {
                            setSelectedTutorial(null)
                        } else {
                            setSelectedTutorial(tutorial)
                        }
                    }
                }
            />
        </div>
        {
            isSelected && <>
                <div style={{ justifyContent: 'center' }} className="row mb-xl">

                    {
                        isSectionTitleEditing ?
                            <>
                                <input
                                    type="text"
                                    value={sectionTitle}
                                    className="tutorial-tutorial-title-input"
                                    onChange={(e) => setSectionTitle(e.target.value)}
                                />
                                <SaveIcon color="primary" onClick={handleEditSectionTitle} />
                            </>
                            :
                            <>
                                <h2 className="mr-m">{sectionTitle}</h2>
                                <EditIcon
                                    onClick={() => setIsSectionTitleEditing(true)}
                                    color="info"
                                    fontSize="large"
                                />
                            </>

                    }
                </div>
                <div className="tutorial-section row">
                    {
                        tutorial.data.map(
                            (item, index) => <TutorialContent onItemSave={handleSaveItem} key={String(index)} content={item} />
                        )
                    }
                </div>
            </>

        }

    </div>;
};
