import { Dispatch, FC, SetStateAction } from 'react';
import { rewardsTableHeaders } from '#globals';
import { Locale, Reward, getRelevantLanguageDisplay } from '#types';
import { useSafeState } from '#hooks';
import { formatDateAsDDMMYYYY } from '../../services/helpers';

interface RewardSearchBarProps{
    rewards: Reward[];
    setRewardToEdit: Dispatch<SetStateAction<Reward | null>>
    lang: Locale;
    setLang: Dispatch<SetStateAction<Locale>>
    availableLanguages: Locale[]
}

export const RewardsTable: FC<RewardSearchBarProps> = ({ rewards, setRewardToEdit, lang, setLang, availableLanguages }) => {

    const [hoveredRowId, setHoveredRowId] = useSafeState<number | null>(null);
    const handleLangChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedLang = event.target.value as Locale;
        setLang(selectedLang);
    };

    return <>
        <div className='three-columns-expand-three'>
            <label htmlFor="langDropdown" className='mr-m'>Select Language: </label>
            <select id="langDropdown" value={lang} onChange={handleLangChange}>
                { availableLanguages.map((value) => (<option key={value} value={value}>{getRelevantLanguageDisplay(value)}</option>)) }
            </select>
            <div/>
        </div>
        <table className='center-wrapper table pd-m mt-s'>
            <thead className="table-header">
                <tr>
                    {
                        rewardsTableHeaders.map(
                            header => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rewards.map((reward, index) => 
                        <tr 
                            key={index}
                            className={`table-row ${hoveredRowId === index ? 'hovered' : ''}`}                                
                            onMouseEnter={() => {setHoveredRowId(index)}}
                            onClick={() => setRewardToEdit(reward)}
                            onMouseLeave={() => {setHoveredRowId(null)}}
                        >
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.companyName}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.title}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.subtitle}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.description}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.rewardCode}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.active ? '✅' : '❌'}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                {reward.targetFlagCodes ? reward.targetFlagCodes.join(', ') : ''}
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{reward.priority}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                            <p className='selectable-text'>{formatDateAsDDMMYYYY(new Date(reward.validFrom))}</p>
                            </td>
                            <td className="table-cell a-tag-reset">
                                <p className='selectable-text'>{formatDateAsDDMMYYYY(new Date(reward.validTo))}</p>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    </>
}
