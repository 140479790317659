import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect } from 'react';
import { UserNote, User } from '#types';
import { useAppContext, useSafeState } from '#hooks';
import { HttpStatusCode } from 'axios';
import { dashboardApi } from '#api';
import { formatDateWithHours } from '../../services/helpers';

interface userNoteFormData {
    author: string;
    content: string;
}

interface UserSubscriptionNotesScreenProps {
    user: User;
    subscriptionId?: string;
    setSelectedSubscriptionForNotes: Dispatch<SetStateAction<string | null>>;
    subscriptionsWithNotesRef?: MutableRefObject<string[]>;
}

export const UserSubscriptionNotesScreen: FC<UserSubscriptionNotesScreenProps> = ({ user, subscriptionId, setSelectedSubscriptionForNotes, subscriptionsWithNotesRef }) => {

    const { setApiStatus } = useAppContext();
    const [userNotes, setUserNotes] = useSafeState<UserNote[]>([] as UserNote[])
    const [userNoteToEdit, setUserNoteToEdit] = useSafeState<UserNote | null>(null)
    const [formData, setFormData] = useSafeState<userNoteFormData>({} as userNoteFormData)

    const fetchNotes = async () => {
        try {
            const response = await dashboardApi.fetchUserNotes(user._id.toString(), subscriptionId);
            setUserNotes(response.data)
            if (response?.data?.length > 0 && subscriptionsWithNotesRef) {
                const subscriptionIds: string[] = response.data
                    .map((item: UserNote) => item.subscriptionId)
                    .filter((subscriptionId: string) => subscriptionId !== undefined);
                subscriptionsWithNotesRef.current = subscriptionIds;
            }
        } catch (error: any) {
            if(error.response?.data?.statusCode === HttpStatusCode.NotFound) {
                setUserNotes([])
                return
            }
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    const createUserNote = async () => {
        if(formData.content === '' || formData.author === '') {
            setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Ensure that the note has a message and a author' })
            return
        }
        try {
            const response = await dashboardApi.createUserNote(user._id.toString(), formData.content, formData.author, subscriptionId);
            setUserNotes([...userNotes, response.data])
            setFormData({ author: '', content: '' })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    const editUserNote = async () => {
        if(!userNoteToEdit || !userNoteToEdit._id || userNoteToEdit.content === '' || userNoteToEdit.author === '') {
            setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Ensure that the note has a message and a author' })
            return
        }
        try {
            const response = await dashboardApi.editUserNote(userNoteToEdit._id.toString(), userNoteToEdit.content, userNoteToEdit.author);
            setUserNotes(userNotes.map((userNote) => userNote._id === response.data._id.toString() ? response.data : userNote))
            setFormData({ author: '', content: '' })
            setUserNoteToEdit({ author: '', content: '' } as UserNote)
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    const handleTypeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setFormData((prevState: any) => ({...prevState, content: value}))
    }

    const handleChangeAuthor = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setFormData((prevState: any) => ({...prevState, author: value}))
    }

    const handleTypeContentEditing = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setUserNoteToEdit((prevState: any) => ({...prevState, content: value}))
    }

    const handleChangeAuthorEditing = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setUserNoteToEdit((prevState: any) => ({...prevState, author: value}))
    }

    const deleteUserNote = async (noteId: string) => {
        try {
            const response = await dashboardApi.deleteUserNote(noteId);
            setUserNotes(userNotes.filter((note) => note._id !== response.data))
            if(response.data) {
                setUserNoteToEdit(null)
            }
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    useEffect(
        () => {
            fetchNotes()
        },
        [subscriptionId],
    );

    return <div className='main-dashboard-container pd-m mt-s  user-notes-container overflow-scroll chat-box'>
        <div className='three-columns-expand-two mb-m'>
            <h3>Notes for {subscriptionId ? `subscription with ID: ${subscriptionId}` : `user with email: ${user.email}`}</h3>
            <div/>
            {subscriptionId && <button className="pagination-button pd-s bold" onClick={() => setSelectedSubscriptionForNotes(null)}>RETURN</button>}
        </div>
        <div className='mb-m'>
            {
                userNotes && userNotes.length > 0 ? (
                    <div>
                        {
                            userNotes.map(
                                (note, index) => (
                                    <div key={index} className='three-columns-expand-one'>
                                        <div>
                                            <div className='mb-s two-columns-expand-two'>
                                                <div className='bold text-selectable' style={{ width: '250px'}}>
                                                    <p>{note.author}</p>
                                                    <p>{formatDateWithHours(note.createdAt)}</p>
                                                    {note.subscriptionId && !subscriptionId && <p>{note.subscriptionId}</p>}
                                                </div>
                                                <p className='ml-m'>{note.content}</p>
                                            </div>
                                        </div>
                                        <button 
                                            className="pagination-button pd-s bold vertical-align mr-s"
                                            style={{ height: '28px'}} 
                                            onClick={() => setUserNoteToEdit(note)}
                                        >
                                            EDIT
                                        </button>
                                        <button 
                                            className="pagination-button pd-s bold vertical-align"
                                            style={{ height: '28px'}} 
                                            onClick={() => deleteUserNote(note._id.toString())}
                                        >
                                            DELETE
                                        </button>
                                    </div>
                                )
                            )
                        }
                    </div>
                ) : (
                    <p>No notes</p>
                )
            }
        </div>
        {
            userNoteToEdit && userNoteToEdit._id ? <div className='message-input two-columns-expand-one'>
                <div style={{ display: 'grid', width: '100%' }}>
                        <textarea
                            placeholder="Type your name..."
                            value={userNoteToEdit.author}
                            className='pd-s mb-s mr-m'
                            rows={1}
                            onChange={handleChangeAuthorEditing}
                        />                           
                        <textarea
                                placeholder="Type your message..."
                                value={userNoteToEdit.content}
                                className='pd-s mr-m'
                                rows={6}
                                onChange={handleTypeContentEditing}
                        />     
                    </div>
                    <div className='three-rows-expand-one'>
                        <div/>
                        <button className="pagination-button pd-s bold mb-s" onClick={() => setUserNoteToEdit(null)}>CANCEL</button>
                        <button className="pagination-button pd-s bold" onClick={() => editUserNote()}>EDIT</button>
                    </div>
                </div> : <div className='message-input two-columns-expand-one'>
                <div style={{ display: 'grid', width: '100%' }}>
                    <textarea
                        placeholder="Type your name..."
                        value={formData.author}
                        className='pd-s mb-s mr-m'
                        rows={1}
                        onChange={handleChangeAuthor}
                    />                           
                    <textarea
                        placeholder="Type your message..."
                        value={formData.content}
                        className='pd-s mr-m'
                        rows={6}
                        onChange={handleTypeContent}
                    />     
                </div>
                <div className='two-rows-expand-one'>
                    <div/>
                    <button className="pagination-button pd-s bold" onClick={() => createUserNote()}>CREATE</button>
                </div>
            </div>
        }
    </div>
}
