import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppContext } from '#hooks';
import { CommonAnswers } from '#dashboard/pages/commonAnswers';
import { CurrentUsers } from '#users/pages/currentUsers.page';
import { BlacklistPage } from '#blacklist/pages/blacklist.page';
import { Reports } from '#reports/pages/reports.page';
import { NotFoundPage } from '#dashboard/pages/notFound.page';
import { DashboardPage } from '#dashboard/pages/dashboardPage';
import { Notifications } from '#notifications/pages/notifications.page';
import { Promotions } from '#promotions/pages/promotions.page';
import { TranslationsPage } from '../../translation/pages/translation.page';
import { LiveChat } from '#livechat/pages/liveChat.page';
import { EsimTutorialsPage } from '#tutorials/pages/esimTutorials.page';
import { EsimsPage } from '#esims/pages/esims.page';
import { RewardsPage } from '#rewards/pages/rewards.page';
import { Database } from '#types';

interface DashboardRoutesInterface {
    path: string;
    component: FC;
}

export const MainDashboardContainer: FC = () => {
    const { loggedInUser, currentDb } = useAppContext();

    let dashboardRoutes: DashboardRoutesInterface[] = [];

    if (loggedInUser?.roles?.SUPERUSER) {
        dashboardRoutes = [
            {
                path: '/',
                component: DashboardPage,
            },
            {
                path: '/notifications',
                component: Notifications,
            },
            {
                path: '/locale',
                component: TranslationsPage,
            },
            {
                path: '/esims',
                component: EsimsPage,
            },
        ];

        if (currentDb !== Database.GD) {
            dashboardRoutes.push(
                {
                    path: '/promos',
                    component: Promotions,
                },
                {
                    path: '/rewards',
                    component: RewardsPage,
                }
            );
        }
    }

    const routes = [
        {
            path: '/users',
            component: CurrentUsers,
        },
        {
            path: '/reports',
            component: Reports,
        },
        {
            path: '/faq',
            component: CommonAnswers,
        },
        {
            path: '/livechat',
            component: LiveChat,
        },
        {
            path: '/tutorials',
            component: EsimTutorialsPage,
        },
        {
            path: '/blacklist',
            component: BlacklistPage,
        },
        ...dashboardRoutes,
        {
            path: '*',
            component: NotFoundPage,
        },
    ];

    if (!loggedInUser?.roles) {
        return <div className="lds-hourglass" />;
    }

    return (
        <div className="ml-m mr-m">
            <Routes>
                {
                    routes.map(
                        (route) => (
                            <Route key={route.path} path={route.path} element={<route.component />} />
                        )
                    )
                }
            </Routes>
        </div>
    );
};
