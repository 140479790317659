import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Conversation, Message } from '#types';
import { dashboardApi } from '#api';
import { useAppContext, useSafeState } from '#hooks';
import { ApiStatus } from '#dashboard/components/apiStatus.component';
import { LiveChatMessageBubble } from '#livechat/components/liveChatMessageBubble.component';

interface ConversationAndLiveChatMessages {
    conversation: Conversation,
    liveChatMessages: Message[]
}

export const ArchivedChat: FC = () => {

    const { apiStatus } = useAppContext();
    const { conversationId } = useParams();
    const [errorMessage, setErrorMessage] = useSafeState<string>('')
    const [conversationWithMessages, setConversationWithMessages] = useSafeState<ConversationAndLiveChatMessages>({} as ConversationAndLiveChatMessages)
    const [isLoading, setIsLoading] = useSafeState<boolean>(true);

    const fetchConversationAndLiveMessages = async () => {
        try {
            const response = await dashboardApi.getConversationAndLiveChatMessages(conversationId as string)
            setConversationWithMessages({ conversation: response.data.conversation, liveChatMessages: response.data.liveChatMessages })
        } catch {
            setErrorMessage('Conversation/livechat messages could not be found, please sign out, sign in and try again. If problem persists, please contact Alex or Ahmad')
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(
        () => {
            fetchConversationAndLiveMessages()
        },
        [],
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    if (!conversationWithMessages.conversation?._id) {
        return <div className='app user-to-display-page'>
            <div className='main-dashboard-container mb-s pd-m'>
                <p className='status-message-failure bold'>{errorMessage}</p>
            </div>
        </div>
    }

    return <div className='app user-to-display-page'>
        <div style={{ alignItems: 'center', padding: 10 }} className='main-dashboard-container pb-m mb-s two-columns-expand-two'>
            <h1 className='selectable-text'>{conversationWithMessages?.conversation?.userEmail} - {conversationWithMessages?.conversation?.selectedTitle} - {conversationWithMessages?.conversation?.selectedProblem}</h1>
            <div/>
        </div>
        { apiStatus && <ApiStatus /> }
        <div className='main-dashboard-container pb-m mb-s'>
            {
                conversationWithMessages?.liveChatMessages && conversationWithMessages?.liveChatMessages.length > 0 && conversationWithMessages?.liveChatMessages.map(
                    (message, index) => (
                        <LiveChatMessageBubble message={message} index={index} key={index.toString()}/>
                    )
                )
            }
        </div>
    </div>
}
