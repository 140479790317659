import { FC } from 'react';
import { UserTable } from '../components/userTable.component';
import { PageHeader } from '#dashboard/components/pageHeader.component';
import { DownloadUsersButtons } from '#users/components/downloadUsersButtons.component';
import { useAppContext } from '#hooks';

export const CurrentUsers: FC = () => {

    const { loggedInUser } = useAppContext();

    return <>
        <PageHeader header={'Current Users'} />
        <UserTable />
        {
            loggedInUser?.roles?.SUPERUSER && <DownloadUsersButtons />
        }
    </>
}
