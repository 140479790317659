import { FC } from 'react';
import { ImportantDates } from '#dashboard/components/importantDates.component';
import { PageHeader } from '#dashboard/components/pageHeader.component';
import { Database } from '#types';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '#hooks';

export const DashboardPage: FC = () => {

    const { loggedInUser, currentDb } = useAppContext();
    const navigate = useNavigate();
    const isSuperUser: boolean = loggedInUser?.roles?.SUPERUSER || false
    const isZimDb: boolean = currentDb === Database.ZIM;

    if (!isSuperUser || !isZimDb) {
        navigate("/users")
        return null
    }

    return <>
        <PageHeader header={'ZIM Dashboard aka S.A.P. - 2024 Update'} />
        <ImportantDates />
    </>
}
