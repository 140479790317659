import { FC, useEffect } from 'react';
import { useAppContext, useSafeState } from '#hooks';
import { Database, Locale, SupportOption, getRelevantLanguageDisplay, getRelevantLanguages } from '#types';
import { dashboardApi } from '#api';
import { SupportOptionToEdit } from './supportOptionToEdit.component';

export const SupportOptionsList: FC = () => {

    const { setApiStatus, currentEnvironment, currentDb } = useAppContext();
    const [ isLoading, setIsLoading ] = useSafeState<boolean>(true)
    const [ supportOptionToEdit, setSupportOptionToEdit ] = useSafeState<SupportOption | null>(null)
    const [ supportOptions, setSupportOptions ] = useSafeState<SupportOption[]>([])
    const [ selectedLanguage, setSelectedLanguage ] = useSafeState<Locale>(Locale.ENGLISH);
    const [ showEditSupportOption, setShowEditSupportOption ] = useSafeState<boolean>(false)
    const languageOptionArray = getRelevantLanguages(currentDb, false)

    const fetchSupportOptions = async () => {
        try {
            const response = await dashboardApi.getAllSupportOptions(selectedLanguage)
            setSupportOptions([ ...response.data.supportOptions ] as SupportOption[]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(
        () => {
            if(supportOptionToEdit === null) {
                fetchSupportOptions();
            }
        },
        [supportOptionToEdit, currentEnvironment, selectedLanguage]
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <div className="main-dashboard-container pd-m">
        <div className='three-columns-expand-two ml-m'>
            <h3 className='vertical-align'>Edit Support Options</h3>
            <div/>
            <div className='two-columns-expand-one'>
                <div/>
                <button className='clear-button' id='expand-contract-button' onClick={() => setShowEditSupportOption(!showEditSupportOption)}>
                    <img src={showEditSupportOption ? "/images/contract.png" : "/images/expand.png"} alt="expand-contract" id='expand-contract-img' />
                </button>
            </div>
        </div>
        {
            showEditSupportOption && (
                <>
                    {
                        !supportOptionToEdit &&
                            <div className='three-columns-expand-three'>
                                <label className='bold ml-m'>Select the language:</label>
                                <div className='three-rows-expand-one-three'>
                                    <div/>
                                    <select value={selectedLanguage} onChange={(event) => setSelectedLanguage(event.target.value as Locale)} className='ml-m'>
                                        {
                                            languageOptionArray.map(
                                                (value) => (
                                                    <option value={value} key={value}>
                                                        {getRelevantLanguageDisplay(value)}
                                                    </option>
                                                )
                                            )
                                        }
                                    </select>
                                    <div/>
                                </div>
                                <div />
                            </div>
                    }
                    {
                        !supportOptionToEdit && (
                            <>
                                {
                                    supportOptions.filter((supportOption) => supportOption.language === selectedLanguage)
                                    .map(
                                        (supportOption, index) => ( 
                                            <div key={index} className='three-columns-expand-two mb-m ml-m'>
                                                <h4 style={{ width: '180px' }}>{supportOption.title}</h4>
                                                <div>
                                                    {
                                                        supportOption.problems?.map(
                                                            (problem, problemIndex) => (
                                                                <p key={problemIndex}>{problem}</p>
                                                            )
                                                        )
                                                    }
                                                </div>
                                                <div className='two-rows-expand-two'>
                                                    <button onClick={() => setSupportOptionToEdit(supportOption)} className='clear-button edit-button'>
                                                        EDIT
                                                    </button>
                                                    <div/>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                                <div className='three-rows-expand-one-three'>
                                    <div/>
                                    <button 
                                        onClick={() => { setSupportOptionToEdit({ title: 'New title', problems: ['problem 1'], language: selectedLanguage })}}
                                        className='clear-button plus-button'
                                    >
                                        +
                                    </button>
                                    <div/>
                                </div>
                            </>
                        )
                    }
                    {
                        supportOptionToEdit && <SupportOptionToEdit supportOptionToEdit={supportOptionToEdit} setSupportOptionToEdit={setSupportOptionToEdit} />
                    }
                </>
            )
        }
    </div>
}
