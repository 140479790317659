import { constants } from '#globals';
import { useAppContext } from '#hooks';
import { EnvironmentType } from '#types';
import { HttpStatusCode } from 'axios';
import { FC } from 'react';

export const DatabaseToggleSwitch: FC = () => {

    const { currentEnvironment, setCurrentEnvironment, setApiStatus } = useAppContext();

    const handleSwitchChange = () => {
        const selectedEnvironment = currentEnvironment === EnvironmentType.DEVELOPMENT ? EnvironmentType.PRODUCTION : EnvironmentType.DEVELOPMENT;
        localStorage.setItem(constants.targetEnv, selectedEnvironment)
        setCurrentEnvironment(selectedEnvironment);
        setApiStatus({ code: HttpStatusCode.Ok, message: `Database changed to ${selectedEnvironment.toUpperCase()}`})
    };

    if(process.env.REACT_APP_DB_URL?.includes('localhost')) {
        return <div className='three-rows-expand-one-three mr-m'>
            <div/>
            <p className='local-text'>LOCAL</p>
            <div/>
        </div>
    }

    return <div className='three-rows-expand-one-three mr-m'>
        <div/>
        <div className='four-columns-expand-one'>
            <div/>
            <label htmlFor="switch-development" className="switch-label bold mr-s">
                DEV
            </label>
            <label className="switch">
                <input
                    type="checkbox"
                    checked={currentEnvironment === EnvironmentType.PRODUCTION}
                    onChange={handleSwitchChange}
                    id="switch-development"
                />
                <span className={`slider round ${currentEnvironment}`} />
            </label>
            <label htmlFor="switch-production" className="switch-label bold ml-s">
                PROD
            </label>
        </div>
        <div/>
    </div>
}
