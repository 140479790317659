import { FC, useEffect, useRef } from 'react';
import { reportTableHeaders } from '#globals';
import { Issue, ReportsFilter } from '#types';
import { ReportSearchBar } from '#reports/components/reportSearchBar.component'
import { Pagination } from '#dashboard/components/pagination.component';
import moment from 'moment';
import { useAppContext, useSafeState } from '#hooks';
import { dashboardApi } from '#api';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';

//TODO create a generic component for the table

export const ReportsTable: FC = () => {

    const { setApiStatus, currentEnvironment } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [reports, setReports]= useSafeState<Issue[]>([])
    const [pageSize, setPageSize] = useSafeState<number>(5);
    const [currentPage, setCurrentPage] = useSafeState<number>(1);
    const [filter, setFilter] = useSafeState<ReportsFilter>({});
    const totalPagesRef = useRef<number>(1);
    //TODO! link to the support of the provider

    const fetchReports = async () => {
        try {
            const response = await dashboardApi.getReports(filter, false, currentPage, pageSize)
            totalPagesRef.current = Math.ceil(response.data.total / pageSize)
            setReports([...response.data.reports]);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(
        () => {
            fetchReports();
        }, 
        [filter, currentPage, pageSize, currentEnvironment]
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <div className='pd-m main-dashboard-container mt-s'>
        <div className='three-columns-expand-two'>
            <ReportSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
            <div />
            <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Reports' />
        </div>
        <table className='center-wrapper table'>
            <thead className="table-header">
                <tr>
                    {
                        reportTableHeaders.map(
                            header => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    reports.map((report, index) => 
                        <tr key={`${report._id}_${String(index)}`} className={'table-row'} onClick={() => window.open(`reports/${report.email}`, '_blank')} >
                            <td className="table-cell a-tag-reset">{report.email}</td>
                            <td className="table-cell a-tag-reset">{moment(report.createdAt).format('DD/MM/YYYY')}</td>
                            <td className="table-cell a-tag-reset">{report.type}</td>
                            <td className="table-cell a-tag-reset">{report.description}</td>
                            <td className="table-cell a-tag-reset">{report.message}</td>
                            <td className="table-cell a-tag-reset">
                                {
                                    report.images.map(
                                        (image, index) => (
                                            <img key={index} className="report-screenshot" src={`${image}`} alt={`Image ${index}`} />
                                        )
                                    )
                                }
                            </td>
                            <td className="table-cell a-tag-reset">{report.isOpen ? 'Yes' : 'No'}</td>
                        </tr>
                    )
                }
            </tbody>
        </table>
        <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
}
