import { constants } from '#globals';
import { Database } from '#types';
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

export const authClient = axios.create({ baseURL: process.env.REACT_APP_DB_URL as string });

authClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (localStorage.getItem(constants.targetDb) === Database.SBB) {
            config.baseURL = process.env.REACT_APP_SBB_DB_URL as string;
        }
        if (localStorage.getItem(constants.targetDb) === Database.GD) {
            config.baseURL = process.env.REACT_APP_GD_DB_URL as string;
        }
        if (config.headers) {
            config.headers.Accept = 'application/json';
        }
        return config as InternalAxiosRequestConfig<any>;
    },
    (error) => {
        return Promise.reject(error);
    }
);
