import { FC } from 'react';
import { importantDatesUL } from '#globals';

interface GroupedDates {
    month: string;
    dates: { 
        date: Date
        type: string
        message: string
    }[];
}

export const ImportantDates: FC = () => {
    const groupedDates: GroupedDates[] = [];
    const currentDate = new Date();
    const nextSixMonths = new Date(currentDate.getFullYear(), currentDate.getMonth() + 5, currentDate.getDate());
    function dateValue(date: Date): number {
        return date.getFullYear() * 12 + date.getMonth();
    }
    const currentDV = dateValue(currentDate);
    const nextSixMonthsDV = dateValue(nextSixMonths);
    const filteredDates = importantDatesUL.filter(item => {
        const date = new Date(item.date);
        const itemDV = dateValue(date);
        return itemDV >= currentDV && itemDV <= nextSixMonthsDV;
    });

    filteredDates.forEach(item => {
        const date = new Date(item.date);
        const monthName = date.toLocaleString('default', { month: 'long' });
        const month = groupedDates.find(group => group.month === monthName);
        if (!month) {
            groupedDates.push({ month: monthName, dates: [{ date, type: item.type, message: item.message }] });
        } else {
            month.dates.push({ date, type: item.type, message: item.message });
        }
    });

    function nth_of(i: number) {
        const j = i % 10;
        if (j === 1 && i !== 11) return i + "st";
        if (j === 2 && i !== 12) return i + "nd";
        if (j === 3 && i !== 13) return i + "rd";
        return i + "th";
    }
    
    groupedDates.sort((a, b) => {
        const aValue = dateValue(a.dates[0].date);
        const bValue = dateValue(b.dates[0].date);
        return aValue - bValue;
    });
    
    return <div className='auto-columns main-dashboard-container pd-m mt-s'>
        {
            groupedDates.map(
                (month, index) => (
                    <div key={index} className="main-dashboard-container pd-m mt-s calendar-dimensions faq-li center-wrapper">
                        <div className='mb-m faq-question'>
                            <div className="mb-m question-section">
                                <h2>{month.month.toUpperCase()}</h2>
                            </div>
                        </div>
                        <ul className='answer-section mb-m'>
                            {month.dates.map(
                                (item, i) => (
                                    <li key={i} className="date-item mt-s">
                                        <span className="date ml-s vertical-align">{nth_of(item.date.getDate())}</span>
                                        <img src={`/images/${item.type}.png`} alt="icon-dates" className='important-dates-icon vertical-align' />
                                        {item.message}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                )
            )
        }
    </div>
};
