import { useSafeState } from '#hooks';
import { FaqSubSectionContent, Reward } from '#types';
import { Dispatch, FC, SetStateAction } from 'react';

interface EditRewardSubSectionProps {
    updatedReward: Reward
    setUpdatedReward: Dispatch<SetStateAction<Reward>>
    faqSubSectionToEdit: string | null
    setFaqSubSectionToEdit: Dispatch<SetStateAction<string | null>>
}

export const EditRewardSubSection: FC<EditRewardSubSectionProps> = ({ updatedReward, setUpdatedReward, faqSubSectionToEdit, setFaqSubSectionToEdit }) => {

    const isInstructions: boolean = faqSubSectionToEdit === 'instructions'
    const emptyFaqSubSectionArray: FaqSubSectionContent[] = [{type: 'ol', title: '', value: ['']}]
    const [updatedFaqSubSection, setUpdatedFaqSubSection] = useSafeState<FaqSubSectionContent[]>(
        isInstructions 
            ? updatedReward.instructions || emptyFaqSubSectionArray
            : updatedReward.additionalInfo || emptyFaqSubSectionArray
    );

    const updateFaqSubSection = () => {
        const newUpdatedReward = { ...updatedReward };
        isInstructions ? newUpdatedReward.instructions = updatedFaqSubSection : newUpdatedReward.additionalInfo = updatedFaqSubSection;
        setUpdatedReward(newUpdatedReward);
        setFaqSubSectionToEdit(null)
    }

    const handleTypeChange = (paragraphIndex: number, listType: string) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            updatedContent[paragraphIndex] = { ...updatedContent[paragraphIndex], type: listType };
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const handleTitleChange = (paragraphIndex: number, title: string) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            updatedContent[paragraphIndex] = { ...updatedContent[paragraphIndex], title };
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const deleteTitle = (paragraphIndex: number) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            updatedContent[paragraphIndex] = { ...updatedContent[paragraphIndex], title: undefined };
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const handleContentChange = (index: number, paragraphIndex: number, newValue: string) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            const updatedValues = [...updatedContent[paragraphIndex].value];
            updatedValues[index] = newValue;
            const updatedParagraph = { ...updatedContent[paragraphIndex], value: updatedValues };
            updatedContent[paragraphIndex] = updatedParagraph;
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const addValueToParagraph = (paragraphIndex: number) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            const updatedValue = [...updatedContent[paragraphIndex].value, ''];
            const updatedParagraph = { ...updatedContent[paragraphIndex], value: updatedValue };
            updatedContent[paragraphIndex] = updatedParagraph;
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const deleteValueFromParagraph = (paragraphIndex: number, valueIndex: number) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            const updatedValue = [...updatedContent[paragraphIndex].value];
            if(updatedValue.length === 1) {
                deleteParagraph(paragraphIndex)
                return
            }
            updatedValue.splice(valueIndex, 1);
            const updatedParagraph = { ...updatedContent[paragraphIndex], value: updatedValue };
            updatedContent[paragraphIndex] = updatedParagraph;
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const deleteParagraph = (paragraphIndex: number) => {
        const updatedContent = [...updatedFaqSubSection];
        if (updatedContent[paragraphIndex]) {
            updatedContent.splice(paragraphIndex, 1);
            setUpdatedFaqSubSection(updatedContent);
        }
    };

    const addParagraph = () => {
        const emptyParagraph = { type: 'ol', title: '', value: [''] }
        const updatedContent = [...updatedFaqSubSection, emptyParagraph];
        setUpdatedFaqSubSection(updatedContent);
    };

    return <div className="main-dashboard-container mb-s pd-m">
            {
                updatedFaqSubSection.map(
                    (content, paragraphIndex) => (
                        <div key={paragraphIndex}>
                            <label htmlFor="paragraph" className='bold mb-s'>Title</label>
                            <div className='three-columns-expand-one'>
                                <textarea
                                    value={content.title || ''}
                                    onChange={(e) => handleTitleChange(paragraphIndex, e.target.value)}
                                    className='pd-s mb-s mt-s'
                                    style={{ width: '100%' }}
                                />
                                <div className='three-rows-expand-one-three ml-m'>
                                    <div/>
                                    <button className="center-wrapper bold copy-text-button mb-s" onClick={() => deleteTitle(paragraphIndex)}>DEL</button>
                                    <div/>
                                </div>
                            </div>
                            <div className='five-columns-expand-five'>
                                <label htmlFor={`type-${paragraphIndex}`} className="mr-s">List type: </label>
                                <input
                                    type="radio"
                                    id={`ol-${paragraphIndex}`}
                                    name={`type-${paragraphIndex}`}
                                    value="ol"
                                    className="ml-m vertical-align"
                                    checked={content.type === "ol"}
                                    onChange={() => handleTypeChange(paragraphIndex, 'ol')}
                                />
                                <label htmlFor={`normal-${paragraphIndex}`} className='ml-s'>Numbered List</label>
                                <input
                                    type="radio"
                                    id={`ul-${paragraphIndex}`}
                                    name={`type-${paragraphIndex}`}
                                    value="ul"
                                    className="ml-m vertical-align"
                                    checked={content.type === "ul"}
                                    onChange={() => handleTypeChange(paragraphIndex, 'ul')}
                                />
                                <label htmlFor={`ul-${paragraphIndex}`} className='ml-s'>Bullet-point List</label>
                                <div/>
                            </div>
                            {
                                content.value.map(
                                    (content, valueIndex) => (
                                        <div className='three-columns-expand-one'>
                                            <textarea
                                                value={content}
                                                onChange={(e) => handleContentChange(valueIndex, paragraphIndex, e.target.value)}
                                                className='pd-s mb-s mt-s'
                                                style={{ width: '100%' }}
                                            />
                                            <div className='three-rows-expand-one-three ml-m'>
                                                <div/>
                                                <button className="center-wrapper bold copy-text-button mb-s" onClick={() => deleteValueFromParagraph(paragraphIndex, valueIndex)}>DEL</button>
                                                <div/>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            <div className='three-columns-expand-one-three'>
                                <div/>
                                <button onClick={() => addValueToParagraph(paragraphIndex)} className="center-wrapper bold copy-text-button mb-s mt-m">ADD VALUE</button>
                                <div/>
                            </div>
                        </div>
                    )
                )
            }
        <div className='three-columns-expand-one-three'>
            <div/>
            <button onClick={() => addParagraph()} className="center-wrapper bold copy-text-button mb-s mt-m">ADD PARAGRAPH</button>
            <div/>
        </div>
        <div className='four-columns-expand-one-four'>
            <div/>
            <div className="three-columns-expand-one-three mt-m">
                <div />
                <button className="center-wrapper bold copy-text-button mb-s" onClick={() => setFaqSubSectionToEdit(null)}>
                    CANCEL
                </button>
                <div />
            </div>
            <div className="three-columns-expand-one-three mt-m ml-m">
                <div />
                <button className="center-wrapper bold copy-text-button mb-s" onClick={() => updateFaqSubSection()}>
                    SUBMIT
                </button>
                <div />
            </div>
            <div/>
        </div>
    </div>
}
