import { dashboardApi } from '#api';
import { FC, useEffect, useRef } from 'react';
import { Conversation, ConversationsFilter } from '#types';
import { Pagination } from '#dashboard/components/pagination.component';
import { conversationTableHeaders } from '#globals';
import { useAppContext, useSafeState } from '#hooks';
import { SelectPerPageDropdown } from '#dashboard/components/selectPerPageDropdown.component';
import { ConversationSearchBar } from './conversationSearchBar.component';
import { formatDateWithHours } from '../../services/helpers';

export const LiveChatArchiveTable: FC = () => {

    const { setApiStatus, currentEnvironment } = useAppContext();
    const [ isLoading, setIsLoading ] = useSafeState<boolean>(true)
    const [ showConversationsTable, setShowConversationsTable ] = useSafeState<boolean>(false)
    const [ conversations, setConversations ] = useSafeState<Conversation[ ]>([ ]);
    const [ pageSize, setPageSize ] = useSafeState<number>(10);
    const [ currentPage, setCurrentPage ] = useSafeState<number>(1);
    const [ filter, setFilter ] = useSafeState<ConversationsFilter>({});
    const totalPagesRef = useRef<number>(1);

    const fetchConversations = async () => {
        try {
            const response = await dashboardApi.getClosedConversations(filter, currentPage, pageSize)
            totalPagesRef.current = Math.ceil(response.data.total / pageSize)
            setConversations(response.data.conversations);
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(
        () => {
            fetchConversations();
        },
        [filter, currentPage, pageSize, currentEnvironment]
    );

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    return <div className="main-dashboard-container pd-m">
        <div className='three-columns-expand-two ml-m'>
            <h3 className='vertical-align'>Chat Archive</h3>
            <div/>
            <div className='two-columns-expand-one'>
                <div/>
                <button className='clear-button' id='expand-contract-button' onClick={() => setShowConversationsTable(!showConversationsTable)}>
                    <img src={showConversationsTable ? "/images/contract.png" : "/images/expand.png"} alt="expand-contract" id='expand-contract-img' />
                </button>
            </div>
        </div>
        {
            showConversationsTable && <>
                <div className='three-columns-expand-two'>
                    <ConversationSearchBar setCurrentPage={setCurrentPage} setFilter={setFilter} />
                    <div />
                    <SelectPerPageDropdown pageSize={pageSize} setPageSize={setPageSize} setCurrentPage={setCurrentPage} name='Conversations' />
                </div>
                <table className='center-wrapper table'>
                    <thead className="table-header">
                        <tr>
                            {
                                conversationTableHeaders.map(
                                    (header: string) => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            conversations.map(
                                (conversation: Conversation, index: number) => (
                                    <tr key={`${conversation._id}_${String(index)}`} className={'table-row'} onClick={() => window.open(`conversations/${conversation._id}`, '_blank')} >
                                        <td className="table-cell">{conversation.userEmail}</td>
                                        <td className="table-cell">{conversation.selectedTitle}</td>
                                        <td className="table-cell">{conversation.selectedProblem}</td>
                                        <td className="table-cell">{formatDateWithHours(conversation.createdAt)}</td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>
                <Pagination totalPages={totalPagesRef.current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
        }
    </div>
}
