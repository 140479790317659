import { FC } from 'react';
import { ReportsTable } from '#reports/components/reportsTable.component';
import { PageHeader } from '#dashboard/components/pageHeader.component'

export const Reports: FC = () => {
    return <>
        <PageHeader header={'Reports'}/>
        <ReportsTable />
    </>
}
