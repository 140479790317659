import { useAppContext, useSafeState } from '#hooks';
import { FaqSubSection, FaqSubSectionContent, FaqSubSectionWithUrl } from '#types';
import { Dispatch, FC, SetStateAction } from 'react';
import { dashboardApi } from '#api';

interface EditFaqFormProps {
    faqToEdit: FaqSubSection | FaqSubSectionWithUrl
    setFaqToEdit: Dispatch<SetStateAction<FaqSubSection | FaqSubSectionWithUrl>>
}

export const EditFaqForm: FC<EditFaqFormProps> = ({ faqToEdit, setFaqToEdit }) => {

    const { setApiStatus } = useAppContext();
    const originalTitle: string = faqToEdit.title
    const [editedTitle, setEditedTitle] = useSafeState(faqToEdit.title);
    const [editedContent, setEditedContent] = useSafeState(faqToEdit.content);

    const updateFaq = async () => {
        try {
            const response = await dashboardApi.updateFaq(originalTitle, editedContent, editedTitle)
            setApiStatus({ code: response.status, message: response.data.message })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setFaqToEdit({} as FaqSubSection)
        }
    }

    const handleContentChange = (index: number, newValue: string, fontWeight: string) => {
        const updatedContent = [...(editedContent as FaqSubSectionContent[])];
        updatedContent[index] = { ...updatedContent[index], value: [newValue], type: fontWeight };
        setEditedContent(updatedContent);
    };

    const addParagraph = () => {
        setEditedContent([...(editedContent as FaqSubSectionContent[]), {type: 'normal', value: [""]}])
    }

    const handleUrlChange = (updatedValue: string) => {
        setEditedContent(updatedValue)
    }

    return <>
        <div className="main-dashboard-container mb-s pd-m">
            <label htmlFor="titleTextarea" className='bold'>Title</label>
            <textarea
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                rows={2}
                style={{ width: '100%' }}
                className='bold mb-m pd-s'
            />
            {
                typeof faqToEdit.content === 'string' ? <>
                        <textarea
                            value={editedContent as string}
                            onChange={(e) => handleUrlChange(e.target.value)}
                            rows={1}
                            className='pd-s'
                            style={{ width: '100%' }}
                        />
                    </> :
                    (editedContent as FaqSubSectionContent[]).map(
                        (content: FaqSubSectionContent, index: number) => (
                            <div key={index}>
                                <label htmlFor="paragraph" className='bold mb-s'>Paragraph {index + 1}</label>
                                <div className='five-columns-expand-five'>
                                    <label htmlFor={`type-${index}`} className="mr-s">Text type: </label>
                                    <input
                                        type="radio"
                                        id={`normal-${index}`}
                                        name={`type-${index}`}
                                        value="normal"
                                        className="ml-m vertical-align"
                                        checked={content.type === "normal"}
                                        onChange={() => handleContentChange(index, content.value[0], 'normal')}
                                    />
                                    <label htmlFor={`normal-${index}`} className='ml-s'>Normal</label>
                                    <input
                                        type="radio"
                                        id={`bold-${index}`}
                                        name={`type-${index}`}
                                        value="bold"
                                        className="ml-m vertical-align"
                                        checked={content.type === "bold"}
                                        onChange={() => handleContentChange(index, content.value[0], 'bold')}
                                    />
                                    <label htmlFor={`bold-${index}`} className='ml-s'>Bold</label>
                                    <div/>
                                </div>
                                <textarea
                                    value={content.value}
                                    onChange={(e) => handleContentChange(index, e.target.value, content.type)}
                                    rows={4}
                                    className='pd-s'
                                    style={{ width: '100%', fontWeight: content.type === 'bold' ? 'bold' : '' }}
                                />
                            </div>
                        )
                    )
            }
            {
                typeof faqToEdit.content !== 'string' &&
                    <div className='three-columns-expand-one-three'>
                        <div/>
                        <button onClick={addParagraph} className="center-wrapper bold copy-text-button mb-s mt-m">ADD PARAGRAPH</button>
                        <div/>
                    </div>
            }
            <div className='four-columns-expand-one-four'>
                <div/>
                <div className="three-columns-expand-one-three mt-m">
                    <div />
                    <button className="center-wrapper bold copy-text-button mb-s" onClick={() => setFaqToEdit({} as FaqSubSection)}>
                        CANCEL
                    </button>
                    <div />
                </div>
                <div className="three-columns-expand-one-three mt-m ml-m">
                    <div />
                    <button className="center-wrapper bold copy-text-button mb-s" onClick={() => updateFaq()}>
                        SUBMIT
                    </button>
                    <div />
                </div>
                <div/>
            </div>
        </div>
    </>
}
