import { FC, useEffect, useRef } from 'react';
import { useAppContext, useSafeState } from '#hooks';
import { Database, Faq, FaqDropdownOptions, FaqSubSection, FaqSubSectionWithUrl, Locale, getRelevantLanguageDisplay, getRelevantLanguages } from '#types';
import { dashboardApi } from '#api';
import { EditFaqForm } from './editFaqForm.component';

export const FaqList: FC = () => {

    const [faqs, setFaqs] = useSafeState<Faq[]>([])
    const { setApiStatus, currentEnvironment, loggedInUser, currentDb } = useAppContext();
    const [isLoading, setIsLoading] = useSafeState<boolean>(true)
    const [faqToEdit, setFaqToEdit] = useSafeState<FaqSubSection | FaqSubSectionWithUrl>({} as FaqSubSection)
    const [selectedSection, setSelectedSection] = useSafeState<string>(FaqDropdownOptions.USING_THE_APP);
    const [selectedLanguage, setSelectedLanguage] = useSafeState<Locale>(Locale.ENGLISH);
    const languageOptionArray = getRelevantLanguages(currentDb, false)
    const faqDropdownOptionsArray = useRef<string[]>(Object.values(FaqDropdownOptions));

    useEffect(
        () => {
            const fetchFaqs = async () => {
                try {
                    const response = await dashboardApi.getAllFaqs(selectedLanguage)
                    setFaqs([...response.data.faqs]);
                    faqDropdownOptionsArray.current = response.data.faqs.map((faq: { section: string }) => faq.section);
                    setSelectedSection(faqDropdownOptionsArray.current[0]);
                } catch (error: any) {
                    setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
                } finally {
                    setIsLoading(false)
                }
            };
            fetchFaqs();
        },
        [faqToEdit, currentEnvironment, selectedLanguage]
    );

    const handleCopy = (faqSubSection: FaqSubSection) => {
        let textToCopy: string = ''
        faqSubSection.content.forEach(
            (subSectionContent) => {
                const { type, value } = subSectionContent;
                type === 'bold' ? textToCopy += `**${value.join("")}**\n` : textToCopy += `${value.join("")}\n`
            }
        )
        navigator.clipboard.writeText(textToCopy);
    };

    if (isLoading) {
        return <div className="lds-hourglass" />
    }

    if (faqToEdit?.content?.length > 0) {
        return <EditFaqForm faqToEdit={faqToEdit} setFaqToEdit={setFaqToEdit} />
    }

    //TODO: Make the faq topic editable

    return <div className="main-dashboard-container mb-s pd-m" >
        <div className='three-columns-expand-three'>
            <label className='bold ml-m'>Select the language:</label>
            <div className='three-rows-expand-one-three'>
                <div />
                <select value={selectedLanguage} onChange={(event) => setSelectedLanguage(event.target.value as Locale)} className='ml-m'>
                    {
                        languageOptionArray.map(
                            (value) => (
                                <option value={value} key={value}>
                                    {getRelevantLanguageDisplay(value)}
                                </option>
                            )
                        )
                    }
                </select>
                <div />
            </div>
            <div />
        </div>
        <div className='three-columns-expand-three mb-m'>
            <label className='bold ml-m'>Select the topic of the FAQs:</label>
            <div className='three-rows-expand-one-three'>
                <div />
                <select value={selectedSection} onChange={(event) => setSelectedSection(event.target.value)} className='ml-m'>
                    {
                        faqDropdownOptionsArray.current.map(
                            (searchOption) => <option value={searchOption} key={searchOption}>
                                {searchOption}
                            </option>
                        )
                    }
                </select>
                <div />
            </div>
            <div />
        </div>
        <ul>
            {
                faqs
                    .filter((faq) => faq.section === selectedSection)
                    .map(
                        (faq, index) => (
                            <li key={index}>
                                <ul>
                                    {
                                        faq.subSections.map(
                                            (faqSubSection, index) => (
                                                <li className="main-dashboard-container faq-li mb-s" key={index}>
                                                    <div className="question-section">
                                                        <div className="mb-m reading-width center-wrapper faq-question">
                                                            <h2>{faqSubSection.title.toUpperCase()}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="answer-section">
                                                        <div className="center-wrapper reading-width">
                                                            {
                                                                typeof faqSubSection.content === 'string' ?
                                                                    <p>{faqSubSection.content}</p> :
                                                                    faqSubSection.content.map(
                                                                        (content, index) => (
                                                                            <p key={index} className={content.type === "bold" ? "bold" : ""}>
                                                                                {content.value[0]}
                                                                            </p>
                                                                        )
                                                                    )
                                                            }
                                                        </div>
                                                        <div className='four-columns-expand-one-four'>
                                                            <div />
                                                            <div className="three-columns-expand-one-three mt-m mr-m">
                                                                <div />
                                                                <button className="center-wrapper bold copy-text-button mb-xl" onClick={() => handleCopy(faq.subSections[index])}>
                                                                    COPY
                                                                </button>
                                                                <div />
                                                            </div>
                                                            {
                                                                loggedInUser?.roles?.SUPERUSER && (
                                                                    <div className="three-columns-expand-one-three mt-m">
                                                                        <div />
                                                                        <button className="center-wrapper bold copy-text-button mb-xl" onClick={() => setFaqToEdit(faq.subSections[index])}>
                                                                            EDIT
                                                                        </button>
                                                                        <div />
                                                                    </div>
                                                                )
                                                            }
                                                            <div />
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        )
                                    }
                                </ul>
                            </li>
                        )
                    )
            }
        </ul>
    </div>
}
