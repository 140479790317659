import { FC } from 'react';
import { PageHeader } from '#dashboard/components/pageHeader.component'
import { RewardsInformation } from '#rewards/components/rewardsInformation.component';
import { CreateNewReward } from '#rewards/components/createNewReward.component';
import { GuideToCreateReward } from '#rewards/components/guideToCreateReward.component';

export const RewardsPage: FC = () => {

    return <>
        <PageHeader header={'ZIM Rewards'}/>
        <RewardsInformation />
        <CreateNewReward />
        <GuideToCreateReward />
    </>
}
