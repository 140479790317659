import { FC } from 'react';
import { ComposeNotification } from '#notifications/components/composeNotification.component';
import { PageHeader } from '#dashboard/components/pageHeader.component'
import { NotificationCodesToCopy } from '#notifications/components/notificationCodesToCopy.component';
import { useSafeState } from '#hooks';

export const Notifications: FC= () => {

    const [isConfirmationOpen, setIsConfirmationOpen] = useSafeState<boolean>(false)

    return <>
        <PageHeader header={'Notifications'}/>
        <ComposeNotification isConfirmationOpen={isConfirmationOpen} setIsConfirmationOpen={setIsConfirmationOpen}/>
        {
            !isConfirmationOpen && <NotificationCodesToCopy />
        }
    </>
}
