import { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { searchLabels, UserSearchBarOptions, UsersFilter } from '#types';
import { useSafeState } from '#hooks';

interface UserSearchBarProps{
    setFilter: Dispatch<SetStateAction<UsersFilter>>;
    setCurrentPage: (currentPage: number) => void;
}

export const UserSearchBar: FC<UserSearchBarProps> = ({ setFilter, setCurrentPage }) => {
    const [searchQuery, setSearchQuery] = useSafeState<string>('');
    const [searchField, setSearchField] = useSafeState<string>(UserSearchBarOptions.EMAIL);
    const userSearchBarOptionLabels: searchLabels[] = [
        { value: UserSearchBarOptions.FIRST_NAME, label: "First Name" },
        { value: UserSearchBarOptions.LAST_NAME, label: "Last Name" },
        { value: UserSearchBarOptions.EMAIL, label: "Email" },
        { value: UserSearchBarOptions.IS_VERIFIED, label: "Is Verified?" },
        { value: UserSearchBarOptions.LOCATION, label: "Location" },
        { value: UserSearchBarOptions.DEVICE_OS, label: "Device OS" },
        { value: UserSearchBarOptions.PROVIDER, label: "Provider" }
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const filter = { [searchField]: searchQuery };
        setFilter(filter);
        setCurrentPage(1)
    };

    const handleClearFilter = () => {
        setFilter({})
        setCurrentPage(1)
    }

    return <div className='search-bar'>
        <form onSubmit={handleSubmit} id='user-search-bar-container'>
            <div className='two-columns-expand-two'>
                <label htmlFor="search-query" className='vertical-align mr-m'>Search:</label>
                <input
                    type="text"
                    id="search-query"
                    className='pl-m'
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                />
            </div>
            <div className='four-columns-auto'>
                <label htmlFor="search-field" className='vertical-align ml-m mr-m nowrap'>Search by:</label>
                <div className='three-rows-expand-one-three'>
                    <div/>
                    <select id="search-field" value={searchField} onChange={(event) => setSearchField(event.target.value)}>
                        {
                            Object.values(userSearchBarOptionLabels).map(
                                (searchBarOption) => <option value={searchBarOption.value} key={searchBarOption.value}>
                                    {searchBarOption.label}
                                </option>
                            )
                        }
                    </select>
                    <div/>
                </div>
                <button type="submit" className='searchBy-btn bold white-text'>SEARCH</button>
                <button type="button" onClick={handleClearFilter} className='searchBy-btn white-text bold nowrap'>CLEAR</button>
            </div>
        </form>
    </div>
};
