import { FC, ChangeEvent, FormEvent } from 'react';
import { dashboardApi } from '#api';
import { PromotionReqBody, PromoCodeScope, PromoCodeType, BackgroundUrlOptions } from '#types';
import { useAppContext, useSafeState } from '#hooks';
import { HttpStatusCode } from 'axios';

//TODO: change validTo and validFrom to remove the tertiary condition
export const CreatePromotion: FC = () => {

    const { setApiStatus } = useAppContext();
    const [isExpandedBubble, setIsExpandedBubble] = useSafeState<boolean>(false)
    const [promotionToCreate, setPromotionToCreate] = useSafeState<PromotionReqBody>(
        {
            title: '',
            description: '',
            code: '',
            amount: 0,
            validFrom: new Date().toISOString(),
            validTo: new Date(Date.now() + 86400000).toISOString(),
            targetEmails: [],
            type: PromoCodeType.NORMAL,
            scope: PromoCodeScope.GLOBAL,
            backgroundUrl: BackgroundUrlOptions.GREEN_CARD,
            active: true,
            oneOffUsed: false,
            numberOfUsesRemaining: -1,
        }
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPromotionToCreate({ ...promotionToCreate, [name]: value });
    };

    const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setPromotionToCreate({ ...promotionToCreate, [name]: value.split(',') });
    };

    const handleInputChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setPromotionToCreate(
            {
                ...promotionToCreate,
                [event.target.name]: event.target.value
            }
        );
    };

    const src = isExpandedBubble ? '/images/expand.png' : 'images/contract.png';

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        let requestBody: PromotionReqBody = promotionToCreate
        requestBody.amount = Number(requestBody.amount)
        requestBody.numberOfUsesRemaining = Number(requestBody.numberOfUsesRemaining)
        try {
            const res = await dashboardApi.createPromotion(requestBody)
            setApiStatus({code: res.status, message: 'Promotion created successfully!'});
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    };


    return <div className='main-dashboard-container pd-m mt-s'>
        <div className='three-columns-expand-two' onClick={() => setIsExpandedBubble(!isExpandedBubble)}>
            <h3>Create A Promo Code</h3>
            <div />
            <img src={src} alt="arrow" className='expand-contract-esim vertical-align ml-l' />
        </div>
        {
            isExpandedBubble && <form onSubmit={handleSubmit} className='create-promotion-form-grid'>
                <div className='auto-size-columns'>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="code" className='mr-s vertical-align first-column-width'>Title *</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            className="pd-s pl-m promo-input"
                            value={promotionToCreate.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="code" className='mr-s vertical-align first-column-width'>Description *</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            className="pd-s pl-m promo-input"
                            value={promotionToCreate.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="code" className='mr-s vertical-align first-column-width'>Code *</label>
                        <input
                            type="text"
                            id="code"
                            name="code"
                            maxLength={8}
                            className="pd-s pl-m promo-input"
                            value={promotionToCreate.code}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="amount" className='mr-s vertical-align first-column-width'>Amount *</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className='pl-m promo-input'
                            value={promotionToCreate.amount}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="validFrom" className='mr-s vertical-align first-column-width'>Valid From *</label>
                        <input
                            type="date"
                            id="validFrom"
                            name="validFrom"
                            className='pl-m promo-input'
                            value={promotionToCreate.validFrom ? new Date(promotionToCreate.validFrom).toISOString().split('T')[0] : ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="validTo" className='mr-s vertical-align first-column-width'>Valid To *</label>
                        <input
                            type="date"
                            id="validTo"
                            name="validTo"
                            className='pl-m promo-input'
                            value={promotionToCreate.validTo ? new Date(promotionToCreate.validTo).toISOString().split('T')[0] : ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor='backgroundUrl' className='mr-s vertical-align first-column-width'>
                            Background URL *
                        </label>
                        <select
                            id='backgroundUrl'
                            name='backgroundUrl'
                            className='mt-s mb-s'
                            value={promotionToCreate.backgroundUrl}
                            onChange={handleInputChange}
                        >
                            {
                                Object.values(BackgroundUrlOptions).map(
                                    (option) => (
                                        <option key={option} value={option}>
                                            {
                                                option
                                                    .replace('https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/', '')
                                                    .replace('.png', '')
                                                    .replace(/\b\w/g, (match) => match.toUpperCase())
                                                    .replace(/[-_]/g, ' ')
                                            }
                                        </option>
                                    )
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className='auto-size-columns'>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="targetEmails" className='mr-s vertical-align first-column-width'>Target Emails</label>
                        <textarea
                            id="targetEmails"
                            name="targetEmails"
                            className='mt-s mb-s'
                            value={promotionToCreate.targetEmails.join(', ')}
                            onChange={handleTextAreaChange}
                        />
                    </div>
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="type" className='mr-s vertical-align first-column-width'>Type *</label>
                        <select
                            id="type"
                            name="type"
                            className='mt-s mb-s'
                            value={promotionToCreate.type}
                            onChange={handleInputChange}
                        >
                            {
                                Object.values(PromoCodeType).map(
                                    type => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    )
                                )
                            }
                        </select>
                    </div>
                    {
                        promotionToCreate.type === PromoCodeType.LIMITED_USE && <div className='two-columns-promo-expand-two'>
                            <label htmlFor="type" className='mr-s vertical-align first-column-width'>Number of Uses</label>
                            <input
                                type="number"
                                id="numberOfUsesRemaining"
                                name="numberOfUsesRemaining"
                                className='pl-m promo-input'
                                value={promotionToCreate.numberOfUsesRemaining}
                                onChange={handleChange}
                            />
                        </div>
                    }
                    <div className='two-columns-promo-expand-two'>
                        <label htmlFor="scope" className='mr-s vertical-align first-column-width'>Scope *</label>
                        <select
                            id="scope"
                            name="scope"
                            className='mt-s mb-s'
                            value={promotionToCreate.scope}
                            onChange={handleInputChange}
                        >
                            {
                                Object.values(PromoCodeScope).map(
                                    scope => (
                                        <option key={scope} value={scope}>
                                            {scope}
                                        </option>
                                    )
                                )
                            }
                        </select>
                    </div>
                    {/* <div className='two-columns'>
                        <label htmlFor="oneOffUsed" className='mr-s vertical-align first-column-width'>One-Off Use?</label>
                        <input
                            type="checkbox"
                            id="oneOffUsed"
                            name="oneOffUsed"
                            checked={promotionToCreate.oneOffUsed}
                            onChange={handleCheckboxChange}
                        />
                    </div> */}
                </div>
                <div className='three-columns-expand-one-three'>
                    <div />
                    <button type="submit" className='mg-m pd-m bold' id='create-promotion-button'>CREATE</button>
                    <div />
                </div>
            </form>
        }
    </div>
}
