import { dashboardApi } from '#api';
import { useAppContext } from '#hooks';
import { SupportOption } from '#types';
import { HttpStatusCode } from 'axios';
import { Dispatch, FC, SetStateAction } from 'react';

interface SupportOptionToEditProps{
    supportOptionToEdit: SupportOption;
    setSupportOptionToEdit: Dispatch<SetStateAction<SupportOption | null>>;
}


export const SupportOptionToEdit: FC<SupportOptionToEditProps> = ({ supportOptionToEdit, setSupportOptionToEdit }) => {

    const { setApiStatus } = useAppContext();

    const updateSupportOption = async () => {
        if(supportOptionToEdit.title.trim() === '') {
            setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Title is empty' })
            return
        }

        supportOptionToEdit.problems.forEach(problem => {
            if(problem.trim() === '') {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: 'One or more problems are empty' })
                return
            }
        });

        try {
            const response = await dashboardApi.updateSupportOption(supportOptionToEdit)
            setApiStatus({ code: response.status, message: response.data.message })
            setSupportOptionToEdit(null)
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    return <div className='mg-m'>
        <div className='two-columns-expand-two'>
            <label style={{ width: '100px' }} className='bold'>Title:</label>
            <input
                type='text'
                value={supportOptionToEdit.title}
                className='support-option-input'
                onChange={
                    (event) => {
                        setSupportOptionToEdit({ ...supportOptionToEdit, title: event.target.value });
                    }
                }
            />
        </div>
        {
            <>
                {
                    supportOptionToEdit.problems?.map(
                        (problem, problemIndex) => (
                            <div key={problemIndex} className='three-columns-expand-two'>
                                <label style={{ width: '100px' }} className='bold'>{`Problem ${problemIndex + 1}:`}</label>
                                <input
                                    type='text'
                                    value={problem}
                                    className='support-option-input'
                                    onChange={
                                        (event) => {
                                            const updatedProblems = [...supportOptionToEdit.problems];
                                            updatedProblems[problemIndex] = event.target.value;
                                            setSupportOptionToEdit({ ...supportOptionToEdit, problems: updatedProblems });
                                        }
                                    }
                                />
                                <div className='three-rows-expand-one-three'>
                                    <div/>
                                    <button 
                                        onClick={
                                            () => {
                                                const updatedProblems = [...supportOptionToEdit.problems];
                                                updatedProblems.splice(problemIndex, 1);
                                                setSupportOptionToEdit({
                                                    ...supportOptionToEdit,
                                                    problems: updatedProblems
                                                });
                                            }
                                        }
                                        className='clear-button minus-button'
                                    >
                                        -
                                    </button>
                                    <div/>
                                </div>
                            </div>
                        )
                    )
                }
                <div className='three-rows-expand-one-three'>
                    <div/>
                    <button 
                        onClick={
                            () => {
                                const updatedProblems = supportOptionToEdit?.problems === undefined ? ['problem'] : [...supportOptionToEdit.problems, 'problem'];
                                setSupportOptionToEdit({
                                    ...supportOptionToEdit,
                                    problems: updatedProblems
                                })
                            }
                        }
                        className='clear-button plus-button'
                    >
                        +
                    </button>
                    <div/>
                </div>
            </>
        }
        <div className='four-columns-expand-one-four'>
            <div/>
            <button onClick={() => setSupportOptionToEdit(null)} className='clear-button edit-button mr-m'>CANCEL</button>
            <button className='clear-button edit-button' onClick={() => updateSupportOption()}>SAVE</button>
            <div/>
        </div>
    </div>
};
