import { Dispatch, FC, SetStateAction } from 'react';
import { promotionTableHeaders } from '#globals';
import { Promotion } from '#types';
import { useSafeState } from '#hooks';
import moment from 'moment';

//TODO create a generic component for the table

interface PromotionSearchBarProps{
    promotions: Promotion[];
    setPromotionToEdit: Dispatch<SetStateAction<Promotion | null>>
}

export const PromotionsTable: FC<PromotionSearchBarProps> = ({ promotions, setPromotionToEdit }) => {

    const [hoveredRowId, setHoveredRowId] = useSafeState<number | null>(null);

    return <table className='center-wrapper table pd-m mt-s'>
        <thead className="table-header">
            <tr>
                {
                    promotionTableHeaders.map(
                        header => <th className="table-cell" key={header}>{header.toUpperCase()}</th>
                    )
                }
            </tr>
        </thead>
        <tbody>
            {
                promotions.map((promotion, index) => 
                    <tr 
                        key={index}
                        className={`table-row ${hoveredRowId === index ? 'hovered' : ''}`}                                
                        onMouseEnter={() => {setHoveredRowId(index)}}
                        onClick={() => setPromotionToEdit(promotion)}
                        onMouseLeave={() => {setHoveredRowId(null)}}
                    >
                        <td className="table-cell a-tag-reset">
                            <p className='selectable-text'>{promotion.code}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.consumption?.length || 0}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.active ? "ACTIVE" : "EXPIRED"}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.amount}%</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.type}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.scope}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.oneOffUsed ? '✅' : ''}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{promotion.numberOfUsesRemaining === -1 || !promotion.numberOfUsesRemaining ? 'unlimited' : promotion.numberOfUsesRemaining}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{moment(promotion.validFrom).format('DD/MM/YYYY')}</p>
                        </td>
                        <td className="table-cell a-tag-reset">
                            <p>{moment(promotion.validTo).format('DD/MM/YYYY')}</p>
                        </td>
                    </tr>
                )
            }
        </tbody>
    </table>
}
