import { FC } from 'react';
import { useAppContext } from '#hooks';
import { ApiStatus } from '#dashboard/components/apiStatus.component';
import { EnvironmentType } from '#types';

interface PageHeaderProps {
    header?: string
}

export const PageHeader: FC<PageHeaderProps> = ({ header }) => {

    const { isExpanded, setIsExpanded, currentEnvironment } = useAppContext();

    function handleExpandButtonClick(): void {
        isExpanded ? setIsExpanded(false) : setIsExpanded(true)
    }

    return <>
        <div className='main-dashboard-container pd-s mb-s three-rows-expand-one-three'>
            <div/>
            <div className='four-columns-expand-three'>
                <button className='clear-button' id='expand-contract-button' onClick={handleExpandButtonClick}>
                    <img src={isExpanded ? "/images/contract.png" : "/images/expand.png"} alt="expand-contract" id='expand-contract-img' />
                </button>
                <h1>{header}</h1>
                <div/>
                { currentEnvironment === EnvironmentType.DEVELOPMENT && <p className='bold vertical-align mr-m testing-badge'>TESTING</p> }
            </div>
            <div/>
        </div>
        <ApiStatus />
    </>
}
