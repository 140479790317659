import { useState, useEffect, useRef } from 'react';
import { Socket, io } from 'socket.io-client';
import { ChatRoom, Message, LiveChatEvent, ImageFile } from '#types';
import { HttpStatusCode } from 'axios';
import { useAppContext, useSafeState } from '#hooks';
import { constants } from '#globals';
import { getWebsocketUrl } from '../services/helpers';

interface LiveChatState {
    selectedChatRoomLoadedAllMessages: boolean,
    selectedChatRoomMessages: Message[],
    selectedChatRoomEmail: string | null,
    selectedConversationId: string | null,
    connectedChatRooms: ChatRoom[],
    message: string,
    customerTyping: boolean,
    supportAgentTyping: boolean,
    images: ImageFile[],
}

export function useLiveChat() {

    const [liveChatState, setLiveChatState] = useState<LiveChatState>({
        selectedChatRoomLoadedAllMessages: false,
        selectedChatRoomMessages: [],
        selectedChatRoomEmail: null,
        selectedConversationId: null,
        connectedChatRooms: [],
        message: '',
        customerTyping: false,
        supportAgentTyping: false,
        images: [],
    });
    const [reconnectingToSocketIoStatus, setReconnectingToSocketIoStatus] = useSafeState<LiveChatEvent>(LiveChatEvent.CONNECT);
    const liveChatStateRef = useRef<LiveChatState>(liveChatState);
    const { setApiStatus } = useAppContext();
    const socketRef = useRef<Socket | null>(null);
    const typingTimeoutRef = useRef<any>(null)
    const userType: LiveChatEvent = LiveChatEvent.SUPPORT_AGENT;
    const connectedRoomsRef = useRef<ChatRoom[]>([])
    const websocketUrl: string = getWebsocketUrl()

    useEffect(
        () => {
            liveChatStateRef.current = liveChatState;
        },
        [liveChatState]
    );

    useEffect(
        () => {
            socketRef.current = io(websocketUrl, {
                extraHeaders: {
                    Authorization: `Bearer ${localStorage.getItem(constants.accessToken)}`,
                },
            });
            const socket = socketRef.current;

            socket.on(LiveChatEvent.DISCONNECT, (reason) => {
                reconnectToServerAndRefreshChatRooms(reason)
            });

            socket.on(LiveChatEvent.SEND_MESSAGE, (message: Message) => {
                if (message.to === liveChatStateRef.current.selectedChatRoomEmail || message.from === liveChatStateRef.current.selectedChatRoomEmail) {
                    setLiveChatState(prevState => ({
                        ...prevState,
                        selectedChatRoomMessages: [...prevState.selectedChatRoomMessages, message],
                    }));
                }
                socket.emit(LiveChatEvent.GET_ACTIVE_ROOMS, {}, (rooms: ChatRoom[]) => {
                    updateRooms(rooms)
                });
            });

            socket.on(LiveChatEvent.REFRESH_ROOM_LIST, () => {
                socket.emit(LiveChatEvent.GET_ACTIVE_ROOMS, {}, (rooms: ChatRoom[]) => {
                    updateRooms(rooms)
                });
            });

            socket.on(LiveChatEvent.MARK_CONVERSATION_AS_NOT_RESOLVED, () => {
                socket.emit(LiveChatEvent.GET_ACTIVE_ROOMS, {}, (rooms: ChatRoom[]) => {
                    updateRooms(rooms)
                });
            });

            socket.on(LiveChatEvent.CUSTOMER_TYPING, (conversationId: string) => {
                if (conversationId === liveChatStateRef.current.selectedConversationId) {
                    setLiveChatState(prevState => ({
                        ...prevState,
                        customerTyping: true
                    }));
                }
            });

            socket.on(LiveChatEvent.CUSTOMER_STOP_TYPING, (conversationId: string) => {
                if (conversationId === liveChatStateRef.current.selectedConversationId) {
                    setLiveChatState(prevState => ({
                        ...prevState,
                        customerTyping: false
                    }));
                }
            });

            socket.on(LiveChatEvent.SUPPORT_AGENT_TYPING, (conversationId: string) => {
                if (conversationId === liveChatStateRef.current.selectedConversationId) {
                    setLiveChatState(prevState => ({
                        ...prevState,
                        supportAgentTyping: true
                    }));
                }
            });

            socket.on(LiveChatEvent.SUPPORT_AGENT_STOP_TYPING, (conversationId: string) => {
                if (conversationId === liveChatStateRef.current.selectedConversationId) {
                    setLiveChatState(prevState => ({
                        ...prevState,
                        supportAgentTyping: false
                    }));
                }
            });

            socket.emit(LiveChatEvent.GET_ACTIVE_ROOMS, {}, (rooms: ChatRoom[]) => {
                updateRooms(rooms)
            });

            return () => {
                socket.off(LiveChatEvent.SEND_MESSAGE);
                socket.off(LiveChatEvent.REFRESH_ROOM_LIST);
                socket.off(LiveChatEvent.CUSTOMER_TYPING);
                socket.off(LiveChatEvent.CUSTOMER_STOP_TYPING);
                socket.off(LiveChatEvent.SUPPORT_AGENT_TYPING);
                socket.off(LiveChatEvent.SUPPORT_AGENT_STOP_TYPING);
                socket.off(LiveChatEvent.CONNECT);
                socket.off(LiveChatEvent.DISCONNECT);
                socket.disconnect();
            };
        },
        [],
    )

    async function handleAttachMedia(): Promise<void> {
        try {
            if (liveChatState.images.length >= 3) {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Too many images' });
                return;
            }

            const inputElement = document.createElement('input');
            inputElement.type = 'file';
            inputElement.multiple = true;
            inputElement.accept = 'image/*';
            inputElement.style.display = 'none';

            inputElement.addEventListener('change', async (event) => {
                const target = event.target as HTMLInputElement;
                if (!target.files) {
                    return;
                }

                if (target.files[0].size > 2 * 1024 * 1024) {
                    setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Image too large' });
                    return;
                }


                const selectedFiles = Array.from(target.files);
                const imageFiles = await Promise.all(selectedFiles.map(async (file) => {
                    const dataURL = await readFileAsDataURL(file);
                    return {
                        uri: dataURL,
                        name: file.name,
                        type: file.type,
                    };
                }));

                setLiveChatState((prevState: any) => ({ ...prevState, images: [...prevState.images, ...imageFiles] }));
            });

            document.body.appendChild(inputElement);
            inputElement.click();
            document.body.removeChild(inputElement);
        } catch (error) {
            console.error('Error attaching media:', error);
        }
    }

    async function readFileAsDataURL(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.onerror = () => {
                reject(reader.error);
            };
            reader.readAsDataURL(file);
        });
    }

    const openChat = (chatRoom: ChatRoom | null) => {
        if (!chatRoom) {
            setLiveChatState(prevState => ({
                ...prevState,
                selectedChatRoomLoadedAllMessages: false,
                selectedChatRoomMessages: [],
                selectedChatRoomEmail: null,
                selectedConversationId: null,
                connectedChatRooms: prevState.connectedChatRooms,
                message: '',
                customerTyping: false,
                supportAgentTyping: false,
                images: [],
            }));
            return
        }
        if (chatRoom?.conversationId === liveChatState.selectedConversationId) {
            return
        }
        setLiveChatState(prevState => ({
            ...prevState,
            selectedChatRoomLoadedAllMessages: false,
            selectedChatRoomEmail: chatRoom?.email as string,
            selectedConversationId: chatRoom?.conversationId as string,
            images: [],
            customerTyping: false,
            supportAgentTyping: false,
        }));
        socketRef.current?.emit(LiveChatEvent.JOIN_ROOM, { conversationId: chatRoom?.conversationId });
        socketRef.current?.emit(
            LiveChatEvent.FIND_ALL_CONVERSATIONS,
            { conversationId: chatRoom?.conversationId, numberOfMessagesLoaded: 0 },
            (messages: Message[]) => {
                setLiveChatState(prevState => ({
                    ...prevState,
                    selectedChatRoomMessages: messages
                }));
            }
        );
    };

    const handleSendMessage = () => {
        if (liveChatState.message.trim() === '' && liveChatState.images.length === 0) {
            return;
        }

        const conversationId = liveChatState.selectedConversationId;

        const newMessage: { content: string, conversationId: string | null, images: { name: string; type: string; data: Blob }[] } = {
            content: liveChatState.message,
            conversationId,
            images: [],
        };

        if (liveChatState.images.length > 0) {
            const imagePromises = liveChatState.images.map(image => {
                return fetch(image.uri)
                    .then(response => response.blob())
                    .then(blob => {
                        newMessage.images.push({
                            name: image.name,
                            type: image.type,
                            data: blob,
                        });
                    })
                    .catch(error => {
                        console.error('Error fetching image:', error);
                    });
            });

            Promise.all(imagePromises).then(() => {
                socketRef.current?.emit(LiveChatEvent.SEND_MESSAGE, newMessage);
                socketRef.current?.emit(LiveChatEvent.TYPING_EVENT, { conversationId, action: LiveChatEvent.STOP_TYPING, userType });
                setLiveChatState(prevState => ({ ...prevState, message: '', images: [] }));
            });
        } else {
            socketRef.current?.emit(LiveChatEvent.SEND_MESSAGE, newMessage);
            socketRef.current?.emit(LiveChatEvent.TYPING_EVENT, { conversationId, action: LiveChatEvent.STOP_TYPING, userType });
            setLiveChatState(prevState => ({ ...prevState, message: '', images: [] }));
        }
    };


    const handleTypeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        const conversationId: string | null = liveChatState?.selectedConversationId

        setLiveChatState(prevState => ({
            ...prevState,
            message: value
        }));

        if (conversationId) {
            value === '' ?
                socketRef.current?.emit(LiveChatEvent.TYPING_EVENT, { conversationId, action: LiveChatEvent.STOP_TYPING, userType }) :
                socketRef.current?.emit(LiveChatEvent.TYPING_EVENT, { conversationId, action: LiveChatEvent.TYPING, userType })
        }

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current)
        }

        typingTimeoutRef.current = setTimeout(() => {
            if (conversationId) {
                socketRef.current?.emit(LiveChatEvent.TYPING_EVENT, { conversationId, action: LiveChatEvent.STOP_TYPING, userType })
            }
        }, 10000)
    }

    const handleAskForResolution = (conversationId: string, event: React.MouseEvent) => {
        event.stopPropagation();
        socketRef.current?.emit(LiveChatEvent.AGENT_ASK_FOR_RESOLUTION, { conversationId });
        socketRef.current?.emit(LiveChatEvent.GET_ACTIVE_ROOMS, {}, (rooms: ChatRoom[]) => {
            updateRooms(rooms)
        });
    }

    const handleLoadMoreMessages = () => {
        socketRef.current?.emit(
            LiveChatEvent.FIND_ALL_CONVERSATIONS,
            { conversationId: liveChatState.selectedConversationId, numberOfMessagesLoaded: liveChatState.selectedChatRoomMessages?.length || 0 },
            (messages: Message[]) => {
                if (messages.length === 0) {
                    setLiveChatState(prevState => ({
                        ...prevState,
                        selectedChatRoomLoadedAllMessages: true
                    }));
                }
                setLiveChatState(prevState => ({
                    ...prevState,
                    selectedChatRoomMessages: [...messages, ...liveChatState.selectedChatRoomMessages]
                }));
            }
        );
    }

    const removeMediaFromMessage = (image: ImageFile) => {
        setLiveChatState(prevState => ({
            ...prevState,
            images: prevState.images.filter(img => img.uri !== image.uri)
        }));
    }

    const reconnectToServerAndRefreshChatRooms = (reason: string) => {
        if (reason !== 'io client disconnect') {
            setReconnectingToSocketIoStatus(LiveChatEvent.ATTEMPTING_RECONNECT);
            let numberOfReconnectionAttempts = 0;
            const maxNumberOfReconnectionAttempts = 10;
            const reconnectionDelay = 5000;
            const attemptReconnectToSocketIo = () => {
                const conversationIdArray: string[] = liveChatState.connectedChatRooms.map(chatroom => chatroom.conversationId);
                numberOfReconnectionAttempts++;
                if (numberOfReconnectionAttempts === maxNumberOfReconnectionAttempts) {
                    setReconnectingToSocketIoStatus(LiveChatEvent.DISCONNECT);
                    clearInterval(reconnectionInterval);
                    return;
                }
                if (socketRef?.current?.io._readyState !== 'closed') {
                    clearInterval(reconnectionInterval)
                    setReconnectingToSocketIoStatus(LiveChatEvent.CONNECT);
                    socketRef?.current?.connect();
                    conversationIdArray && conversationIdArray.length > 0 && socketRef?.current?.emit(LiveChatEvent.RECONNECT, { conversationIdArray: connectedRoomsRef.current });
                    socketRef?.current?.emit(LiveChatEvent.REFRESH_ROOM_LIST);
                    return;
                }
            }
            const reconnectionInterval = setInterval(attemptReconnectToSocketIo, reconnectionDelay);
        }
    }

    const updateRooms = (rooms: ChatRoom[]) => {
        setLiveChatState(prevState => ({
            ...prevState,
            connectedChatRooms: rooms
        }));
        connectedRoomsRef.current = rooms;
    }

    const closeCurrentChat = () => {
        socketRef?.current?.emit(LiveChatEvent.CLOSE_CHAT, { conversationId: liveChatState.selectedConversationId });
        socketRef?.current?.emit(LiveChatEvent.REFRESH_ROOM_LIST);
        setLiveChatState(prevState => ({
            ...prevState,
            selectedChatRoomLoadedAllMessages: false,
            selectedChatRoomMessages: [],
            selectedChatRoomEmail: null,
            selectedConversationId: null,
            connectedChatRooms: prevState.connectedChatRooms,
            message: '',
            customerTyping: false,
            supportAgentTyping: false,
            images: [],
        }));
    }

    return { liveChatState, openChat, handleSendMessage, handleTypeMessage, handleLoadMoreMessages, removeMediaFromMessage, handleAskForResolution, handleAttachMedia, reconnectingToSocketIoStatus, closeCurrentChat };
}
