import { FC } from "react"
import { PageHeader } from "#dashboard/components/pageHeader.component"
import { BlacklistTable } from "#users/components/blacklistTable.component"

export const BlacklistPage: FC = () => {
    return <>
        <PageHeader header={'Blacklisted Users'} />
        <BlacklistTable />
    </>
}
