import { FC, useEffect, useRef } from 'react';
import { Esim, EsimProvider, Subscription, User } from '#types';
import { SubscriptionToDisplay } from '#users/components/subscriptionToDisplay.component';
import { useAppContext, useSafeState } from '#hooks';
import { HttpStatusCode } from 'axios';
import { dashboardApi } from '#api';
import { UserSubscriptionNotesScreen } from './userSubscriptionNotesScreen.component';
import { getFullUserName } from '../../services/helpers';

interface UserWithBundlesInformationProps {
    user: User;
}

interface Bundle {
    esim: Esim,
    subscriptions: Subscription[]
}

export const UserWithBundlesInformation: FC<UserWithBundlesInformationProps> = ({ user }) => {

    const { setApiStatus, loggedInUser } = useAppContext();
    const [bundles, setBundles] = useSafeState<Bundle[]>([] as Bundle[])
    const [expandedEsim, setExpandedEsim] = useSafeState<string[]>([]);
    const [selectedSubscriptionForNotes, setSelectedSubscriptionForNotes] = useSafeState<string | null>(null)
    const subscriptionsWithNotesRef = useRef<string[]>([])

    const refreshEsim = async (iccid: string) => {
        try {
            const response = await dashboardApi.refreshEsimgoEsim(iccid)
            setApiStatus({ code: response.status, message: response.data.message })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    useEffect(
        () => {
            if(!user.subscriptions || !user.esims) {
                return
            }
            const updatedBundles: Bundle[] = []
            for (const subscription of user.subscriptions) {
                const esim = user.esims.find((esim) => esim.iccid === subscription.simId);

                if (esim) {
                    const bundle = updatedBundles.find((bundle) => bundle.esim.iccid === esim.iccid);

                    if (bundle) {
                        bundle.subscriptions.push(subscription);
                    } else {
                        const newBundle: Bundle = { esim, subscriptions: [subscription] };
                        updatedBundles.push(newBundle);
                    }
                } else {
                    setApiStatus({
                        code: HttpStatusCode.InternalServerError,
                        message: `One of the user's subscriptions does not have a corresponding eSim, please contact Alex or Ahmad and give them the email: ${user?.email}`
                    })
                }
            }
            setBundles(updatedBundles)
        },
        [],
    );

    useEffect(
        () => {
            if (bundles.length > 0) {
                const initialExpandedEsim = bundles.map(bundle => bundle.esim.iccid);
                setExpandedEsim(initialExpandedEsim);
            }
        },
        [bundles]
    );

    return <>
        <div className='main-dashboard-container pd-m mt-s'>
            {
                bundles?.length === 0 ?
                    <div>{getFullUserName(user)} Did not purchase any eSIM yet</div> :
                    bundles?.map(
                        (bundle: Bundle) => {
                            const icon = expandedEsim.includes(bundle.esim.iccid) ? '/images/contract.png' : '/images/expand.png';
                            const subscriptionsText = bundle.subscriptions.length > 0 ? `${bundle.subscriptions.length} subscription(s)` : 'No subscriptions';
                            return <div key={bundle.esim.iccid}>
                                <div
                                    className="mb-m three-columns-expand-one"
                                    style={{ border: '1px solid black', borderRadius: 4, padding: 6 }}
                                >
                                    <div className='bold three-rows-expand-one-three'>
                                        <div />
                                        <div className='selectable-text'>
                                            {bundle.esim.provider} - {bundle.esim.iccid} {bundle.esim.provider.toUpperCase() === EsimProvider.ORANGE && bundle.esim.msisdn &&  - bundle.esim.msisdn} - {bundle?.esim?.status?.toUpperCase()} {subscriptionsText}
                                        </div>
                                        <div />
                                    </div>
                                    {
                                        bundle.esim.provider === EsimProvider.ESIMGO && loggedInUser?.roles?.SUPERUSER &&
                                        <button
                                            onClick={() => refreshEsim(bundle.esim.iccid)}
                                            id='refresh-esim-button'
                                            className='pd-s bold'
                                        >
                                            REFRESH
                                        </button>
                                    }
                                    <img
                                        className='expand-contract-esim vertical-align ml-m'
                                        src={icon}
                                        alt="expand"
                                        onClick={
                                            () => {
                                                if (expandedEsim.includes(bundle.esim.iccid)) {
                                                    setExpandedEsim(expandedEsim.filter(iccid => iccid !== bundle.esim.iccid));
                                                } else {
                                                    setExpandedEsim([...expandedEsim, bundle.esim.iccid]);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                {
                                    expandedEsim.includes(bundle.esim.iccid) && bundle.subscriptions.length > 0 && (
                                        <div className='mb-m subscription-columns'>
                                            {
                                                bundle.subscriptions.map(subscription => 
                                                    <SubscriptionToDisplay
                                                        key={subscription._id}
                                                        setSelectedSubscriptionForNotes={setSelectedSubscriptionForNotes}
                                                        subscription={subscription as Subscription}
                                                        hasNotes={subscriptionsWithNotesRef.current.includes(subscription._id.toString())}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        }
                    )
            }
        </div>
        { 
            selectedSubscriptionForNotes ? 
                <UserSubscriptionNotesScreen setSelectedSubscriptionForNotes={setSelectedSubscriptionForNotes} subscriptionId={selectedSubscriptionForNotes} user={user} /> :
                <UserSubscriptionNotesScreen setSelectedSubscriptionForNotes={setSelectedSubscriptionForNotes} user={user} subscriptionsWithNotesRef={subscriptionsWithNotesRef} />
        }
    </> 
}