
export enum ReportSearchBarOptions {
    EMAIL = "email",
    DATE = "createdAt",
    IS_OPEN = "isOpen",
    TYPE = "type",
    IMAGES = "images"
}

export enum PlanSearchBarOptions {
    PRODUCT_CODE = "productCode",
    DESTINATION_NAME = "destinationName",
    FOOTPRINT_CODE = "footprintCode",
}

export enum UserSearchBarOptions {
    EMAIL = "email",
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    IS_VERIFIED = 'isVerified',
    LOCATION = 'currentLocationFootprint',
    PROVIDER = 'provider',
    DEVICE_OS = 'device.OS',
}

export enum EsimSearchBarOptions {
    USER_EMAIL = 'userEmail',
    ICCID = "iccid",
    PROVIDER = "provider",
    STATUS = "status",
}

export enum RewardSearchBarOptions {
    TITLE = 'title',
    COMPANY_NAME = 'companyName',
    REWARD_CODE = 'rewardCode'
}

export enum ConversationSearchBarOptions {
    USER_EMAIL = "userEmail"
}

export enum FaqDropdownOptions {
    USING_THE_APP = "Using the app",
    GENERAL = "General",
    SETTING_UP_YOUR_ESIM = "Setting up your eSIM",
    MANAGING_ESIM_ON_YOUR_PHONE = "Managing eSIM on your phone",
    TRAVELLING_WITH_YOUR_ESIM = "Travelling with your eSIM",
    TROUBLESHOOTING = "Troubleshooting",
    REFUND_POLICY = "Refund Policy"
}

export enum PromotionSearchBarOptions {
    CODE = "code",
}

export enum PromoCodeType {
    NORMAL = 'NORMAL',
    PROMOTION = 'PROMOTION',
    LIMITED_USE = 'LIMITED_USE',
    EVENT = 'EVENT',
    ONEOFF = 'ONEOFF'
}

export enum PromoCodeScope {
    GLOBAL = 'GLOBAL',
    GROUP = 'GROUP',
    EXCLUSIVE = 'EXCLUSIVE',
}

export enum NotificationType {
    Welcome = 'Welcome',
    Purchase = 'Purchase',
    DestinationNews = 'DestinationNews',
    AppNews = 'AppNews',
    Promotion = 'Promotion',
    Maintenance = 'Maintenance',
    Reminder = 'Reminder',
    OneSignal = 'OneSignal',
    Fix = 'Fix',
    Consumption = 'Consumption',
    Chat_Resolved = 'ChatResolved',
    Chat_Closed = 'ChatClosed',
    Chat_Inactive = 'ChatInactive'
    /*
    NEW_COUNTRY = "New Country",
    NEW_PROVIDER = "New Provider",
    NEW_FEATURES = "New Features",
    ZIMMIES = "Zimmies",
    */
}

export enum RecipientType {
    ALL = 'Send to all',
    INDIVIDUAL = 'Send to email',
    FILTERED = 'Send to filtered users'
}

export enum FilterFieldOptions {
    SUBSCRIPTION = 'subscription',
    LOCATION = 'location',
    STEPLER = 'stepler',
}

export enum FilterOperatorOptions {
    GREATER_THAN = 'greater than',
    EQUAL_TO = 'exactly',
}

export enum EsimProvider {
    TRUPHONE = 'TRUPHONE',
    ESIMGO = 'ESIMGO',
    WTR = 'WTR',
    ORANGE = 'ORANGE'
}

export enum SubscriptionStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    PENDING = 'PENDING',
    DEPLETED = 'DEPLETED',
}

export enum Currency {
    GBP = 'gbp',
    EUR = 'eur',
    USD = 'usd',
}

export enum EnvironmentType {
    PRODUCTION = 'production',
    DEVELOPMENT = 'development'
}

export enum PromotionType {
    NORMAL = 'NORMAL',
    PROMOTION = 'PROMOTION',
    ONEOFF = 'ONEOFF',
    EVENT = 'EVENT',
}

export enum PromotionScope {
    GLOBAL = 'GLOBAL',
    GROUP = 'GROUP',
    EXCLUSIVE = 'EXCLUSIVE',
}

export enum EsimType {
    DATA = 'DATA',
    VOICE = 'VOICE',
    VOICE_AND_DATE = 'VOICE_AND_DATA'
}

export enum PlanScope {
    GLOBAL = 'GLOBAL',
    LOCAL = 'LOCAL',
    REGIONAL = 'REGIONAL',
}

export enum BackgroundUrlOptions {
    GREEN_CARD = 'https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/green-card.png',
    DARK_PURPLE_CARD = 'https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/dark-purple-card.png',
    LIGHT_PURPLE_CARD = 'https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/light-purple-card.png',
    PINK_CARD = 'https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/pink-card.png',
    QATAR = 'https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/qatar.png',
    YELLOW_CARD = 'https://zim-mobile.s3.eu-central-1.amazonaws.com/promotions/yellow-card.png',
}

export enum Locale {
    ALL = 'all',
    ENGLISH = 'en',
    FRENCH = 'fr',
    ITALIAN = 'it',
    GERMAN = 'de'
}

export const getRelevantLanguages = (db: Database, includeAll: boolean = false): Locale[] => {
    if (db === Database.ZIM) {
        return includeAll ? [Locale.ALL, Locale.ENGLISH, Locale.FRENCH] : [Locale.ENGLISH, Locale.FRENCH];
    } else if (db === Database.SBB) {
        return includeAll ? Object.values(Locale) : Object.values(Locale).filter(lang => lang !== Locale.ALL);
    } else {
        return includeAll ? Object.values(Locale) : Object.values(Locale).filter(lang => lang === Locale.ENGLISH);
    }
}

const languageDisplayMap: { [key in Locale]: string } = {
    [Locale.ALL]: 'ALL',
    [Locale.ENGLISH]: 'ENGLISH',
    [Locale.FRENCH]: 'FRENCH',
    [Locale.ITALIAN]: 'ITALIAN',
    [Locale.GERMAN]: 'GERMAN',
};

export const getRelevantLanguageDisplay = (language: Locale): string => {
    return languageDisplayMap[language];
}

export enum LiveChatEvent {
    JOIN_ROOM = 'joinRoom',
    FIND_ALL_CONVERSATIONS = 'findAllConversations',
    GET_ACTIVE_ROOMS = 'getActiveRooms',
    CUSTOMER_TYPING = 'customerTyping',
    CUSTOMER_STOP_TYPING = 'customerStopTyping',
    SUPPORT_AGENT_TYPING = 'supportAgentTyping',
    SUPPORT_AGENT_STOP_TYPING = 'supportAgentStopTyping',
    REFRESH_ROOM_LIST = 'refreshRoomList',
    TYPING_EVENT = 'typingEvent',
    SUPPORT_AGENT = 'supportAgent',
    STOP_TYPING = 'stopTyping',
    TYPING = 'typing',
    AGENT_ASK_FOR_RESOLUTION = 'agentAskForResolution',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    ATTEMPTING_RECONNECT = 'attemptingReconnect',
    RECONNECT = 'reconnect',
    MARK_CONVERSATION_AS_NOT_RESOLVED = 'markConversationAsNotResolved',
    CLOSE_CHAT = 'closeChat',
    SEND_MESSAGE = 'sendMessage',
    MESSAGE = 'message',
}

export enum EsimGuideType {
    QRCODE_INSTALLATION = 'QRCODE_INSTALLATION',
    MANUAL_INSTALLATION = 'MANUAL_INSTALLATION',
    DELETION = 'DELETION',
}

export enum Platform {
    ANDROID = 'android',
    IOS = 'ios',
    WEB = 'web'
}

export enum Database {
    ZIM = 'zim',
    SBB = 'sbb',
    GD = 'g&d'
}

export enum RewardImageType {
    COMPANY_LOGO = 'logo',
    REWARD_BACKGROUND = 'background'
}

export enum BundleType {
    AUTO_RENEWAL = "AUTO_RENEWAL",
    ONE_OFF = "ONE_OFF",
}