import { FC } from 'react';

interface PaginationProps{
    totalPages: number;
    currentPage: number;
    setCurrentPage: (currentPage: number) => void;
}

export const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, setCurrentPage }) => {
    return <div className='five-columns-expand-one-five pagination-bar'>
        <div/>
        <button className="pagination-button bold mg-s pd-s" disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
            PREVIOUS
        </button>
        <div className='three-rows-expand-one-three'>
            <div/>
                <span className='bold-text'>{`${currentPage} out of ${totalPages}`}</span>
            <div/>
        </div>
        <button className="pagination-button bold mg-s pd-s" disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
            NEXT
        </button>
        <div/>
    </div>
}