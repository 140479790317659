import { useAppContext, useSafeState } from '#hooks';
import { FaqSubSectionContent, Locale, Reward, RewardImageType, getRelevantLanguageDisplay } from '#types';
import { ChangeEvent, Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { EditRewardSubSection } from './editRewardSubSection.component';
import { dashboardApi } from '#api';
import { HttpStatusCode } from 'axios';

interface EditRewardProps {
    rewardToEdit: Reward
    setRewardToEdit: Dispatch<SetStateAction<Reward | null>>
    availableLanguages: Locale[],
    setIsExpandedBubble?: Dispatch<SetStateAction<boolean>>
}

export const EditReward: FC<EditRewardProps> = ({ rewardToEdit, setRewardToEdit, availableLanguages, setIsExpandedBubble }) => {

    const { setApiStatus } = useAppContext();
    const [updatedReward, setUpdatedReward] = useSafeState<Reward>(rewardToEdit)
    const creatingNewReward: boolean = rewardToEdit._id === 'create'
    const [faqSubSectionToEdit, setFaqSubSectionToEdit] = useSafeState<string | null>(null)
    const [logoFile, setLogoFile] = useSafeState<File | null>(null)
    const [backgroundFile, setBackgroundFile] = useSafeState<File | null>(null)

    const handleImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            event.target.name === 'logo' ? setLogoFile(event.target.files[0]) : setBackgroundFile(event.target.files[0])
        }
    };

    function extractValues(contents: FaqSubSectionContent[]): string[] {
        return contents.reduce(
            (accumulator: string[], content) => {
                const valuesWithNewlines = content.value.join('\n');
                return accumulator.concat(valuesWithNewlines);
            },
            []
        );
    }

    const saveReward = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        return creatingNewReward ? await createNewReward() : await updateReward()
    }

    const createNewReward = async () => {
        try {
            const validReqBody: boolean = validateReward(updatedReward)
            if (!validReqBody) {
                return
            }
            if (!logoFile || !backgroundFile) {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: 'Both company logo and background image must be present' })
                return
            }
            const rewardToCreate: Reward = {
                ...updatedReward,
                companyLogoUrl: await convertImageToBase64(logoFile),
                rewardBackgroundUrl: await convertImageToBase64(backgroundFile)
            }
            const response = await dashboardApi.createReward(rewardToCreate)
            setApiStatus({ code: response.status, message: response.data.message })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setRewardToEdit(null)
        }
    }

    const handleCancelClick = () => {
        setRewardToEdit(null)
        if (setIsExpandedBubble) {
            setIsExpandedBubble(false)
        }
    }

    const updateReward = async () => {
        try {
            const updatedRewardProperties: Reward = {} as Reward
            for (const property in updatedReward) {
                if (updatedReward.hasOwnProperty(property) && rewardToEdit[property] !== updatedReward[property]) {
                    updatedRewardProperties[property] = updatedReward[property];
                }
            }
            if (Object.keys(updatedRewardProperties).length === 0 && !logoFile && !backgroundFile) {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: 'No changes made to the reward' })
                return
            }
            const validReqBody: boolean = validateReward(updatedReward)
            if (!validReqBody) {
                return
            }
            if (logoFile) {
                updatedRewardProperties.companyLogoUrl = await convertImageToBase64(logoFile)
            }
            if (backgroundFile) {
                updatedRewardProperties.rewardBackgroundUrl = await convertImageToBase64(backgroundFile)
            }
            const response = await dashboardApi.updateReward(rewardToEdit._id, updatedRewardProperties)
            setApiStatus({ code: response.status, message: response.data.message })
        } catch (error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        } finally {
            setRewardToEdit(null)
        }
    }

    const convertImageToBase64 = async (image: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        const base64Promise = new Promise<string>((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.onerror = error => reject(error);
        });
        const base64Data = await base64Promise;
        return { name: image.name, type: image.type as RewardImageType, data: base64Data };
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        const updatedValue = name === 'priority' ? Number(value) : value;
        setUpdatedReward({ ...updatedReward, [name]: updatedValue });
    };

    const handleInputChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value, type } = event.target;
        if (type === 'radio') {
            setUpdatedReward({ ...updatedReward, [name]: value === 'true' });
        } else {
            setUpdatedReward({ ...updatedReward, [name]: value });
        }
    };

    const handleTargetFlagCodesChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const targetFlagCodesArray = value.split(',').map((code) => code.trim());
        setUpdatedReward({ ...updatedReward, targetFlagCodes: targetFlagCodesArray });
    };

    if (faqSubSectionToEdit) {
        return <EditRewardSubSection updatedReward={updatedReward} setUpdatedReward={setUpdatedReward} faqSubSectionToEdit={faqSubSectionToEdit} setFaqSubSectionToEdit={setFaqSubSectionToEdit} />
    }

    const validateReward = (reward: Reward): boolean => {
        const requiredFields = ['title', 'companyName', 'subtitle', 'lang', 'rewardCode'];
        const requiredProperties = ['active', 'validTo', 'validFrom', 'priority', 'instructions'];
        for (const field of requiredFields) {
            if (!reward[field]) {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: `${field} cannot be empty` })
                return false;
            }
        }
        for (const property of requiredProperties) {
            if (!(property in reward)) {
                setApiStatus({ code: HttpStatusCode.BadRequest, message: `${property} cannot be empty` })
                return false;
            }
        }
        return true
    };

    return <div className="main-dashboard-container mb-s pd-m">
        {!creatingNewReward && <h3 className='mb-m'>Edit A Reward</h3>}
        <form onSubmit={(event) => saveReward(event)}>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="companyName" className='bold reward-label-width'>Company Name</label>
                <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={updatedReward.companyName || ''}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="title" className='bold reward-label-width'>Title</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={updatedReward.title || ''}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="subtitle" className='bold reward-label-width'>Subtitle</label>
                <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    value={updatedReward.subtitle || ''}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="description" className='bold reward-label-width'>Description</label>
                <input
                    type="text"
                    id="description"
                    name="description"
                    value={updatedReward.description || ''}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            {
                creatingNewReward && <div className='three-columns-expand-three mb-m'>
                    <label htmlFor="rewardCode" className='bold reward-label-width'>Reward Code</label>
                    <input
                        type="text"
                        id="rewardCode"
                        name="rewardCode"
                        value={updatedReward.rewardCode || ''}
                        onChange={handleChange}
                        className='bold pd-s wide-input-width'
                    />
                    <div />
                </div>
            }
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="priority" className='bold reward-label-width'>Priority</label>
                <input
                    type="number"
                    id="priority"
                    name="priority"
                    value={updatedReward.priority || 0}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="lang" className='bold reward-label-width'>Language</label>
                <select
                    id='lang'
                    name='lang'
                    className='bold pd-s wide-input-width'
                    value={updatedReward.lang || ''}
                    onChange={(event) => handleInputChange(event)}
                >
                    {
                        Object.values(availableLanguages).map(
                            (option) => (
                                <option key={option} value={option}>{getRelevantLanguageDisplay(option)}</option>
                            )
                        )
                    }
                </select>
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="instructions" className='bold reward-label-width'>Instructions</label>
                <input
                    type="text"
                    id="instructions"
                    name="instructions"
                    readOnly
                    value={updatedReward.instructions ? extractValues(updatedReward.instructions) : ''}
                    onClick={() => setFaqSubSectionToEdit('instructions')}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="additionalInfo" className='bold reward-label-width'>Additional Info</label>
                <input
                    type="text"
                    id="additionalInfo"
                    name="additionalInfo"
                    readOnly
                    value={updatedReward.additionalInfo ? extractValues(updatedReward.additionalInfo) : ''}
                    onClick={() => setFaqSubSectionToEdit('additionalInfo')}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="includesPartnerVoucher" className='bold reward-label-width'>Includes Partner Voucher</label>
                <div className='pd-s wide-input-width five-columns-expand-five'>
                    <input
                        type="radio"
                        id="includesPartnerVoucher-true"
                        name="includesPartnerVoucher"
                        value="true"
                        checked={updatedReward.includesPartnerVoucher === true}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="includesPartnerVoucher-true" className='ml-s'>True</label>
                    <input
                        type="radio"
                        id="includesPartnerVoucher-false"
                        name="includesPartnerVoucher"
                        value="false"
                        className="ml-m"
                        checked={updatedReward.includesPartnerVoucher === false}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="includesPartnerVoucher-false" className='ml-s'>False</label>
                </div>
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="active" className='bold reward-label-width'>Active</label>
                <div className='pd-s wide-input-width five-columns-expand-five'>
                    <input
                        type="radio"
                        id="active-true"
                        name="active"
                        value="true"
                        checked={updatedReward.active === true}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="active-true" className='ml-s'>True</label>
                    <input
                        type="radio"
                        id="active-false"
                        name="active"
                        value="false"
                        className="ml-m"
                        checked={updatedReward.active === false}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="active-false" className='ml-s'>False</label>
                </div>
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="targetFlagCodes" className='bold reward-label-width'>Target Flag Codes</label>
                <input
                    type="text"
                    id="targetFlagCodes"
                    name="targetFlagCodes"
                    value={updatedReward.targetFlagCodes ? updatedReward.targetFlagCodes.join(', ') : ''}
                    onChange={handleTargetFlagCodesChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='four-columns-expand-four mb-m'>
                <label htmlFor="background" className='bold reward-label-width vertical-align'>Reward Background URL</label>
                {
                    backgroundFile ?
                        <div className='three-columns-expand-two pd-s wide-input-width'>
                            <p className='bold vertical-align'>{backgroundFile.name}</p>
                            <div />
                            <button onClick={() => setBackgroundFile(null)} className='center-wrapper bold pagination-button white-text mt-m mb-m pd-s'>DELETE</button>
                        </div> :
                        <input
                            type="file"
                            id="background"
                            name="background"
                            onChange={handleImageSelect}
                            className='bold pd-s wide-input-width vertical-align'
                        />
                }
                {
                    (backgroundFile || rewardToEdit.rewardBackgroundUrl) && <img
                        src={backgroundFile ? URL.createObjectURL(backgroundFile) : rewardToEdit.rewardBackgroundUrl && rewardToEdit.rewardBackgroundUrl as string}
                        alt="background preview"
                        className='reward-image vertical-align'
                    />
                }
                <div />
            </div>
            <div className='four-columns-expand-four mb-m'>
                <label htmlFor="logo" className='bold reward-label-width vertical-align'>Company Logo URL</label>
                {
                    logoFile ?
                        <div className='three-columns-expand-two pd-s wide-input-width'>
                            <p className='bold vertical-align'>{logoFile.name}</p>
                            <div />
                            <button onClick={() => setLogoFile(null)} className='center-wrapper bold pagination-button white-text mt-m mb-m pd-s'>DELETE</button>
                        </div> :
                        <input
                            type="file"
                            id="logo"
                            name="logo"
                            onChange={handleImageSelect}
                            className='bold pd-s wide-input-width vertical-align'
                        />
                }
                {
                    (logoFile || rewardToEdit.companyLogoUrl) && <img
                        src={logoFile ? URL.createObjectURL(logoFile) : rewardToEdit.companyLogoUrl && rewardToEdit.companyLogoUrl as string}
                        alt="logo preview"
                        className='reward-image vertical-align'
                    />
                }
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="validFrom" className='bold reward-label-width'>Valid From</label>
                <input
                    type="date"
                    id="validFrom"
                    name="validFrom"
                    value={updatedReward.validFrom ? new Date(updatedReward.validFrom).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='three-columns-expand-three mb-m'>
                <label htmlFor="validTo" className='bold reward-label-width'>Valid To</label>
                <input
                    type="date"
                    id="validTo"
                    name="validTo"
                    value={updatedReward.validTo ? new Date(updatedReward.validTo).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                    onChange={handleChange}
                    className='bold pd-s wide-input-width'
                />
                <div />
            </div>
            <div className='four-columns-expand-one-four'>
                <div />
                <div className="three-columns-expand-one-three mt-m">
                    <div />
                    <button type='button' className="center-wrapper bold pagination-button white-text mb-s pd-s" onClick={() => handleCancelClick()}>
                        CANCEL
                    </button>
                    <div />
                </div>
                <div className="three-columns-expand-one-three mt-m ml-m">
                    <div />
                    <button type="submit" className="center-wrapper bold pagination-button white-text mb-s pd-s">SAVE</button>
                    <div />
                </div>
                <div />
            </div>
        </form>
    </div>
}
