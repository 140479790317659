import { FC } from 'react';
import { useAppContext, useSafeState } from '#hooks';
import { dashboardApi } from '#api';
import { ConversationReport } from '#types';
import { formatDateWithHours } from '../../services/helpers';

export const DownloadConversationsButton: FC = () => {

    const { setApiStatus } = useAppContext();
    const [ fileIsLoading, setFileIsLoading ] = useSafeState<boolean>(false)
    const [ showDownloadConversationButton, setShowDownloadConversationButton ] = useSafeState<boolean>(false)
    const [ fromDate, setFromDate ] = useSafeState(() => {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        return oneMonthAgo.toISOString().split('T')[0];
    });
    const [ toDate, setToDate ] = useSafeState(() => new Date().toISOString().split('T')[0]);
    
    const fetchConversationsWithLiveMessages = async () => {
        try {
            const response = await dashboardApi.getConversationsWithLiveMessages(toDate, fromDate)
            return response.data
        } catch(error: any) {
            setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
        }
    }

    async function createTextFile(): Promise<void> {
        setFileIsLoading(true)
        const conversationReport: ConversationReport[] = await fetchConversationsWithLiveMessages()

        let txtContent = ''

        conversationReport.forEach(
            (conversationReport: ConversationReport) => {
                let row = `\n\nNew conversation: \n\n ${conversationReport.email} - ${conversationReport.problem}\n\n`;
                conversationReport.messages.forEach(message => {
                    row += `${message.from} ${formatDateWithHours(message.createdAt as Date)}: ${message.content} \n`
                });
                txtContent += row;
            }
        );

        const dataToReturn = new Blob([txtContent], { type: 'text/plain' });
        const d = new Date()
        const dateString = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
            + "-" + ("0" + d.getHours()).slice(-2) + "h" + ("0" + d.getMinutes()).slice(-2) + "m"

        saveFile(dataToReturn, `zim-customers-conversations-${dateString}.txt`);
        setFileIsLoading(false)
    }

    function saveFile(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
    }

    return <div className="main-dashboard-container pd-m">
        <div className='three-columns-expand-two ml-m'>
            <h3 className='vertical-align'>Download Conversations Report</h3>
            <div/>
            <div className='two-columns-expand-one'>
                <div/>
                <button className='clear-button' id='expand-contract-button' onClick={() => setShowDownloadConversationButton(!showDownloadConversationButton)}>
                    <img src={showDownloadConversationButton ? "/images/contract.png" : "/images/expand.png"} alt="expand-contract" id='expand-contract-img' />
                </button>
            </div>
        </div>
        {
            showDownloadConversationButton && <div className='six-columns-expand-five mt-m'>
                <span className='vertical-align ml-m'>Download conversations from: </span>
                <input
                    type="date"
                    id="fromDate"
                    name="fromDate"
                    value={fromDate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFromDate(e.target.value)}
                    max={toDate}
                />
                <span className='vertical-align'> to: </span>
                <input
                    type="date"
                    id="toDate"
                    name="toDate"
                    value={toDate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToDate(e.target.value)}
                    min={fromDate}
                />
                <div/>
                {
                    fileIsLoading ? <div className="lds-hourglass" /> :
                        <button onClick={createTextFile} className='download-button mr-m pd-s bold'>DOWNLOAD CONVERSATIONS</button>
                }
            </div>
        }
    </div>
}
