import { dashboardApi } from "#api";
import { useAppContext, useSafeState } from "#hooks";
import { Bundle, EsimProvider, Subscription } from "#types";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect } from "react";
import { SubscriptionToDisplay } from "./subscriptionToDisplay.component";
import { IccidMap } from "./userInformation.component";

type Props = {
    bundle: Bundle;
    handleExpandEsim: (_iccid: string) => void;
    expandedEsimIccids: IccidMap | null;
    setSelectedSubscriptionForNotes: Dispatch<SetStateAction<string | null>>;
    subscriptionsWithNotesRef?: MutableRefObject<string[]>;
}

export const BundleCard: React.FC<Props> = (props) => {

    const { setApiStatus, loggedInUser } = useAppContext();

    const [subscriptions, setSubscriptions] = useSafeState<Subscription[]>([]);
    const [isLoading, setIsLoading] = useSafeState<boolean>(true);

    useEffect(
        () => {
            const init = async () => {
                try {
                    const response = await dashboardApi.getBundleSubscriptions(props.bundle.esim.iccid, props.bundle.userEmail, true);
                    setSubscriptions(response.data);
                } catch (error: any) {
                    setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
                } finally {
                    setIsLoading(false);
                }
            };
            init();
        },
        []
    );

    const refreshEsim = useCallback(
        async (iccid: string) => {
            try {
                const response = await dashboardApi.refreshEsimgoEsim(iccid)
                setApiStatus({ code: response.status, message: response.data.message })
            } catch (error: any) {
                setApiStatus({ code: error?.response?.data?.statusCode, message: error?.response?.data?.message })
            }
        },
        [props.bundle]
    );

    const isExpanded = props.expandedEsimIccids?.[props.bundle.esim.iccid];
    const icon = isExpanded ? '/images/contract.png' : '/images/expand.png';
    const subscriptionsText = isLoading ? 'Loading...' : subscriptions.length > 0 ? `${subscriptions.length} subscription(s)` : 'No subscriptions';

    return <div>
        <div
            className="mb-m three-columns-expand-one"
            style={{ border: '1px solid black', borderRadius: 4, padding: 6 }}
        >
            <div className='bold three-rows-expand-one-three'>
                <div />
                <div className='selectable-text'>
                    {(props.bundle?.providerOfWhiteLabel ? `${props.bundle?.esim?.provider} (${props.bundle?.providerOfWhiteLabel})` : props.bundle?.esim?.provider)} - 
                    {props.bundle.esim?.iccid} {props.bundle.esim.provider.toUpperCase() === EsimProvider.ORANGE && props.bundle?.esim?.msisdn && - props.bundle?.esim?.msisdn} - 
                    {` ` + props.bundle?.esim?.status?.toUpperCase()} - 
                    {` ` + subscriptionsText}
                </div>
                <div />
            </div>
            {
                props.bundle.esim.provider === EsimProvider.ESIMGO && loggedInUser?.roles?.SUPERUSER &&
                <button
                    onClick={() => refreshEsim(props.bundle.esim.iccid)}
                    id='refresh-esim-button'
                    className='pd-s bold'>
                    REFRESH
                </button>
            }
            <img
                className='expand-contract-esim vertical-align ml-m'
                src={icon}
                alt="expand"
                onClick={() => props.handleExpandEsim(props.bundle.esim.iccid)}
            />
        </div>
        {
            isExpanded && subscriptions.length > 0 && (
                <div className='mb-m subscription-columns'>
                    {
                        isLoading ?
                            <div className="lds-hourglass" /> :
                            subscriptions.map(subscription =>
                                <SubscriptionToDisplay
                                    key={subscription._id}
                                    setSelectedSubscriptionForNotes={props.setSelectedSubscriptionForNotes}
                                    subscription={subscription as Subscription}
                                    hasNotes={(props.subscriptionsWithNotesRef as any).current.includes(subscription._id.toString())}
                                />
                            )
                    }
                </div>
            )
        }
    </div>
};
